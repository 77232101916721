import React from 'react';
import { FormControlLabel, Grid, Stack } from '@mui/material';

import { SxProps } from '@mui/system';
import RedCheckbox from '../base/RedCheckbox';
import { IService } from '../../services/transportTypes/NService';
import HeaderComponent from '../base/HeaderComponent';

export interface ServiceEditorProps {
  services: IService[];
  selected: IService['name'][];
  onChange: (selected: IService['name'][]) => void;
  sx?: SxProps;
}

const ServiceEditor = (props: ServiceEditorProps): JSX.Element => {
  const { services, selected, onChange, sx } = props;

  const handleCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: IService['name'],
  ) => {
    if (selected.includes(id)) {
      onChange(selected.filter((s) => s !== id));
    } else {
      onChange([...selected, id]);
    }
  };

  return (
    <Stack alignItems="stretch" sx={sx}>
      <HeaderComponent title="Services" />
      <Grid container>
        {services.map((service) => (
          <Grid item xs={4} key={service.id}>
            <FormControlLabel
              control={
                <RedCheckbox
                  checked={selected.includes(service.name)}
                  onChange={(e) => handleCheckBoxChange(e, service.name)}
                  name="checkedG"
                />
              }
              label={service.label}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
export default ServiceEditor;
