import { Grid, Stack } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonDefault from '../components/base/ButtonDefault';
import PageHeader from '../components/base/PageHeader';
import Panel from '../components/base/Panel';
import DefaultRolePicker from '../components/project/DefaultRolePicker';
import ProjectPropertiesEditor, {
  ProjectPropertiesDictionary,
} from '../components/project/ProjectPropertiesEditor';
import ServiceEditor from '../components/project/ServiceEditor';
import MESSAGES from '../constants/messagesConstants';
import PathGenerators from '../constants/pathGenerators';
import AuthorizedPage from '../containers/AuthorizedPage';
import projectService from '../services/projectService';
import rolesService from '../services/rolesService';
import { IDefaultRole } from '../services/transportTypes/NRole';
import { ServiceNames } from '../services/transportTypes/NService';
import { IService } from '../utilities/backendTypes';
import useAlert from '../utilities/hooks/useAlert';
import useServiceList from '../utilities/hooks/useServiceList';

/*
  Valid slug:
    - consists of lowercase alpha-numeric characters, '-' and '_'
    - has at least one alpha character
    - does not start or end with a '-' or '_'
*/
function isSlugFormatValid(slug: string): boolean {
  return !!slug.match(/^[a-z0-9]+([-_]+[a-z0-9]+)*$/) && !!slug.match(/[a-z]/);
}

const ProjectCreate = (): JSX.Element => {
  const navigate = useNavigate();
  const alert = useAlert();

  const { data: defaultRoles = [] } = useQuery<IDefaultRole[]>(
    ['getDefaultRoles'],
    () => rolesService.getDefaultRoles(),
  );

  const [services] = useServiceList();

  const [properties, setProperties] = useState<ProjectPropertiesDictionary>({
    name: '',
    client: '',
    logo_url: null,
    slug: '',
  });
  const [fieldErrors, setFieldErrors] = useState<string[]>([]);
  const [serviceNames, setServiceNames] = useState<IService['name'][]>([
    ServiceNames.Core,
  ]);
  const [roleIds, setRoleIds] = useState<IDefaultRole['id'][]>([]);

  const addProjectMutation = useMutation(projectService.addProject, {
    onSuccess: (project) => {
      alert({
        message: MESSAGES.PROJECT_ADDED,
        severity: 'success',
      });
      navigate(PathGenerators.PROJECTS_HOME(project.slug));
    },
    onError: (error: Error) => {
      console.log('Add Project error', { error });
      alert({
        message: error.message,
        severity: 'error',
      });
    },
  });

  const handleSave = () => {
    const errors: string[] = [];

    if (!properties.name) {
      errors.push('name');
    }
    if (!properties.client) {
      errors.push('client');
    }
    if (errors.length) {
      alert({
        message: 'Please fill all mandatory fields.',
        severity: 'error',
      });
    }

    if (!properties.slug) {
      errors.push('slug');
    } else if (!isSlugFormatValid(properties.slug)) {
      alert({
        message:
          'Slug must have letters with no spaces and only contain (a-z, 0-9, -, _).',
        severity: 'error',
      });
      errors.push('slug');
    }

    if (!serviceNames.includes(ServiceNames.Core)) {
      alert({
        message: 'Core services must be selected.',
        severity: 'error',
      });
      errors.push('slug');
    }
    // Only dashboard project can have admin
    if (serviceNames.includes(ServiceNames.PlatformAdmin)) {
      alert({
        message: 'Admin services may not be selected.',
        severity: 'error',
      });
      errors.push('slug');
    }

    console.log('onSave', {
      properties,
      serviceIds: serviceNames,
      roleIds,
      errors,
    });
    setFieldErrors(errors);
    if (errors.length === 0) {
      addProjectMutation.mutate({
        name: properties.name,
        client: properties.client,
        logo_url: properties.logo_url,
        slug: properties.slug,
        services: services
          .filter((s) => serviceNames.includes(s.name))
          .map((s) => s.id),
        roles: defaultRoles.filter((r) => roleIds.includes(r.id)),
        special_permissions: false,
      });
    }
  };

  return (
    <AuthorizedPage>
      <PageHeader title="Create Project" hintMessage="Create a new project" />
      <Panel>
        <Grid container spacing={10} alignItems="flex-start">
          <Grid item xs={12} sm={12} md={12} lg={7}>
            <ProjectPropertiesEditor
              values={properties}
              errors={fieldErrors}
              onChange={(update) => setProperties(update)}
              slugEditable
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5}>
            <Stack alignItems="stretch" spacing={4}>
              <ServiceEditor
                services={services.filter(
                  (s) => s.name !== ServiceNames.PlatformAdmin,
                )}
                selected={serviceNames}
                onChange={(update) => setServiceNames(update)}
              />
              <DefaultRolePicker
                roles={defaultRoles}
                selected={roleIds}
                onChange={(update) => setRoleIds(update)}
              />
            </Stack>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="flex-end">
          <ButtonDefault
            large
            onClick={handleSave}
            isLoading={addProjectMutation.isLoading}
          >
            Save
          </ButtonDefault>
        </Stack>
      </Panel>
    </AuthorizedPage>
  );
};

export default ProjectCreate;
