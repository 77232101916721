import { Delete } from '@mui/icons-material';
import { Grid, Stack, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import AlertModal from '../components/base/AlertModal';
import ButtonDefault from '../components/base/ButtonDefault';
import PageHeader from '../components/base/PageHeader';
import Panel from '../components/base/Panel';
import PermissionsCheckboxEditor, {
  PermissionsCheckboxEditorProps,
} from '../components/roles/PermissionsCheckboxEditor';
import RolesButtonsList from '../components/roles/RolesButtonsList';
import MESSAGES from '../constants/messagesConstants';
import AuthorizedPage from '../containers/AuthorizedPage';
import rolesService from '../services/rolesService';
import {
  IPermission,
  PermissionAction,
  PermissionName,
} from '../services/transportTypes/BaseTypes';
import { IRoleDetails } from '../utilities/frontendTypes';
import { notEmpty } from '../utilities/functions';
import useAlert from '../utilities/hooks/useAlert';
import useProject from '../utilities/hooks/useProject';
import useProjectPermissions from '../utilities/hooks/useProjectPermissions';
import useRolePermissions from '../utilities/hooks/useRolePermissions';

type UpdateRoleArg = Parameters<typeof rolesService.updateRole>[0];
type UpdatePermissionArg = Parameters<
  typeof rolesService.updateRolePermission
>[0];

function copyPermissionArray(
  permissions: IPermission[],
): PermissionsCheckboxEditorProps['permissions'] {
  return permissions.map(
    ({ name, label, create, read, update, delete: del }) => ({
      name,
      label,
      create,
      read,
      update,
      delete: del,
    }),
  );
}

function isPermissionEqual(
  a: Pick<IPermission, PermissionAction>,
  b: Pick<IPermission, PermissionAction>,
): boolean {
  return (
    a.create === b.create &&
    a.read === b.read &&
    a.update === b.update &&
    a.delete === b.delete
  );
}

const RolesDefine = (): JSX.Element => {
  const alert = useAlert();
  const [searchParams, setSearchParams] = useSearchParams();
  const [project, projectLoading, invalidateProject] = useProject();

  const roleParam = searchParams.get('id');
  const roleId = roleParam !== null ? Number.parseInt(roleParam) : null;
  const role = project?.roles?.find((r) => r.id === roleId);

  const [rolePermissions, rolePermissionsLoading, invalidateRole] =
    useRolePermissions(role);
  const [projectPermissions, projectPermissionsLoading] =
    useProjectPermissions(project);

  const [name, setName] = useState('');
  const [permissions, setPermissions] = useState<
    PermissionsCheckboxEditorProps['permissions']
  >([]);
  const [markedForDelete, setMarkedForDelete] = useState(false);

  const deleteRoleMutation = useMutation({
    mutationFn: rolesService.deleteRole,
    mutationKey: [rolesService.deleteRole.name],
    onSuccess() {
      alert({
        message: MESSAGES.ROLE_DELETED,
        severity: 'success',
      });
      Promise.all([invalidateRole(role!), invalidateProject(project!)]).catch(
        (err) => console.error(err),
      );
    },
    onError(err: Error, params) {
      console.error(`Failed to delete role ${params.role_name}:`, err.message);
      alert({ message: err.message, severity: 'error' });
    },
  });

  const updateRoleAndPermissionsMutation = useMutation({
    mutationKey: ['updateRoleAndPermissions'],
    mutationFn({
      roleArgs,
      permissionArgs,
    }: {
      roleArgs?: UpdateRoleArg;
      permissionArgs?: UpdatePermissionArg[];
    }) {
      const promises: Promise<any>[] = [];

      if (roleArgs) {
        promises.push(rolesService.updateRole(roleArgs));
      }

      if (permissionArgs) {
        promises.push(
          ...permissionArgs.map((arg) =>
            rolesService.updateRolePermission(arg),
          ),
        );
      }

      return Promise.all(promises);
    },
    onSuccess() {
      // console.log(`Updated role ${response.role_name}`);
      alert({
        message: MESSAGES.ROLE_UPDATED,
        severity: 'success',
      });
      return Promise.all([
        invalidateRole(role!),
        invalidateProject(project!),
      ]).catch((err) => console.error(err));
    },
    onError(err: Error, params) {
      console.error(
        `Failed to update role ${
          params.roleArgs?.role_name ??
          params.permissionArgs?.[0]?.pathParams.roleId ??
          'Undefined'
        }:`,
        err.message,
      );
      alert({ message: err.message, severity: 'error' });
    },
  });

  const addRoleMutation = useMutation({
    mutationFn: rolesService.addRole,
    mutationKey: [rolesService.addRole.name],
    onSuccess(response) {
      // console.log(`Added role ${response.role_name}`);

      // select new role
      searchParams.set('id', response.id.toString());
      setSearchParams(searchParams);

      alert({ message: MESSAGES.ROLE_ADDED, severity: 'success' });
      Promise.all([invalidateProject(project!)]).catch((err) =>
        console.error(err),
      );
    },
    onError(err: Error, params) {
      console.error(
        `Failed to add role ${params.body.role_name}:`,
        err.message,
      );
      alert({ message: err.message, severity: 'error' });
    },
  });

  const handlePermissionToggle = (
    permissionName: PermissionName,
    action: PermissionAction,
  ) => {
    // console.log(`Permission toggle: ${permissionName}-${action}`);

    setPermissions(
      permissions.map((p) => {
        if (p.name === permissionName) {
          return {
            ...p,
            [action]: !p[action],
          };
        }
        return p;
      }),
    );
  };

  const handleNameChange = (updated: string) => {
    setName(updated);
  };

  const handleButtonSelected = (selected: IRoleDetails) => {
    searchParams.set('id', selected.id.toString());
    setSearchParams(searchParams);
  };

  const handleAddRole = () => {
    searchParams.delete('id');
    setSearchParams(searchParams);
  };

  const handleDelete = () => {
    if (project) {
      if (role) {
        deleteRoleMutation.mutate(role);
      }

      if (project.roles.length > 0) {
        searchParams.set('id', project.roles[0].id.toString());
      } else {
        searchParams.delete('id');
      }
      setSearchParams(searchParams);
    } else {
      alert({
        message: 'Role delete failed as project was not loaded',
        severity: 'error',
      });
    }
    setMarkedForDelete(false);
  };

  const handleSave = () => {
    if (project) {
      if (role) {
        let roleArgs: UpdateRoleArg | undefined;
        // Update role name
        if (name !== role.role_name) {
          roleArgs = {
            ...role,
            role_name: name,
          };
        }

        // Update role permissions
        const permissionArgs = projectPermissions
          .map<UpdatePermissionArg | null>((base) => {
            const existing = rolePermissions.find((p) => p.name === base.name);
            const updated = permissions.find((p) => p.name === base.name);

            if (
              updated &&
              (!existing || !isPermissionEqual(updated, existing))
            ) {
              const { create, read, update, delete: del } = updated;

              return {
                pathParams: {
                  projectId: project.slug,
                  roleId: role.id,
                  permissionName: base.name,
                },
                body: {
                  create,
                  read,
                  update,
                  delete: del,
                },
              };
            }
            return null;
          })
          .filter(notEmpty);

        updateRoleAndPermissionsMutation.mutate({
          roleArgs,
          permissionArgs,
        });
      } else {
        // Create new role
        addRoleMutation.mutate({
          projectId: project.slug,
          body: {
            role_name: name,
            permissions: projectPermissions.map((permission) => {
              const values = permissions.find(
                (p) => p.name === permission.name,
              );

              return {
                ...permission,
                create: values?.create ?? false,
                read: values?.read ?? false,
                update: values?.update ?? false,
                delete: values?.delete ?? false,
              };
            }),
          },
        });
      }
    }
  };

  useEffect(() => {
    // Refresh permissions when selected role changes
    if (!rolePermissionsLoading && rolePermissions.length) {
      // console.log('Resetting Permissions for role change');
      setPermissions(
        // Create copy of permissions to avoid corrupting memory
        copyPermissionArray(rolePermissions),
      );
    } else if (!projectPermissionsLoading) {
      // console.log('Resetting Permissions for role clear');
      setPermissions(
        // Create copy of permissions to avoid corrupting memory
        copyPermissionArray(projectPermissions),
      );
    }
  }, [
    roleParam,
    rolePermissions,
    rolePermissionsLoading,
    projectPermissions,
    projectPermissionsLoading,
  ]);

  useEffect(() => {
    // Refresh role name when selected role changes
    if (role) {
      setName(role.role_name);
    } else if (!projectLoading) {
      setName('');
    }
  }, [role]);

  return (
    <AuthorizedPage>
      <PageHeader
        title="Project - Define Roles"
        hintMessage="Add and edit roles to this project."
        displayGoBack
      />
      <Grid className="dashboard-body project-define-roles">
        <Grid container item xs={12}>
          <RolesButtonsList
            roles={project?.roles ?? []}
            selected={roleId ?? undefined}
            onSelect={handleButtonSelected}
            onAdd={handleAddRole}
            isLoading={projectLoading}
          />
        </Grid>
        <Panel>
          <Stack spacing={1}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ mb: 2 }}
            >
              <TextField
                variant="outlined"
                label="Title"
                placeholder="Role Title"
                value={name}
                onChange={(e) => handleNameChange(e.target.value)}
                sx={{ flex: '1 1 0' }}
                disabled={updateRoleAndPermissionsMutation.isLoading}
              />
              <IconButton onClick={() => setMarkedForDelete(true)}>
                <Delete />
              </IconButton>
            </Stack>
            <PermissionsCheckboxEditor
              permissions={permissions}
              onChange={handlePermissionToggle}
              listLoading={projectLoading || projectPermissionsLoading}
              valuesLoading={rolePermissionsLoading && !!role}
              disabled={updateRoleAndPermissionsMutation.isLoading}
            />
            <Grid
              container
              item
              xs={12}
              direction="row"
              justifyContent="flex-end"
              className="define-roles-footer"
            >
              <ButtonDefault
                variant="primary"
                type="submit"
                onClick={handleSave}
                isLoading={updateRoleAndPermissionsMutation.isLoading}
              >
                <p>SAVE</p>
              </ButtonDefault>
            </Grid>
            {markedForDelete && (
              <AlertModal
                title="Delete Confirmation"
                modalStatus={markedForDelete}
                content={`Are you sure you want to delete ${role?.role_name}?`}
                handleCancel={() => setMarkedForDelete(false)}
                handleConfirm={handleDelete}
              />
            )}
          </Stack>
        </Panel>
      </Grid>
    </AuthorizedPage>
  );
};

export default RolesDefine;
