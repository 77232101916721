const API = {
  USER: '/user',
  ADMIN: '/admin',
  EVENT: '/event',
  DEFAULT: '',
  FORGOT_PASSWORD: '/auth/forgot',
  RESET_PASSWORD: '/auth/reset',
  PROJECT: '/project',
  ANALYTICS: '/analytics',
  HISTORY: '/history',
  LOGIN_DATA: '/login',
  SERVICE: '/service',
} as const;
export default API;
