import { useMutation } from '@tanstack/react-query';
import React from 'react';
import MESSAGES from '../../constants/messagesConstants';
import { IAdminUser } from '../../services/transportTypes/NAdminUser';
import userService from '../../services/userService';
import useAlert from '../../utilities/hooks/useAlert';
import ButtonDefault, { ButtonVariants } from '../base/ButtonDefault';

export interface ResetPasswordButtonProps {
  user?: IAdminUser;
  self?: boolean;
}

const ResetPasswordButton = (props: ResetPasswordButtonProps): JSX.Element => {
  const { user, self } = props;

  const alert = useAlert();

  const forgotPasswordMutation = useMutation({
    mutationFn: userService.forgotPassword,
    mutationKey: ['forgotPassword'],
    onSuccess: () => {
      alert({
        message: self
          ? MESSAGES.RESET_PASSWORD_EMAIL_SENT_SELF
          : MESSAGES.RESET_PASSWORD_EMAIL_SENT,
        severity: 'success',
      });
    },
    onError: (error: Error) => {
      console.error('forgotPassword error', { error });
      alert({
        message: error.message,
        severity: 'error',
      });
    },
  });

  const handleResetPassword = () => {
    if (user) {
      forgotPasswordMutation.mutate({
        email: user.email,
      });
    }
  };

  return (
    <>
      <ButtonDefault
        variant={ButtonVariants.secondary}
        onClick={handleResetPassword}
        disabled={!user}
        isLoading={forgotPasswordMutation.isLoading}
      >
        Reset Password
      </ButtonDefault>
    </>
  );
};

export default ResetPasswordButton;
