import React, { ReactNode } from 'react';
import { Box, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { combineSxProp } from '../../utilities/functions';

export interface GraphHeaderProps {
  name: string;
  category: string;
  children?: ReactNode | ReactNode[];
  sx?: SxProps;
}

const GraphHeader = (props: GraphHeaderProps): JSX.Element => {
  const { name, category, children, sx } = props;

  return (
    <Stack
      direction="row"
      justifyContent="stretch"
      sx={combineSxProp({ px: 3, pt: 2.5, pb: 1, width: '100%' }, sx)}
    >
      <Box sx={{ flexGrow: 1, flexShrink: 1 }}>
        <Typography variant="h3">{name}</Typography>
        <Typography variant="subtitle2">Category: {category}</Typography>
      </Box>
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
        {children}
      </Stack>
    </Stack>
  );
};

export default GraphHeader;
