import { Delete, Edit } from '@mui/icons-material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageHeader from '../components/base/PageHeader';
import { BodyPanel } from '../components/base/Panel';
import TableDefault, { ColumnData } from '../components/base/TableDefault';
import SchemaSlider from '../components/cms/SchemaSlider';
import pathGenerators from '../constants/pathGenerators';
import queryConstants from '../constants/queryConstants';
import AuthorizedPage from '../containers/AuthorizedPage';
import cmsService from '../services/cmsService';
import {
  DocumentDataField,
  DocumentFieldType,
  DocumentSchemaField,
  IDocument,
  IDocumentSchema,
} from '../services/transportTypes/NCms';
import { flattenObject, formatDate } from '../utilities/functions';
import useAlert from '../utilities/hooks/useAlert';
import useDeleteConfirmation from '../utilities/hooks/useDeleteConfirmation';
import useDocumentList from '../utilities/hooks/useDocumentList';
import useDocumentSchemaList from '../utilities/hooks/useDocumentSchemaList';
import useIdSearch from '../utilities/hooks/useIdSearch';
import useProject from '../utilities/hooks/useProject';
import useUrlFilters from '../utilities/hooks/useUrlFilters';

export type FlatDocument = Omit<IDocument, 'data'> & IDocument['data'];

function schemaTypeToColumnType(
  type: DocumentFieldType,
): ColumnData<FlatDocument>['type'] {
  switch (type) {
    case 'float':
    case 'int':
      return 'number';
    case 'boolean':
      return 'string';
    case 'string':
    default:
      return 'string';
  }
}

function formatFieldValue(
  f: DocumentSchemaField,
  value: DocumentDataField,
): string {
  return value?.toString() ?? 'null';
}

function createColumns(schema?: IDocumentSchema): ColumnData<FlatDocument>[] {
  const fieldColumns =
    schema?.fields.slice(0, 3).map((f) => ({
      id: f.name,
      type: schemaTypeToColumnType(f.type),
      formatter: (val: DocumentFieldType) => formatFieldValue(f, val),
    })) ?? [];

  return [
    {
      id: 'sequence',
      type: 'number',
      label: 'Sequence',
    },
    {
      id: 'id',
      type: 'number',
      label: 'Id',
    },
    {
      id: 'name',
      type: 'string',
      label: 'Name',
    },
    ...fieldColumns,
    {
      id: 'created_at',
      type: 'date',
      label: 'Created at',
      formatter: (date: IDocumentSchema['created_at']) => formatDate(date),
    },
    {
      id: 'updated_at',
      type: 'date',
      label: 'Last updated at',
      formatter: (date: IDocumentSchema['updated_at']) =>
        date && formatDate(date),
    },
  ];
}

const Documents = (): JSX.Element => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const alert = useAlert();

  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get(queryConstants.SEARCH);
  const [filters, updateFilters, applyFilters] = useUrlFilters<FlatDocument>();

  const schemaId = useIdSearch();
  const [project, projectLoading] = useProject();
  const [projectSchemas, schemasLoading] = useDocumentSchemaList(project);
  const schema = projectSchemas.find((s) => s.id === schemaId);
  const [documents, documentsLoading] = useDocumentList(project, schema);

  const flatDocuments = documents.map((d) => flattenObject(d, 'data'));
  const filteredDocuments = applyFilters(flatDocuments).filter(
    (d) =>
      !searchValue ||
      Object.values(d)
        .join('|')
        .toLowerCase()
        .includes(searchValue.toLowerCase()),
  );

  const deleteDocumentMutation = useMutation({
    mutationKey: [cmsService.deleteDocument.name],
    mutationFn: cmsService.deleteDocument,
  });

  const handleSchemaChange = (selected: IDocumentSchema) => {
    if (selected) {
      navigate(pathGenerators.DOCUMENTS(project?.slug, selected.id.toString()));
    }
  };

  const handleAdd = () => {
    navigate(
      pathGenerators.DOCUMENT_CREATE(project?.slug, schema?.id.toString()),
    );
  };

  const handleEdit = (document: FlatDocument) => {
    navigate(
      pathGenerators.DOCUMENT_EDIT(project?.slug, document.id.toString()),
    );
  };

  const performDelete = (items: FlatDocument[]) => {
    console.log('Delete Schemas', items);
    Promise.all(
      items.map((i) =>
        deleteDocumentMutation.mutate({
          projectId: project!.id,
          documentId: i.id,
        }),
      ),
    )
      .then(() => {
        alert(
          `${
            items.length === 1 ? items[0].name : `${items.length} schemas`
          } deleted`,
        );
        return queryClient.resetQueries([
          cmsService.listSchemaDocuments.name,
          {
            projectId: project?.slug,
            schemaId: schema?.id,
          },
        ]);
      })
      .catch((err: Error) => {
        console.error(err);
        alert({
          message: err.message,
          severity: 'error',
        });
      });
  };

  const [DeleteConfirmation, markForDelete] = useDeleteConfirmation({
    performDelete,
    summary: (items) =>
      items.length === 1 ? items[0].name : `${items.length} documents`,
  });

  const handleDelete = (items: FlatDocument | FlatDocument[]) => {
    markForDelete(Array.isArray(items) ? items : [items]);
  };

  useEffect(() => {
    if (!schemaId && projectSchemas.length) {
      navigate(
        pathGenerators.DOCUMENTS(
          project?.slug,
          projectSchemas[0].id.toString(),
        ),
      );
    }
  }, [projectSchemas, schemaId]);

  return (
    <AuthorizedPage>
      <PageHeader
        title={`${schema?.name ?? 'CMS'} - Documents`}
        hintMessage="Create and edit content."
        displaySearch
        displayGoBack
      />
      <BodyPanel>
        <SchemaSlider
          schemas={projectSchemas}
          selected={schema?.id ?? null}
          onChange={handleSchemaChange}
          isLoading={projectLoading || schemasLoading}
        />
        <TableDefault
          columns={createColumns(schema)}
          rows={filteredDocuments}
          filters={filters}
          onFiltersUpdate={updateFilters}
          isLoading={projectLoading || schemasLoading || documentsLoading}
          onAddNew={handleAdd}
          onMassDelete={handleDelete}
          actions={[
            {
              icon: Edit,
              action: handleEdit,
              tooltip: 'Edit',
              key: 'edit',
            },
            {
              icon: Delete,
              action: handleDelete,
              tooltip: 'Delete',
              key: 'delete',
            },
          ]}
        />
      </BodyPanel>
      {DeleteConfirmation}
    </AuthorizedPage>
  );
};

export default Documents;
