export interface EventGroup<Event> {
  id: string;
  name: string;
  data: number;
  percentage: number;
  events: Event[];
}

export default function <Event> (
  transformer: (e: Event) => EventGroup<Event>,
  events: Event[],
): EventGroup<Event>[] {
  const groups = events.reduce<EventGroup<Event>[]>((acc, e) => {
    const agg = transformer(e);
    const group = acc.find((g) => g.id === agg.id);
    if (group) {
      group.data += agg.data;
      group.events.push(...agg.events);
    } else {
      acc.push(agg);
    }
    return acc;
  }, []);

  const total = groups.reduce((acc, g) => acc + g.data, 0);

  return groups
    .map((g) => ({
      ...g,
      percentage: (g.data * 100) / total,
    }))
    .sort((a, b) => b.data - a.data);
}
