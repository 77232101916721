import { SxProps } from '@mui/system';
import React from 'react';
import { IOptionsFilter } from '../../constants/analyticsConstants';
import SelectDefault from '../base/SelectDefault';

export interface FilterSelectProps {
  selected: IOptionsFilter | null;
  options: IOptionsFilter[];
  onChange: (selected: IOptionsFilter | null) => void;
  sx?: SxProps;
}

const FilterSelect = (props: FilterSelectProps): JSX.Element => {
  const { selected, options, onChange, sx } = props;

  const handleChange = (value: string | null) => {
    onChange(options.find((opt) => opt.id === value) ?? null);
  };

  return (
    <SelectDefault
      value={selected?.id}
      options={options}
      onChange={handleChange}
      displayEmpty
      sx={sx}
    />
  );
};

export default FilterSelect;
