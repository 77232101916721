import { Paper, PaperProps } from '@mui/material';
import React from 'react';
import DesignConstants from '../../constants/designConstants';
import { combineSxProp } from '../../utilities/functions';

export interface PanelProps extends PaperProps {}

const Panel = (props: PanelProps): JSX.Element => {
  const { sx, elevation = 0, children, ...others } = props;

  return (
    <Paper
      {...others}
      sx={combineSxProp(
        {
          border: '1px solid #dee6eb',
          borderRadius: 2.5,
          backgroundColor: DesignConstants.COLOR_WHITE,
          padding: 2,
        },
        sx,
      )}
      elevation={elevation}
    >
      {children}
    </Paper>
  );
};

export default Panel;

export const BodyPanel = ({ sx, ...others }: PanelProps) => (
  <Panel
    sx={combineSxProp(
      {
        minHeight: 500,
        padding: 3,
      },
      sx,
    )}
    {...others}
  />
);

export const InputPanel = ({ sx, ...others }: PanelProps) => (
  <Panel
    sx={combineSxProp(
      {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 1,
        px: 1.75,
        py: 2,

        '&:hover': {
          borderColor: 'rgba(0, 0, 0, 0.87)',
        },
      },
      sx,
    )}
    {...others}
  />
);
