import DatabaseConstants from '../constants/databaseConstants';
import {
  IPermission,
  IProject,
  PermissionAction,
  PermissionActions,
  PermissionName,
  PermissionNames,
} from '../services/transportTypes/BaseTypes';
import { IProjectSummary } from '../services/transportTypes/NProject';
import {
  IService,
  ServiceName,
  ServiceNames,
} from '../services/transportTypes/NService';

export function isProjectPermitted(
  projectId: IProject['id'] | undefined,
  permissions: IPermission[],
  name: PermissionName,
  action: PermissionAction,
): boolean {
  return (
    permissions.find((p) => p.name === name && p.project_id === projectId)?.[
      action
    ] ?? false
  );
}

export function isAdminPermitted(
  permissions: IPermission[],
  name: PermissionName,
  action: PermissionAction,
): boolean {
  return isProjectPermitted(
    DatabaseConstants.ADMIN_PROJECT_ID,
    permissions,
    name,
    action,
  );
}

export function isServiceAvailable(
  services: IService[] | undefined,
  name: ServiceName,
): boolean {
  return services?.some((s) => s.name === name) ?? false;
}

export function isPermitted({
  project,
  permissions,
  service,
  projectPermission,
  adminPermission,
  action,
}: {
  project?: IProjectSummary;
  permissions: IPermission[];
  service?: ServiceName;
  projectPermission?: PermissionName;
  adminPermission?: PermissionName;
  action: PermissionAction;
}): boolean {
  return (
    isServiceAvailable(project?.services, service ?? ServiceNames.Core) &&
    ((projectPermission !== undefined &&
      isProjectPermitted(
        project?.id,
        permissions,
        projectPermission,
        action,
      )) ||
      (adminPermission !== undefined &&
        isAdminPermitted(permissions, adminPermission, action)))
  );
}

export function showAnalyticsControls(
  project: IProjectSummary | undefined,
  permissions: IPermission[],
): boolean {
  return isPermitted({
    project,
    permissions,
    service: ServiceNames.Analytics,
    projectPermission: PermissionNames.Analytics,
    adminPermission: PermissionNames.AdminAnalytics,
    action: PermissionActions.Read,
  });
}
