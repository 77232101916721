import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import {
  IAdminUser,
  TwoFactorMode,
  TwoFactorState,
} from '../../services/transportTypes/NAdminUser';
import userService from '../../services/userService';
import useAlert from '../../utilities/hooks/useAlert';
import ButtonDefault from '../base/ButtonDefault';
import TwoFactorEnableOverlay from '../TwoFactorEnableOverlay';

export interface twoFactorStateButtonsProps {
  user?: IAdminUser;
  self?: boolean;
  onEnable2fa?: () => void;
  onDisable2fa?: () => void;
}

const TwoFactorStateButtons = (
  props: twoFactorStateButtonsProps,
): JSX.Element => {
  const { user, self, onEnable2fa, onDisable2fa } = props;

  const alert = useAlert();

  const [twoFactorQR, setTwoFactorQR] = useState<string>();

  const updateUserMutation = useMutation({
    mutationFn: userService.updateUser,
    mutationKey: ['updateUser'],
    onError: (error: Error) => {
      console.error('updateUser error', { error });
      alert({
        message: error.message,
        severity: 'error',
      });
    },
  });

  const update2faMutation = useMutation({
    mutationFn: userService.update2fa,
    mutationKey: [userService.update2fa.name],
    onError: (error: Error) => {
      console.error('update2fa error', { error });
      alert({
        message: error.message,
        severity: 'error',
      });
    },
  });

  const verify2faMutation = useMutation({
    mutationFn: userService.verify2fa,
    mutationKey: [userService.verify2fa.name],
    onSuccess() {
      alert({
        message: 'Two Factor Authentication has been enabled',
        severity: 'success',
      });
      setTwoFactorQR(undefined);

      if (onEnable2fa) {
        onEnable2fa();
      }
    },
    onError: (error: Error) => {
      console.error('verify2fa error', { error });
      alert({
        message: error.message,
        severity: 'error',
      });
    },
  });

  const handleEnable2fa = () => {
    update2faMutation.mutate(
      { mode: TwoFactorMode.App },
      {
        onSuccess(response) {
          setTwoFactorQR(response.qr);
        },
      },
    );
  };

  const handleCheck2fa = (otp: string) => {
    verify2faMutation.mutate({ otp });
  };

  const handleDisable2fa = () => {
    const options = {
      onSuccess() {
        alert({
          message: 'Two Factor Authentication has been removed',
          severity: 'success',
        });

        if (onDisable2fa) {
          onDisable2fa();
        }
      },
    };

    if (self) {
      update2faMutation.mutate({ mode: TwoFactorMode.Disabled }, options);
    } else if (user) {
      updateUserMutation.mutate(
        { id: user.id, two_factor_mode: TwoFactorMode.Disabled },
        options,
      );
    }
  };

  return (
    <>
      {user?.two_factor_state === TwoFactorState.Active && (
        <ButtonDefault
          onClick={handleDisable2fa}
          isLoading={
            updateUserMutation.isLoading || update2faMutation.isLoading
          }
        >
          Disable 2FA
        </ButtonDefault>
      )}
      {self && user?.two_factor_state !== TwoFactorState.Active && (
        <ButtonDefault
          onClick={handleEnable2fa}
          variant="secondary"
          isLoading={update2faMutation.isLoading}
        >
          Enable 2FA
        </ButtonDefault>
      )}

      <TwoFactorEnableOverlay
        qrUrl={twoFactorQR}
        onCheck={handleCheck2fa}
        onClose={() => {
          setTwoFactorQR(undefined);
        }}
        open={!!twoFactorQR}
      />
    </>
  );
};

export default TwoFactorStateButtons;
