import { capitalize, Grid, Skeleton, Stack } from '@mui/material';
import React, { ChangeEvent } from 'react';
import {
  DocumentDataField,
  DocumentSchemaFieldDefinition,
} from '../../services/transportTypes/NCms';
import { camelCaseToSpaceSeparated } from '../../utilities/functions';
import HeaderComponent from '../base/HeaderComponent';
import InputDefault from '../base/InputDefault';
import NumericInput from '../base/NumericInput';
import DocumentEditorField from './DocumentEditorField';

export interface DocumentEditorProps {
  name: string;
  sequence: number;
  fields: DocumentSchemaFieldDefinition[];
  data: Record<string, DocumentDataField> | null;
  errors?: string[];
  isLoading?: boolean;
  onNameChange: (name: string) => void;
  onSequenceChange: (sequence: number) => void;
  onDataChange: (name: string, value: DocumentDataField) => void;
}

const DocumentEditor = (props: DocumentEditorProps): JSX.Element => {
  const {
    name,
    sequence,
    fields,
    data,
    errors,
    isLoading,
    onNameChange,
    onSequenceChange,
    onDataChange,
  } = props;

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    onNameChange(e.target.value);
  };

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={4}>
        <HeaderComponent title="Details" />
        <Stack spacing={2}>
          {isLoading ? (
            <>
              <Skeleton variant="rounded" height={56} />
              <Skeleton variant="rounded" height={56} />
            </>
          ) : (
            <>
              <InputDefault
                label="Name"
                placeholder="New document"
                value={name}
                error={errors?.includes('name')}
                onChange={handleNameChange}
                required
              />
              <NumericInput
                label="Sequence"
                placeholder="0"
                value={sequence}
                error={errors?.includes('sequence')}
                onChange={(val) => onSequenceChange(val ?? 0)}
                required
              />
            </>
          )}
        </Stack>
      </Grid>
      <Grid item xs={8}>
        <HeaderComponent title="Fields" />
        <Stack spacing={2}>
          {isLoading ? (
            <>
              <Skeleton variant="rounded" height={56} />
              <Skeleton variant="rounded" height={56} />
              <Skeleton variant="rounded" height={56} />
              <Skeleton variant="rounded" height={56} />
            </>
          ) : (
            fields.map((f) => (
              <DocumentEditorField
                key={f.name}
                name={camelCaseToSpaceSeparated(capitalize(f.name))}
                value={data && data[f.name]}
                type={f.type}
                properties={f.properties}
                error={errors?.includes(f.name)}
                onChange={(v) => onDataChange(f.name, v)}
              />
            ))
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DocumentEditor;
