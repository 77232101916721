import { ArrowBack } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PATHS from '../../constants/pathGenerators';
import { useAppDispatch } from '../../store/store';
import { logout } from '../../store/userSlice';
import ButtonDefault, { ButtonSizes, ButtonVariants } from './ButtonDefault';
import UrlSearchField from './UrlSearchField';

interface HeaderProps {
  title: string;
  hintMessage: string;
  displaySearch?: boolean;
  displayGoBack?: boolean;
  shouldCapitalizeHint?: boolean;
  displayEdit?: boolean;
  handleEdit?: () => void;
}

const PageHeader = (props: HeaderProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    title,
    hintMessage,
    displaySearch,
    handleEdit,
    displayEdit,
    displayGoBack,
    shouldCapitalizeHint,
  } = props;

  const handleLogout = () => {
    dispatch(logout());
    navigate(PATHS.LOGIN());
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Grid
      container
      className="header page-header"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid container item xs={12} sm={12} md={12} lg={6} direction="row">
        {displayGoBack && (
          <Grid onClick={goBack} role="button">
            <Tooltip title="Go Back">
              <IconButton aria-label="Go Back" className="" size="large">
                <ArrowBack />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        <div className="page-header__title">
          <Typography variant="h1">{title}</Typography>
          <Typography
            variant="subtitle1"
            className={shouldCapitalizeHint ? 'capitalize' : ''}
          >
            {hintMessage}
          </Typography>
        </div>
        {displayEdit && (
          <Grid onClick={handleEdit} role="button">
            <Tooltip title="Edit">
              <IconButton aria-label="Edit" className="" size="large">
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} lg={6} justifyContent="flex-end">
        <Grid container justifyContent="flex-end">
          {displaySearch && (
            <Grid container item xs={12} lg={8} justifyContent="flex-end">
              <UrlSearchField />
            </Grid>
          )}
          {title === 'My Account' && (
            <Grid className="page-header__logout-btn">
              <ButtonDefault
                variant={ButtonVariants.subtle}
                size={ButtonSizes.small}
                onClick={handleLogout}
              >
                Logout <ExitToAppIcon sx={{ marginLeft: 1 }} />
              </ButtonDefault>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PageHeader;
