import { Stack, Switch } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import {
  DocumentDataField,
  DocumentFieldType,
  DocumentSchemaField,
} from '../../services/transportTypes/NCms';
import InputDefault from '../base/InputDefault';
import InputPanel from '../base/InputPanel';
import NumericInput from '../base/NumericInput';

export interface DocumentEditorStringProps {
  name: string;
  value: string | null;
  properties: DocumentSchemaField['properties'];
  error?: boolean;
  onChange: (value: string | null) => void;
}

export const DocumentEditorString = (
  props: DocumentEditorStringProps,
): JSX.Element => {
  const { name, value, properties, error, onChange } = props;

  return (
    <InputDefault
      label={name}
      value={value ?? ''}
      error={error}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export interface DocumentEditorFloatProps {
  name: string;
  value: number | null;
  properties: DocumentSchemaField['properties'];
  error?: boolean;
  onChange: (value: number | null) => void;
}

export const DocumentEditorFloat = (
  props: DocumentEditorFloatProps,
): JSX.Element => {
  const { name, value, properties, error, onChange } = props;

  return (
    <NumericInput
      label={name}
      value={value}
      float
      error={error}
      onChange={onChange}
    />
  );
};

export interface DocumentEditorIntProps {
  name: string;
  value: number | null;
  properties: DocumentSchemaField['properties'];
  error?: boolean;
  onChange: (value: number | null) => void;
}

export const DocumentEditorInt = (
  props: DocumentEditorIntProps,
): JSX.Element => {
  const { name, value, properties, error, onChange } = props;

  return (
    <NumericInput
      label={name}
      value={value}
      error={error}
      onChange={onChange}
    />
  );
};

export interface DocumentEditorBoolProps {
  name: string;
  value: boolean | null;
  properties: DocumentSchemaField['properties'];
  error?: boolean;
  onChange: (value: boolean | null) => void;
}
export const DocumentEditorBool = (
  props: DocumentEditorBoolProps,
): JSX.Element => {
  const { name, value, properties, error, onChange } = props;

  const handleChange = () => {
    onChange(!value);
  };

  return (
    <InputPanel
      label={name}
      onClick={handleChange}
      sx={{ cursor: 'pointer' }}
      error={error}
    >
      <Stack direction="row" spacing={1} alignItems="center" sx={{ py: 1 }}>
        <Typography variant="body1">False</Typography>
        <Switch checked={value ?? false} />
        <Typography variant="body1">True</Typography>
      </Stack>
    </InputPanel>
  );
};

export interface DocumentEditorFieldProps {
  name: string;
  value: DocumentDataField;
  type: DocumentFieldType;
  properties: DocumentSchemaField['properties'];
  error?: boolean;
  onChange: (value: DocumentDataField) => void;
}

const DocumentEditorField = (props: DocumentEditorFieldProps): JSX.Element => {
  const { name, value, type, properties, error, onChange } = props;

  switch (type) {
    case 'string':
      return (
        <DocumentEditorString
          name={name}
          value={value as string}
          properties={properties}
          error={error}
          onChange={onChange}
        />
      );
    case 'float':
      return (
        <DocumentEditorFloat
          name={name}
          value={value as number}
          properties={properties}
          error={error}
          onChange={onChange}
        />
      );
    case 'int':
      return (
        <DocumentEditorInt
          name={name}
          value={value as number}
          properties={properties}
          error={error}
          onChange={onChange}
        />
      );
    case 'boolean':
      return (
        <DocumentEditorBool
          name={name}
          value={value as boolean}
          properties={properties}
          error={error}
          onChange={onChange}
        />
      );
    default:
      console.error(`Unknown field type [${type}]`);
  }

  return <></>;
};

export default DocumentEditorField;
