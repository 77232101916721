import React from 'react';
import { Box, Checkbox, Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import IconCheckbox, { CheckboxState } from './base/IconCheckbox';
import { toClassString } from '../utilities/functions';

export interface RequirementItem {
  name: string;
  satisfied?: boolean;
}

export interface RequirementGroup {
  name: string;
  childRequirements: Requirement[];
  satisfied?: boolean;
}

export type Requirement = RequirementItem | RequirementGroup;

function isRequirementGroup(item: Requirement): item is RequirementGroup {
  return Object.prototype.hasOwnProperty.call(item, 'childRequirements');
}

const useStyles = makeStyles({
  item: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
  },
  itemCheckbox: {
    marginRight: 8,
  },
  itemName: {},
  group: {
    display: 'flex',
    flexFlow: 'column',
  },
  groupChildren: {
    display: 'flex',
    flexFlow: 'column',
  },
  groupChildrenIndent: {
    paddingLeft: 15,
  },
});

const PasswordRequirementItem = (props: RequirementItem) => {
  const { name, satisfied } = props;
  const classes = useStyles();

  return (
    <Stack direction="row" alignItems="center">
      <IconCheckbox
        state={satisfied ?? 'indeterminate'}
        className={classes.itemCheckbox}
      />
      <Typography variant="body1">{name}</Typography>
    </Stack>
  );
};

interface PasswordRequirementGroupProps extends RequirementGroup {
  hideName?: boolean;
}

const PasswordRequirementGroup = (props: PasswordRequirementGroupProps) => {
  const { name, childRequirements, satisfied, hideName } = props;
  const classes = useStyles();

  return (
    <Box className={classes.group}>
      {!hideName && (
        <PasswordRequirementItem
          name={name}
          satisfied={satisfied
          }
        />
      )}

      <Box
        className={toClassString(
          classes.groupChildren,
          !hideName && classes.groupChildrenIndent,
        )}
      >
        {childRequirements.map((r) => {
          if (isRequirementGroup(r)) {
            return <PasswordRequirementGroup key={r.name} {...r} />;
          }
          return <PasswordRequirementItem key={r.name} {...r} />;
        })}
      </Box>
    </Box>
  );
};

export interface PasswordRequirementListProps {
  requirements: Requirement[];
}

const PasswordRequirementList = (
  props: PasswordRequirementListProps,
): JSX.Element => {
  const { requirements } = props;

  return (
    <Box>
      <Typography variant="h3">Passwords must:</Typography>
      <PasswordRequirementGroup
        name=""
        satisfied={false}
        childRequirements={requirements}
        hideName
      />
    </Box>
  );
};

export default PasswordRequirementList;
