import { useQuery } from '@tanstack/react-query';
import cmsService from '../../services/cmsService';
import { IProject } from '../../services/transportTypes/BaseTypes';
import { IDocumentSchema } from '../../services/transportTypes/NCms';

export default function (project?: IProject): [IDocumentSchema[], boolean] {
  const { data = [], isLoading } = useQuery({
    queryKey: [cmsService.listProjectSchemas.name, { slug: project?.slug }],
    queryFn: () => cmsService.listProjectSchemas(project!.slug),
    enabled: !!project,
  });

  return [data, isLoading];
}
