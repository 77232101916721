import React, { useMemo } from 'react';
import { startOfToday, subDays } from 'date-fns';
import LineChart, { LineChartProps } from '../charts/LineChart';
import DESIGN from '../../../constants/designConstants';
import { IEndUserLoginHistory } from '../../../services/transportTypes/BaseTypes';
import useFilterSelect from '../utilities/useFilterSelect';
import { defaultOptionsFilters } from '../../../constants/analyticsConstants';
import { aggregateEvents } from '../../../utilities/GraphUtils';

export interface LoginLineChartProps extends Pick<LineChartProps, 'isLoading'>{
  data: IEndUserLoginHistory[];
}

const defaultFilter = defaultOptionsFilters[0];
const LoginLineChart = (props: LoginLineChartProps): JSX.Element => {
  const { data, ...others } = props;

  const { selected, options, onChange } = useFilterSelect(
    defaultOptionsFilters,
    defaultFilter,
  );

  const aggregateData = useMemo(() => {
    const period: Interval = {
      start: subDays(startOfToday(), selected?.value ?? defaultFilter.value),
      end: startOfToday(),
    };
    return aggregateEvents(
      data.map((v) => ({
        ...v,
        date: new Date(v.created_at),
      })),
      period,
    );
  }, [data, selected]);

  return (
    <LineChart
      data={[
        {
          title: 'Total Logins',
          colour: DESIGN.GRAPH_DARK_BLUE,
          points: aggregateData.map((bucket) => ({
            x: bucket.date,
            y: bucket.items.length,
          })),
        },
        {
          title: 'Unique Users',
          colour: DESIGN.COLOR_GREEN,
          points: aggregateData.map((bucket) => ({
            x: bucket.date,
            y: bucket.items.reduce<number[]>((acc, item) => {
              if (!acc.includes(item.user_id)) {
                acc.push(item.user_id);
              }
              return acc;
            }, []).length,
          })),
        },
      ]}
      name="Logins"
      category="Total"
      currentFilter={selected}
      filterOptions={options}
      onFilterChange={onChange}
      {...others}
    />
  );
};

export default LoginLineChart;
