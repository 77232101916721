import React from 'react';
import AnalyticsDetails from './AnalyticsDetails';
import { ColumnData } from '../components/base/TableDefault';
import { formatDateFromString } from '../utilities/functions';
import useLoginData from '../utilities/hooks/useLoginData';
import useProject from '../utilities/hooks/useProject';
import getUniqueLogins, {
  UniqueEndUserLoginData,
} from '../utilities/getUniqueLogins';

const columnsUniqueLogins: ColumnData<UniqueEndUserLoginData>[] = [
  {
    id: 'email',
    type: 'string',
    label: 'Email',
  },
  {
    id: 'firstLogin',
    type: 'date',
    label: 'First Login',
    formatter: (dateStr: string) => formatDateFromString(dateStr),
  },
  {
    id: 'latestLogin',
    type: 'date',
    label: 'Latest Login',
    formatter: (dateStr: string) => formatDateFromString(dateStr),
  },
  {
    id: 'totalLogins',
    type: 'number',
    label: 'Total Logins',
  },
];

export interface AnalyticsLoginDetailsProps {

}

const AnalyticsLoginDetails = (props: AnalyticsLoginDetailsProps): JSX.Element => {
  const [project] = useProject();
  const [loginData] = useLoginData(project?.slug);
  const uniqueLoginData = getUniqueLogins(loginData?.login ?? []);

  console.log('AnalyticsLoginDetails', { loginData, uniqueLoginData });
  return (
    <AnalyticsDetails
      hint="Unique Logins"
      columns={columnsUniqueLogins}
      rows={uniqueLoginData ?? []}
    />
  );
};

export default AnalyticsLoginDetails;
