import { Dialog, DialogProps, Grid, TextField } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useEffect, useState } from 'react';
import ButtonDefault, { ButtonVariants } from './base/ButtonDefault';

export interface TwoFactorEnableOverlayProps
  extends Omit<
    DialogProps,
    'aria-labelledby' | 'aria-describedby' | 'onClose'
  > {
  qrUrl?: string;
  onCheck: (otp: string) => void;
  onClose: () => void;
}

const TwoFactorEnableOverlay = (
  props: TwoFactorEnableOverlayProps,
): JSX.Element => {
  const { open, qrUrl, onCheck, onClose, ...otherDialogProps } = props;

  const [otp, setOtp] = useState<string>();

  const handleCheck = () => {
    if (otp) {
      onCheck(otp);
    }
  };

  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
  };

  // Clear textbox on close
  useEffect(() => {
    if (!open && otp) {
      setOtp(undefined);
    }
  }, [open, otp]);

  return (
    <Dialog
      {...otherDialogProps}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        Two Factor Settings
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row">
          <Grid item xs={8}>
            <Typography variant="body1">
              To enable Two Factor Authorisation, scan the QR code with your
              authenticator app and then enter a code to confirm.
            </Typography>
            <TextField
              variant="outlined"
              label="Code"
              className="text-field"
              placeholder="123123"
              value={otp}
              onChange={handleCodeChange}
            />
          </Grid>
          <Grid item xs={4} alignItems="center" justifyContent="center">
            <img src={qrUrl} alt="Two Factor Authentication QR Code" />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <ButtonDefault
          onClick={handleCheck}
          variant={ButtonVariants.subtle}
          disabled={!otp}
          size="small"
        >
          Check
        </ButtonDefault>
        <ButtonDefault onClick={onClose} size="small">
          Cancel
        </ButtonDefault>
      </DialogActions>
    </Dialog>
  );
};

export default TwoFactorEnableOverlay;
