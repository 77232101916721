import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import rolesService from '../../services/rolesService';
import { IPermission } from '../../services/transportTypes/BaseTypes';
import { IRole } from '../../services/transportTypes/NRole';

export default function (
  role?: IRole,
): [IPermission[], boolean, (r: IRole) => Promise<void>] {
  const queryClient = useQueryClient();

  const { data, isLoading, isPaused } = useQuery({
    queryKey: [
      rolesService.getRolePermissions.name,
      { projectSlug: role?.project_id, roleId: role?.id },
    ],
    queryFn: () =>
      rolesService.getRolePermissions({
        projectSlug: role!.project_id,
        roleId: role!.id,
      }),
    enabled: !!role,
  });

  const permissions = useMemo(() => data ?? [], [data])

  const invalidate = useCallback(
    (r: IRole) =>
      queryClient.resetQueries([
        rolesService.getRolePermissions.name,
        { projectSlug: r.project_id, roleId: r.id },
      ]),
    [queryClient],
  );

  return [permissions, isLoading || isPaused, invalidate];
}
