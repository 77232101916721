// Component constants
const COMPONENT = {
  ANALYTICS_HISTORY_LOGINS: 'ANALYTICS_HISTORY_LOGINS',
  ANALYTICS_HISTORY_BROWSERS: 'ANALYTICS_HISTORY_BROWSERS',

  PROJECTS_AND_ROLES_LOGGED_IN_USER: 'PROJECTS_AND_ROLES_LOGGED_IN_USER',
  PROJECTS_AND_ROLES_USER: 'PROJECTS_AND_ROLES_USER',
  PROJECTS_AND_ROLES_NEW_USER: 'PROJECTS_AND_ROLES_NEW_USER',

  REVIEW_VIDEO: 'Video',
};

export default COMPONENT;
