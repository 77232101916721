import API from '../constants/apiConstants';
import addAuthHeader from '../utilities/auth';
import { ProjectSlugOrId } from '../utilities/frontendTypes';
import { hydrateTimestamps } from '../utilities/functions';
import handleResponse from '../utilities/handleResponse';
import { IDocument, IDocumentSchema, NCms } from './transportTypes/NCms';

const apiUrl = process.env.REACT_APP_API;

function hydrateSchema(schema: IDocumentSchema<string>): IDocumentSchema {
  return {
    ...hydrateTimestamps(schema),
    fields: schema.fields.map(hydrateTimestamps),
  };
}

function listProjectSchemas(
  projectId: ProjectSlugOrId,
): Promise<NCms.DocumentSchema.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${projectId}/cms/schema`,
    requestOptions,
  )
    .then<NCms.DocumentSchema.Get.Response<string>>(handleResponse)
    .then((schemas) => schemas.map(hydrateSchema));
}

function getSchema({
  projectId,
  schemaId,
}: {
  projectId: ProjectSlugOrId;
  schemaId: IDocumentSchema['id'];
}): Promise<NCms.DocumentSchema.SchemaId.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${projectId}/cms/schema/${schemaId}`,
    requestOptions,
  )
    .then<NCms.DocumentSchema.SchemaId.Get.Response<string>>(handleResponse)
    .then(hydrateSchema);
}

function addSchema({
  projectId,
  body,
}: {
  projectId: ProjectSlugOrId;
  body: NCms.DocumentSchema.Post.Request;
}): Promise<NCms.DocumentSchema.Post.Response> {
  const requestOptions = {
    method: 'POST',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${projectId}/cms/schema`,
    requestOptions,
  )
    .then<NCms.DocumentSchema.Post.Response<string>>(handleResponse)
    .then(hydrateSchema);
}

function updateSchema({
  projectId,
  schemaId,
  body,
}: {
  projectId: ProjectSlugOrId;
  schemaId: IDocumentSchema['id'];
  body: NCms.DocumentSchema.SchemaId.Put.Request;
}): Promise<NCms.DocumentSchema.SchemaId.Put.Response> {
  const requestOptions = {
    method: 'PUT',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${projectId}/cms/schema/${schemaId}`,
    requestOptions,
  )
    .then<NCms.DocumentSchema.SchemaId.Put.Response<string>>(handleResponse)
    .then(hydrateSchema);
}

function deleteSchema({
  projectId,
  schemaId,
}: {
  projectId: ProjectSlugOrId;
  schemaId: IDocumentSchema['id'];
}): Promise<NCms.DocumentSchema.SchemaId.Delete.Response> {
  const requestOptions = {
    method: 'DELETE',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${projectId}/cms/schema/${schemaId}`,
    requestOptions,
  ).then<NCms.DocumentSchema.SchemaId.Delete.Response>(handleResponse);
}

function listSchemaDocuments({
  projectId,
  schemaId,
}: {
  projectId: ProjectSlugOrId;
  schemaId: IDocumentSchema['id'];
}): Promise<NCms.DocumentSchema.SchemaId.Document.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${projectId}/cms/schema/${schemaId}/document`,
    requestOptions,
  )
    .then<NCms.DocumentSchema.SchemaId.Document.Get.Response<string>>(
      handleResponse,
    )
    .then((documents) => documents.map(hydrateTimestamps));
}

function getDocument({
  projectId,
  documentId,
}: {
  projectId: ProjectSlugOrId;
  documentId: IDocument['id'];
}): Promise<NCms.Document.DocumentId.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${projectId}/cms/document/${documentId}`,
    requestOptions,
  )
    .then<NCms.Document.DocumentId.Get.Response<string>>(handleResponse)
    .then(hydrateTimestamps);
}

function addDocument({
  projectId,
  body,
}: {
  projectId: ProjectSlugOrId;
  body: NCms.Document.Post.Request;
}): Promise<NCms.Document.Post.Response> {
  return fetch(`${apiUrl}${API.PROJECT}/${projectId}/cms/document`, {
    method: 'POST',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body),
  })
    .then<NCms.Document.Post.Response<string>>(handleResponse)
    .then(hydrateTimestamps);
}

function updateDocument({
  projectId,
  documentId,
  body,
}: {
  projectId: ProjectSlugOrId;
  documentId: IDocument['id'];
  body: NCms.Document.DocumentId.Put.Request;
}): Promise<NCms.Document.DocumentId.Put.Response> {
  return fetch(
    `${apiUrl}${API.PROJECT}/${projectId}/cms/document/${documentId}`,
    {
      method: 'PUT',
      headers: addAuthHeader({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(body),
    },
  )
    .then<NCms.Document.DocumentId.Put.Response<string>>(handleResponse)
    .then(hydrateTimestamps);
}

function deleteDocument({
  projectId,
  documentId,
}: {
  projectId: ProjectSlugOrId;
  documentId: IDocument['id'];
}): Promise<NCms.Document.DocumentId.Delete.Response> {
  return fetch(
    `${apiUrl}${API.PROJECT}/${projectId}/cms/document/${documentId}`,
    {
      method: 'DELETE',
      headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    },
  ).then<NCms.Document.DocumentId.Delete.Response>(handleResponse);
}

const cmsService = {
  listProjectSchemas,
  getSchema,
  addSchema,
  updateSchema,
  deleteSchema,
  listSchemaDocuments,
  getDocument,
  addDocument,
  updateDocument,
  deleteDocument,
};

export default cmsService;
