import { useQuery } from '@tanstack/react-query';
import analyticsService from '../../services/analyticsService';
import { ISessionRecord } from '../../services/transportTypes/NAnalytics';
import { EventNames } from '../../services/transportTypes/project/CoreAnalyticsEvents';

export default function (
  currentProjectSlug: string | undefined,
): [ISessionRecord[], boolean] {
  const {
    data: browserData,
    isLoading,
    isPaused,
  } = useQuery(['getBrowserData', { projectSlug: currentProjectSlug }], {
    queryFn: () =>
      analyticsService.getAnalyticsData<ISessionRecord>({
        projectSlug: currentProjectSlug!,
        params: {
          name: EventNames.Session,
        },
      }),
    enabled: !!currentProjectSlug,
  });

  return [browserData?.results ?? [], isLoading || isPaused];
}
