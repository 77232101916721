export const EventNames = {
  TimeSpent: 'TimeSpent',
  ContentInteraction: 'ContentInteraction',
  Session: 'Session',
  Feedback: 'Feedback',
} as const;

export interface IAnalyticsEvent<Name extends string, Data extends object> {
  user_id: string | null;
  session_id: string;
  name: Name;
  data: Data;
}

export interface ITimespentEventData {
  type: string;
  id: string;
  ms: number;
}
export type ITimespentEvent = IAnalyticsEvent<
  typeof EventNames.TimeSpent,
  ITimespentEventData
>;

export interface IContentInteractionEventData {
  id: string;
  source_id: string;
  type: string;
}
export type IContentInteractionEvent = IAnalyticsEvent<
  typeof EventNames.ContentInteraction,
  IContentInteractionEventData
>;

export interface ISessionEventData {
  name: string;
  version: string;
  agent: string;
}
export type ISessionEvent = IAnalyticsEvent<
  typeof EventNames.Session,
  ISessionEventData
>;

export interface IFeedbackEventData {
  name: string;
  contact: string;
  message: string;
}
export type IFeedbackEvent = IAnalyticsEvent<
  typeof EventNames.Feedback,
  IFeedbackEventData
>;

export type AnalyticsEvent =
  | ITimespentEvent
  | IContentInteractionEvent
  | ISessionEvent
  | IFeedbackEvent;
