import React from 'react';
import { ISessionRecord } from '../../../services/transportTypes/NAnalytics';
import ButtonDefault from '../../base/ButtonDefault';
import AnalyticsTable, {
  AnalyticsTableProps,
  AnalyticsTableRow,
} from '../charts/AnalyticsTable';
import groupEvents from '../utilities/groupEvents';

export interface BrowsersListAnalyticsProps
  extends Pick<AnalyticsTableProps, 'isLoading' | 'sx'> {
  data: ISessionRecord[];
  onClick: () => void;
}

const BrowserTable = (props: BrowsersListAnalyticsProps): JSX.Element => {
  const { data, onClick, ...others } = props;

  const aggregateData = groupEvents(
    (e) => ({
      id: e.data.name,
      name: e.data.name,
      data: 1,
      percentage: 0,
      events: [e],
    }),
    data,
  );

  const placeholder: AnalyticsTableRow[] = [
    {
      id: 1,
      name: 'Mozilla Firefox',
      data: 0,
      percentage: 0,
    },
    {
      id: 2,
      name: 'Google Chrome',
      data: 0,
      percentage: 0,
    },
    {
      id: 3,
      name: 'Safari',
      data: 0,
      percentage: 0,
    },
    {
      id: 4,
      name: 'Internet Explorer',
      data: 0,
      percentage: 0,
    },
  ];

  return (
    <AnalyticsTable
      name="Top 10"
      category="Browser"
      data={aggregateData.length === 0 ? placeholder : aggregateData}
      includePercentage
      headerChildren={
        <ButtonDefault variant="subtle" size="small" onClick={onClick}>
          ViewMore
        </ButtonDefault>
      }
      {...others}
    />
  );
};

export default BrowserTable;
