import { useQuery } from '@tanstack/react-query';
import analyticsService from '../../services/analyticsService';
import { NProject } from '../../services/transportTypes/NProject';
import {
  IEndUserLoginHistory,
  IIPData,
} from '../../services/transportTypes/BaseTypes';

export default function (currentProjectSlug: string | undefined): [
  {
    login: IEndUserLoginHistory[];
    location: IIPData[];
  },
  boolean,
] {
  const {
    data: loginData,
    isLoading,
    isPaused,
  } = useQuery<NProject.Project.History.Login.Get.Response>(
    ['getLoginData', { projectSlug: currentProjectSlug }],
    {
      queryFn: () => analyticsService.getLoginData(currentProjectSlug!),
      enabled: !!currentProjectSlug,
    },
  );

  return [loginData ?? { login: [], location: [] }, isLoading || isPaused];
}
