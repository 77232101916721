import Typography from '@mui/material/Typography';
import React from 'react';
import { IPermission } from '../../services/transportTypes/BaseTypes';
import { Testable } from '../../utilities/frontendTypes';
import IconCheckbox from '../base/IconCheckbox';

export interface RolePermissionTableProps {
  permissions?: IPermission[];
}

const anyTaken = (p: IPermission) => p.create || p.read || p.update || p.delete;
const allTaken = (p: IPermission) => p.create && p.read && p.update && p.delete;

const RolePermissionTable = (
  props: RolePermissionTableProps & Testable,
): JSX.Element => {
  const { permissions, 'data-testid': testId } = props;

  return (
    <div className="role-permission-table" data-testid={testId}>
      {permissions &&
        permissions.map((p) => (
          <div className="row" key={p.name}>
            <Typography
              variant="body2"
              data-testid={testId ? `${testId}-${p.name}-label` : undefined}
              sx={{whiteSpace: 'nowrap'}}
            >
              {p.label}
            </Typography>
            <IconCheckbox
              state={allTaken(p) || (anyTaken(p) ? 'indeterminate' : false)}
              data-testid={testId ? `${testId}-${p.name}-checkbox` : undefined}
            />
          </div>
        ))}
    </div>
  );
};

export default RolePermissionTable;
