import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { MouseEvent } from 'react';
import ButtonDefault from './ButtonDefault';

export interface AlertDialogProps {
  title: string;
  modalStatus: boolean;
  content: string;
  // eslint-disable-next-line no-unused-vars
  handleCancel: (event: MouseEvent<HTMLButtonElement>) => void;
  // eslint-disable-next-line no-unused-vars
  handleConfirm: (event: MouseEvent<HTMLButtonElement>) => void;
}

export default function AlertModal(props: AlertDialogProps) {
  const { title, content, handleConfirm, handleCancel, modalStatus } = props;

  return (
    <Dialog
      open={modalStatus}
      keepMounted
      onClose={handleCancel}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <ButtonDefault onClick={handleCancel} className="btn-cancel">
          Cancel
        </ButtonDefault>
        <ButtonDefault onClick={handleConfirm} className="btn-confirm">
          Confirm
        </ButtonDefault>
      </DialogActions>
    </Dialog>
  );
}
