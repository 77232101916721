import { Stack } from '@mui/material';
import { differenceInDays, startOfToday } from 'date-fns';
import React from 'react';
import {
  defaultOptionsFilters,
  IOptionsFilter,
} from '../../../constants/analyticsConstants';
import { ITimespentRecord } from '../../../services/transportTypes/NAnalytics';
import { camelCaseToSpaceSeparated } from '../../../utilities/functions';
import PieChart, { PieChartProps } from '../charts/PieChart';
import FilterSelect from '../FilterSelect';
import groupEvents from '../utilities/groupEvents';
import useFilterSelect from '../utilities/useFilterSelect';

const defaultDateFilter = defaultOptionsFilters[0];
const noTypeFilter: IOptionsFilter = {
  id: '', // 'allTypes',
  display: 'All Types',
  value: 0,
};

export interface TimespentPieChartProps
  extends Pick<PieChartProps, 'isLoading'> {
  data: ITimespentRecord[];
}

const TimespentPieChart = (props: TimespentPieChartProps): JSX.Element => {
  const { data, ...others } = props;

  const allTypes = data
    .map((r) => r.data.type)
    .reduce<string[]>(
      (acc, type) => (!acc.includes(type) ? [...acc, type] : acc),
      [],
    );

  const typeFilter = useFilterSelect(
    [
      noTypeFilter,
      ...allTypes.map((t, i) => ({
        id: t,
        value: i + 1,
        display: camelCaseToSpaceSeparated(t),
      })),
    ],
    noTypeFilter,
  );
  const dateFilter = useFilterSelect(defaultOptionsFilters, defaultDateFilter);

  const currentFilterDays =
    dateFilter.selected?.value ?? defaultDateFilter.value;

  const filteredTimeSpentData = data
    .filter(
      // Filter dates
      (e) =>
        differenceInDays(startOfToday(), e.created_at) <= currentFilterDays,
    )
    .filter(
      // Filter types
      (e) =>
        !typeFilter.selected ||
        typeFilter.selected.value === noTypeFilter.value ||
        e.data.type === typeFilter.selected.id,
    );

  const timeSpentGroups = groupEvents(
    (e) => ({
      id: e.data.id,
      name: camelCaseToSpaceSeparated(e.data.id),
      data: e.data.ms,
      percentage: 0,
      events: [e],
    }),
    filteredTimeSpentData,
  );

  return (
    <PieChart
      name="Time Spent"
      category="Total"
      sectorData={timeSpentGroups.map((group) => ({
        id: group.id,
        label: group.name,
        value: group.data,
      }))}
      {...others}
    >
      <Stack direction="row" spacing={1}>
        <FilterSelect
          sx={{
            minWidth: 120,
          }}
          selected={typeFilter.selected}
          options={typeFilter.options}
          onChange={typeFilter.onChange}
        />
        <FilterSelect
          sx={{
            minWidth: 120,
          }}
          selected={dateFilter.selected}
          options={dateFilter.options}
          onChange={dateFilter.onChange}
        />
      </Stack>
    </PieChart>
  );
};

export default TimespentPieChart;
