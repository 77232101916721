export default {
  AUTH: 'auth',
  TWO_FACTOR: '2fa',
  RESET_PASSWORD: 'reset-password',
  FORGOT_PASSWORD: 'forgot-password',
  UNIQUE_LOGINS: 'unique-logins',

  HOME: 'home',
  LOGIN: 'login',
  USERS: 'users',
  PROJECTS: 'projects',

  MANAGEMENT: 'management',
  CREATE: 'create',
  ROLES: 'roles',
  ANALYTICS: 'analytics',
  REVIEW: 'review',
  DETAILS: ':details',
  DEFINE: 'define',
  NEW: 'new',

  MY_ACCOUNT: 'my-account',
  LOGIN_HISTORY: 'login-history',
  ACTIVITY_LOG: 'activity-log',
  BROWSER_TYPES: 'browser-types',
  PDFS_DOWNLOADED: 'pdfs-downloaded',
  DATA_CAPTURE: 'data-capture',
  FORM_DATA: 'form-data',
  CONTACT_FORM: 'contact-form',

  DOC_SCHEMAS: 'schemas',
  DOCUMENTS: 'documents',

  ID: ':id',
  SLUG: ':slug',
} as const;
