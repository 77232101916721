import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import projectService from '../../services/projectService';
import { IProject } from '../../services/transportTypes/BaseTypes';
import { IProjectSummary } from '../../services/transportTypes/NProject';

export default (): [
  IProjectSummary | undefined,
  boolean,
  (p: IProject) => Promise<void>,
] => {
  const queryClient = useQueryClient();

  const { slug } = useParams<{ slug: string }>();
  const [searchParams] = useSearchParams();
  const queryParam = searchParams.get('slug');
  const param = slug || queryParam;

  const { data, isLoading, isPaused } = useQuery({
    queryKey: [projectService.getProject.name, { slug: param }],
    queryFn: () => projectService.getProject(param!),
    enabled: !!param,
  });

  const invalidate = useCallback(
    (p: IProject) =>
      queryClient.resetQueries([
        projectService.getProject.name,
        { slug: p.slug },
      ]),
    [queryClient],
  );

  return [data, isLoading || isPaused, invalidate];
};
