import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
import ListIcon from '@mui/icons-material/List';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Grid, Skeleton, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Colours } from '../../../constants/designConstants';
import { ActivityArea, formatDate } from '../../../utilities/functions';
import { ActivityLogRecord } from '../../../utilities/hooks/useProjectActivityHistory';
import ButtonDefault from '../ButtonDefault';
import HeaderComponent from '../HeaderComponent';

function getAreaIcon(area: ActivityArea) {
  switch (area) {
    case 'project':
    default:
      return <BarChartIcon />;
    case 'role':
      return <ListIcon />;
    case 'user':
      return <GroupIcon />;
  }
}

export interface ActivityLogProps {
  records: ActivityLogRecord[];
  maxItems?: number;
  isLoading?: boolean;
  onClick: (record: ActivityLogRecord) => void;
  onViewMore: () => void;
}

const ActivityLog = (props: ActivityLogProps): JSX.Element => {
  const { records, maxItems = 5, isLoading, onClick, onViewMore } = props;

  return (
    <div>
      <HeaderComponent title="Activity Log" />

      <Stack spacing={1}>
        {records.length === 0 && !isLoading && (
          <Typography variant="body1" textOverflow="ellipsis">
            Data not available.
          </Typography>
        )}

        {isLoading && (
          <>
            <Skeleton variant="rounded" height={70} />
            <Skeleton variant="rounded" height={70} />
            <Skeleton variant="rounded" height={70} />
            <Skeleton variant="rounded" height={70} />
            <Skeleton variant="rounded" height={70} />
          </>
        )}

        {!isLoading &&
          records.slice(0, maxItems).map((record) => (
            <ButtonDefault
              key={record.id}
              variant="subtle"
              size="small"
              onClick={() => onClick(record)}
            >
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <Stack direction="row" spacing={1}>
                    {getAreaIcon(record.area)}
                    <Typography
                      variant="body1"
                      textAlign="start"
                      textTransform="none"
                    >
                      {record.userName}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={0.5}>
                    <Typography
                      variant="body2"
                      textAlign="start"
                      textTransform="none"
                      textOverflow="ellipsis"
                    >
                      {record.detail}
                    </Typography>
                    <Typography
                      variant="body2"
                      textAlign="start"
                      textTransform="none"
                      textOverflow="ellipsis"
                    >
                      {formatDate(record.created_at)}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </ButtonDefault>
          ))}
        <ButtonDefault
          variant="subtle"
          size="small"
          type="button"
          onClick={onViewMore}
          disabled={isLoading}
          sx={{ alignSelf: 'flex-end' }}
        >
          <MoreHorizIcon sx={{ color: Colours.Black.Text, mr: 1 }} />
          <Typography variant="body1">View More</Typography>
        </ButtonDefault>
      </Stack>
    </div>
  );
};

export default ActivityLog;
