/**
 * This file is for types that are used in the interface between frontend and backend. It should
 * only contain Request and Response types.
 */

export type KeysOf<T> = T[keyof T];

export interface BaseErrorResponse {
  success: false;
  message: string;
  token?: string | null;
}

export interface FieldErrorResponse extends BaseErrorResponse {
  missing_fields: (string | number | symbol)[];
}

export interface ObjectionErrorResponse extends BaseErrorResponse {
  type?: string;
  data?: Record<string, unknown>;
}

export type ErrorResponse =
  | BaseErrorResponse
  | FieldErrorResponse
  | ObjectionErrorResponse;

export type ResponseBody<T> = T | ErrorResponse;

export interface IPermission {
  project_id?: number;
  role_id?: number;
  service_id: number;
  name: PermissionName;
  label: string;
  help: string;
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
}

export interface IPermissionDefinition {
  name: PermissionName;
  label?: string;
  help?: string;
  service_id: number;
}

export interface IProject {
  id: number;
  client: string;
  name: string;
  slug: string;
  deleted: boolean;
  logo_url: string | null;
  special_permissions: boolean;
}

export interface IIPData<DateType extends Date | string = Date> {
  id: number;
  ip_address: string;
  country_code: string;
  country_name: string;
  region_code: string;
  region_name: string;
  city: string;
  created_at: DateType;
}

export interface IEndUser {
  id: number;
  project_id: number;
  user_id: number;
  user_identifier: string;
}

export interface IEndUserLoginHistory<DateType extends Date | string = Date> {
  id: number;
  user_id: number;
  email: string;
  full_ua: string;
  name: string;
  version: string;
  fullname: string;
  os: string;
  device_type: string;
  remote_address: string;
  created_at: DateType;
  updated_at: DateType;
}

export interface IActivityLogItem {
  resource: string;
  value: string | null;
}

export interface IActivityLog<DateType extends Date | string = Date> {
  id: number;
  user_id: number | null; // Null indicates user has been deleted
  detail: string;
  items: IActivityLogItem[];
  created_at: DateType;
}

export const PermissionNames = {
  AdminUser: 'admin-user',
  Analytics: 'analytics',
  AdminAnalytics: 'admin-analytics',
  CMS: 'cms',
  AdminCMS: 'admin-cms',
  Project: 'project',
  AdminProject: 'admin-project',
  ProjectUser: 'project-user',
  AdminProjectUser: 'admin-project-user',
  Role: 'role',
  AdminRole: 'admin-role',
  RolePermission: 'role-permission',
  AdminRolePermission: 'admin-role-permission',
  RoleUser: 'role-user',
  AdminRoleUser: 'admin-role-user',
  Service: 'service',
  AdminService: 'admin-service',
  DataCapture: 'data-capture',
  AdminDataCapture: 'admin-data-capture',
  EmailIntegration: 'email-integration',
  AdminEmailIntegration: 'admin-email-integration',
  ContactForm: 'contact-form',
  AdminContactForm: 'admin-contact-form',
  Zoom: 'zoom',
  AdminZoom: 'admin-zoom',
  Unknown: 'unknown',
} as const;
export const AllPermissionNames = Object.values(PermissionNames);

export type PermissionName = KeysOf<typeof PermissionNames>;

export const PermissionActions = {
  Create: 'create',
  Read: 'read',
  Update: 'update',
  Delete: 'delete',
} as const;
export const AllPermissionActions = Object.values(PermissionActions);

export type PermissionAction = KeysOf<typeof PermissionActions>;
