import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import PageHeader from '../components/base/PageHeader';
import TableDefault, { ColumnData } from '../components/base/TableDefault';
import { applyFilter, Filter } from '../components/base/TableDefault/Filter';
import AuthorizedPage from '../containers/AuthorizedPage';
import projectService from '../services/projectService';
import { ServiceNames } from '../services/transportTypes/NService';
import { IForm } from '../utilities/backendTypes';
import { Key } from '../utilities/frontendTypes';
import { formatDateFromString } from '../utilities/functions';
import useProject from '../utilities/hooks/useProject';

const FormData = (): JSX.Element => {
  const [currentProject] = useProject();
  const [downloadArray, setDownloadArray] = useState<any>([]);
  const [filters, setFilters] = useState<Filter<IForm>[]>([]);

  const service = currentProject?.services?.find(
    (item) => item.name === ServiceNames.EmailIntegration,
  );

  const { data: all = [], isLoading } = useQuery({
    queryFn: () =>
      projectService
        .getEmailIntegrationData({
          projectSlug: currentProject!.slug,
          serviceId: service!.id,
        })
        .then((data) => data.results),
    queryKey: [
      projectService.getEmailIntegrationData.name,
      currentProject?.slug,
    ],
    enabled: !!currentProject && !!service,
  });

  const columns: ColumnData<IForm>[] = [
    {
      id: 'name',
      type: 'string',
      disablePadding: false,
      label: 'Form Name',
    },
    {
      id: 'fieldsKeys',
      type: 'string',
      disablePadding: false,
      label: 'Fields',
    },
    {
      id: 'fieldsData',
      type: 'string',
      disablePadding: false,
      label: 'Data',
    },
    {
      id: 'created_at',
      type: 'date',
      disablePadding: false,
      label: 'Date / Time',
      formatter: (dateStr: string) => formatDateFromString(dateStr),
    },
  ];

  const createArrayOfEntries = (indexes: any) => {
    const toDownload: IForm[] = [];

    indexes.forEach((key: number) => {
      const item = all.find((r) => r.id === key);
      if (item) {
        toDownload.push(item);
      }
    });
    const arr = toDownload.map((item) =>
      Object.fromEntries(Object.entries(item.fields)),
    );
    setDownloadArray(arr);
  };

  const handleFilters = (f: Filter<IForm>[]) => {
    setFilters(f);
    // if (filtersData.length > 0) {
    //   let filtered: any = [];
    //   filtersData.forEach((filter) => {
    //     const FIELD = filter.field ? filter.field : 'created_at';
    //     const currentFilter = all.filter((item: any) => {
    //       // if the filter is with date, we need to format them before comparing
    //       const date1 = moment(item[FIELD]).format('YYYY-MM-DD');
    //       const date2 = moment(filter.value).format('YYYY-MM-DD');
    //       return handleOperator(
    //         filter.operator,
    //         filter.field === 'created_at' ? date1 : item[FIELD],
    //         filter.field === 'created_at' ? date2 : filter.value,
    //       );
    //     });
    //     if (currentFilter.length > 0) filtered = filtered.concat(currentFilter);
    //   });
    //   setData(filtered);
    // } else {
    //   setData(all);
    // }
  };

  const handleRowSelect = (indexes: Key[]) => {
    createArrayOfEntries(indexes);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const indexes = all.map((n) => n.id);
      createArrayOfEntries(indexes);
    }
  };

  const filteredData = all.filter((d) =>
    filters.every((f) => applyFilter(d, f)),
  );

  return (
    <AuthorizedPage>
      <PageHeader
        title="Form's Data"
        hintMessage="View and Download Data captured via forms."
      />
      <Grid container justifyContent="space-between" className="review">
        <Grid container item className="table" xs={12}>
          <Grid container item className="dashboard-body project-management">
            {all && (
              <TableDefault<IForm>
                columns={columns}
                rows={filteredData}
                isLoading={isLoading}
                filters={filters}
                toDownload={downloadArray}
                hideAddButton
                hideDeleteButton
                hideDownloadButton={false}
                onRowSelect={handleRowSelect}
                onSelectAllClick={handleSelectAllClick}
                onFiltersUpdate={handleFilters}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </AuthorizedPage>
  );
};

export default FormData;
