import { Skeleton, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Colours } from '../../constants/designConstants';
import { IProject } from '../../services/transportTypes/BaseTypes';
import Slider, { SliderItem } from '../base/Slider';
import ToggleButtonDefault from '../base/ToggleButtonDefault';

interface ProjectSliderProps {
  projects: IProject[];
  selected: string | null;
  onChange: (id: IProject | null) => void;
  isLoading?: boolean;
}

function ProjectSlider(props: ProjectSliderProps): JSX.Element {
  const { projects, selected, onChange, isLoading } = props;

  return (
    <Slider>
      {isLoading
        ? ['a', 'b', 'c', 'd'].map((char) => (
            <SliderItem key={char} itemId={char}>
              <Skeleton
                variant="rectangular"
                width={131}
                height={68}
                sx={{
                  borderRadius: 1,
                }}
              />
            </SliderItem>
          ))
        : projects.map((project) => (
            <SliderItem key={project.slug} itemId={project.slug.toString()}>
              <ToggleButtonDefault
                value={project.slug}
                selected={project.slug === selected}
                onClick={() => onChange(project)}
              >
                <Stack>
                  <Typography
                    variant="body2"
                    sx={{
                      color:
                        project.slug === selected
                          ? Colours.White.Pure
                          : undefined,
                      whiteSpace: 'nowrap',
                      textTransform: 'none',
                    }}
                  >
                    Project Summary
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color:
                        project.slug === selected
                          ? Colours.White.Pure
                          : undefined,
                      whiteSpace: 'nowrap',
                      textTransform: 'none',
                    }}
                  >
                    {project.name}
                  </Typography>
                </Stack>
              </ToggleButtonDefault>
            </SliderItem>
          ))}
    </Slider>
  );
}

export default ProjectSlider;
