import { IContentInteractionRecord } from '../../services/transportTypes/NAnalytics';

export interface PDFDownloadsTotals {
  id: number;
  name: string;
  totalValue: number;
  events: IContentInteractionRecord[];
}

export default function (
  pdfData: IContentInteractionRecord[],
): PDFDownloadsTotals[] {
  // Group data for browser display
  const groupedData = pdfData.reduce<Record<string, PDFDownloadsTotals>>(
    (acc, event, index) => {
      let group = acc[event.data.id];

      if (!group) {
        group = {
          id: index,
          name: event.data.id,
          totalValue: 0,
          events: [],
        };
        acc[event.data.id] = group;
      }

      // calculate total value for each browser
      group.totalValue += 1;
      group.events.push(event);

      return acc;
    },
    {},
  );

  return Object.values(groupedData);
}
