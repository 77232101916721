/* eslint-disable camelcase */
import addAuthHeader from '../utilities/auth';
import handleResponse from '../utilities/handleResponse';
import API from '../constants/apiConstants';
import { IContactForm } from '../utilities/backendTypes';

const apiUrl = process.env.REACT_APP_API;

function getContactForms(projectSlug: string): Promise<IContactForm[]> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(`${apiUrl}${API.PROJECT}/${projectSlug}/contact-service`, requestOptions).then<
    IContactForm[]
  >(handleResponse);
}
interface addContactFormArgs {
  project_slug: string;
  field_data: {
    name: string;
    email_address: string;
    subject: string;
  };
}

function addContactForm(contactForm: addContactFormArgs): Promise<IContactForm> {
  const requestOptions = {
    method: 'POST',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(contactForm),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${contactForm.project_slug}/contact-service`,
    requestOptions,
  ).then<IContactForm>(handleResponse);
}

interface updateContactFormArgs {
  id: number;
  project_slug: string;
  field_data: {
    name: string;
    email_address: string;
    subject: string;
  };
}

function updateContactForm(contactForm: updateContactFormArgs): Promise<IContactForm> {
  const requestOptions = {
    method: 'PUT',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(contactForm),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${contactForm.project_slug}/contact-service/${contactForm.id}`,
    requestOptions,
  ).then<IContactForm>(handleResponse);
}

interface deleteContactFormArgs {
  projectSlug: string;
  id: number;
}

function deleteContactForm(contactForm: deleteContactFormArgs) {
  const requestOptions = {
    method: 'DELETE',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${contactForm.projectSlug}/contact-service/${contactForm.id}`,
    requestOptions,
  ).then<deleteContactFormArgs>(handleResponse);
}

const contactFormService = {
  getContactForms,
  addContactForm,
  updateContactForm,
  deleteContactForm,
};
export default contactFormService;
