import React from 'react';
import { ColumnData } from '../components/base/TableDefault';
import { ISessionRecord } from '../services/transportTypes/NAnalytics';
import { ISessionEventData } from '../services/transportTypes/project/CoreAnalyticsEvents';
import { formatDateFromString } from '../utilities/functions';
import useBrowserData from '../utilities/hooks/useBrowserData';
import useProject from '../utilities/hooks/useProject';
import AnalyticsDetails from './AnalyticsDetails';

type FlatBrowserEvent = Omit<ISessionRecord, 'data'> &
  Omit<ISessionEventData, 'name'> & { browserName: string };

const columnsBrowserData: ColumnData<FlatBrowserEvent>[] = [
  {
    id: 'id',
    type: 'number',
    disablePadding: false,
    label: 'Event Id',
  },
  {
    id: 'user_id',
    type: 'string',
    disablePadding: false,
    label: 'User Identifier',
  },
  {
    id: 'browserName',
    type: 'string',
    disablePadding: false,
    label: 'Browser',
  },
  {
    id: 'created_at',
    type: 'date',
    disablePadding: false,
    label: 'Event Date',
    formatter: (dateStr: string) => formatDateFromString(dateStr),
  },
];

const AnalyticsBrowserDetails = (): JSX.Element => {
  const [project] = useProject();
  const [browserListData] = useBrowserData(project?.slug);

  return (
    <AnalyticsDetails
      hint="Browser Details"
      columns={columnsBrowserData}
      rows={browserListData.map(({ data: { name, ...data }, ...record }) => ({
        ...record,
        ...data,
        browserName: name,
      }))}
    />
  );
};

export default AnalyticsBrowserDetails;
