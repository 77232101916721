import { Delete, Edit, People } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertModal from '../components/base/AlertModal';
import PageHeader from '../components/base/PageHeader';
import TableDefault, { ColumnData } from '../components/base/TableDefault';
import { Filter } from '../components/base/TableDefault/Filter';
import ManageUsersOverlay from '../components/roles/ManageUsersOverlay';
import RolePermissionTable from '../components/roles/RolePermissionTable';
import PATHS from '../constants/pathGenerators';
import AuthorizedPage from '../containers/AuthorizedPage';
import projectService from '../services/projectService';
import rolesService from '../services/rolesService';
import {
  IPermission,
  PermissionActions,
  PermissionNames,
} from '../services/transportTypes/BaseTypes';
import { IAdminUserSummary } from '../services/transportTypes/NAdminUser';
import { IRole } from '../services/transportTypes/NRole';
import userService from '../services/userService';
import { IRoleDetails } from '../utilities/frontendTypes';
import { arrayDiff } from '../utilities/functions';
import useAlert from '../utilities/hooks/useAlert';
import useProject from '../utilities/hooks/useProject';
import useProjectUsers from '../utilities/hooks/useProjectUsers';
import useRoleListPermissions from '../utilities/hooks/useRoleListPermissions';
import useRoleListUsers from '../utilities/hooks/useRoleListUsers';
import useSelfPermissionsList from '../utilities/hooks/useSelfPermissionsList';
import useUrlFilters from '../utilities/hooks/useUrlFilters';
import { isPermitted } from '../utilities/permissionChecks';

interface TableRow extends IRole {
  permissions: IPermission[];
  permissionsLoading: boolean;
  users: IAdminUserSummary[];
  usersStr: string;
  usersLoading: boolean;
}

const columns: ColumnData<TableRow>[] = [
  {
    id: 'id',
    type: 'number',
    label: 'Role Id',
  },
  {
    id: 'role_name',
    type: 'string',
    label: 'Role Name',
  },
  {
    id: 'usersStr',
    type: 'string',
    label: 'Users',
    isLoading: (item) => item.usersLoading,
  },
  {
    id: 'permissions',
    type: 'string',
    label: 'Permissions',
    formatter: (p: IPermission[]) => <RolePermissionTable permissions={p} />,
    isLoading: (item) => item.permissionsLoading,
  },
];

const Roles = (): JSX.Element => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const alert = useAlert();

  const [project, projectLoading] = useProject();
  const [projectUsers] = useProjectUsers(project);
  const [rolePermissions] = useRoleListPermissions(project?.roles);
  const [roleUsers] = useRoleListUsers(project?.roles);
  const [selfPermissions] = useSelfPermissionsList();

  const [currentRow, setCurrentRow] = useState<TableRow>();
  const [toDelete, setToDelete] = useState<TableRow[]>([]);
  const [filters, setFilters, applyFilters] = useUrlFilters<TableRow>();

  const aUser = isPermitted({
    project,
    permissions: selfPermissions,
    adminPermission: PermissionNames.AdminUser,
    action: PermissionActions.Read,
  });

  // Combine roles, permissions and users into a single object for the table
  const roles = project?.roles ?? [];

  let rows = roles.map<TableRow>((r) => {
    const permissions = rolePermissions.find((p) => r.id === p.roleId);
    const users = roleUsers.find((u) => r.id === u.roleId);

    return {
      ...r,
      permissions: permissions?.permissions ?? [],
      permissionsLoading: permissions?.isLoading ?? true,
      users: users?.users ?? [],
      usersStr: (users?.users ?? []).map((u) => u.name).join(', '),
      usersLoading: users?.isLoading ?? true,
    };
  });

  rows = applyFilters(rows);

  const deleteRoleMutation = useMutation({
    mutationFn: rolesService.deleteRole,
    mutationKey: [rolesService.deleteRole.name],
    onSuccess: (response, params) => {
      alert({
        message: `${params.role_name} deleted`,
        severity: 'success',
      });
      Promise.all([
        queryClient.resetQueries([
          projectService.getProject.name,
          { slug: project?.slug },
        ]),
        queryClient.resetQueries([projectService.listProjects.name]),
      ]).catch((err) => console.error(err));
    },
    onError: (err: Error) => {
      console.error(err);
      alert({
        message: err.message,
        severity: 'error',
      });
    },
  });

  const addUsersToRoleMutation = useMutation({
    mutationFn: userService.addUsersToRole,
    mutationKey: [userService.addUsersToRole.name],
    onSuccess: (response, params) => {
      alert({
        message: `Role users updated`,
        severity: 'success',
      });
      Promise.all([
        queryClient.resetQueries([
          userService.getRoleUsers.name,
          { project_id: params.projectId, roleId: params.roleId },
        ]),
        queryClient.resetQueries([projectService.listProjects.name]),
      ]).catch((err) => console.error(err));
    },
    onError: (err: Error) => {
      console.error(err);
      alert({
        message: err.message,
        severity: 'error',
      });
    },
  });

  const removeUsersFromRoleMutation = useMutation({
    mutationFn: userService.removeUsersFromRole,
    mutationKey: [userService.removeUsersFromRole.name],
    onSuccess: () => {
      alert({
        message: `Role users updated`,
        severity: 'success',
      });
      Promise.all([
        queryClient.resetQueries([
          userService.getRoleUsers.name,
          { slug: project?.slug },
        ]),
        queryClient.resetQueries([projectService.listProjects.name]),
      ]).catch((err) => console.error(err));
    },
    onError: (err: Error) => {
      console.error(err);
      alert({
        message: err.message,
        severity: 'error',
      });
    },
  });

  const handleEdit = (item: IRoleDetails) => {
    navigate(PATHS.ROLES_DEFINE(project?.slug, item.id.toString()));
  };

  const performDelete = () => {
    toDelete.forEach((role) => deleteRoleMutation.mutate(role));
    setToDelete([]);
  };

  const handleDelete = (item: TableRow | TableRow[]) => {
    if (Array.isArray(item)) {
      setToDelete(item);
    } else {
      setToDelete([item]);
    }
  };

  const handleOpenManageUsers = (item: TableRow) => {
    setCurrentRow(item);
  };

  const handleCloseManageUsers = () => {
    setCurrentRow(undefined);
  };

  const handleSaveUsers = (toSave: IAdminUserSummary[]) => {
    if (currentRow) {
      const current = currentRow.users;
      const { added, removed } = arrayDiff(current, toSave);

      console.log({ added, removed });

      if (added.length) {
        addUsersToRoleMutation.mutate({
          projectId: currentRow.project_id,
          roleId: currentRow.id,
          body: added.map((u) => u.id),
        });
      }
      if (removed.length) {
        removeUsersFromRoleMutation.mutate({
          projectId: currentRow.project_id,
          roleId: currentRow.id,
          body: removed.map((u) => u.id),
        });
      }
    }
  };

  const handleAddRole = () => {
    navigate(PATHS.ROLES_DEFINE(project?.slug), {
      state: {
        createRole: true,
      },
    });
  };

  const handleFilterChange = (filter: Filter<TableRow>[]) => {
    setFilters(filter);
  };

  const tableActions = [
    {
      icon: Edit,
      action: handleEdit,
      tooltip: 'Edit',
      key: 'edit',
    },
    {
      icon: Delete,
      action: handleDelete,
      tooltip: 'Delete',
      key: 'delete',
    },
    {
      icon: People,
      action: handleOpenManageUsers,
      tooltip: 'Manage Users',
      key: 'manage',
    },
  ];

  return (
    <AuthorizedPage>
      <PageHeader
        title="Project - Roles"
        hintMessage="Add and edit roles."
        displayGoBack
      />
      <Grid className="dashboard-body project-management">
        <Grid item>
          <TableDefault
            columns={columns}
            rows={rows ?? []}
            filters={filters}
            actions={aUser ? tableActions : []}
            onMassDelete={handleDelete}
            onAddNew={handleAddRole}
            hideAddButton={!aUser}
            hideDeleteButton={!aUser}
            onFiltersUpdate={handleFilterChange}
            isLoading={projectLoading}
          />
        </Grid>
      </Grid>
      {toDelete.length > 0 && (
        <AlertModal
          title="Delete Confirmation"
          modalStatus={!!toDelete.length}
          content={`Are you sure you want to delete ${
            toDelete.length === 1
              ? toDelete[0].role_name
              : `${toDelete.length} roles`
          }?`}
          handleCancel={() => setToDelete([])}
          handleConfirm={performDelete}
        />
      )}
      <ManageUsersOverlay
        open={!!currentRow}
        roleName={currentRow?.role_name ?? ''}
        allUsers={projectUsers ?? []}
        includedUsers={currentRow?.users ?? []}
        onSave={handleSaveUsers}
        onClose={handleCloseManageUsers}
      />
    </AuthorizedPage>
  );
};

export default Roles;
