import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { Replay } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import {
  createRoutesFromChildren,
  matchRoutes,
  useNavigationType,
} from 'react-router';
import { useLocation } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from './containers/App';
import DashboardTheme from './DashboardTheme';
import reportWebVitals from './reportWebVitals';
import './sass/app.scss';
import store from './store/store';
import AlertContextProvider from './utilities/contexts/AlertContextProvider';

if (process.env.REACT_APP_SENTRY_DSN) {
  const replaySampleRate = process.env.REACT_APP_SENTRY_REPLAY_RATE
    ? Number.parseFloat(process.env.REACT_APP_SENTRY_REPLAY_RATE)
    : 1;

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    replaysSessionSampleRate: replaySampleRate,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Replay(),
    ],
    tracesSampleRate: 1.0,
  });
}

// React GA initialisation
ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID ?? '');

// React Query initialisation
const queryClient = new QueryClient();

// Redux Persist initialisation
const persistor = persistStore(store, null, () => {
  store.getState();
});

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={DashboardTheme}>
              <AlertContextProvider>
                <App />
              </AlertContextProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </PersistGate>
      </Provider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
