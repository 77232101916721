import { ToggleButton, ToggleButtonProps } from '@mui/material';
import React from 'react';
import { Colours } from '../../constants/designConstants';
import { combineSxProp } from '../../utilities/functions';

export interface ToggleButtonDefaultProps extends ToggleButtonProps {}

const ToggleButtonDefault = (props: ToggleButtonDefaultProps): JSX.Element => {
  const { sx, children, ...others } = props;

  return (
    <ToggleButton
      sx={combineSxProp(
        {
          fontSize: 16,
          textTransform: "none",
          color: Colours.Black.Text,
          backgroundColor: Colours.White.Pure,
          '&.Mui-selected': {
            color: Colours.White.Pure,
            backgroundColor: Colours.Red.Primary,
            '&:hover': {
              backgroundColor: Colours.Red.Darkened,
            },
          },
        },
        sx,
      )}
      {...others}
    >
      {children}
    </ToggleButton>
  );
};

export default ToggleButtonDefault;
