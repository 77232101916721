import { IPermission, KeysOf } from './BaseTypes';

export interface IService {
  id: number;
  name: string;
  label: string;
}

export const ServiceNames = {
  Core: 'core',
  PlatformAdmin: 'platform-admin',
  Analytics: 'analytics',
  CMS: 'cms',
  DataCapture: 'data-capture',
  EmailIntegration: 'email-integration',
  ContactForm: 'contact-form',
  Zoom: 'zoom',
} as const;

export type ServiceName = KeysOf<typeof ServiceNames>;

export namespace NService {
  export namespace Get {
    export type Request = null;
    export type Response = IService[];
    export type Params = null;
  }

  export namespace ServiceId {
    export namespace Get {
      export type Request = null;
      export type Response = IService;
      export type Params = null;
    }

    export namespace Permission {
      export namespace Get {
        export type Request = null;
        export type Response = IPermission[];
        export type Params = null;
      }
    }
  }
}
