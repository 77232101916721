import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Paper, Skeleton, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { ReactNode } from 'react';
import { Colours } from '../../constants/designConstants';
import { IRole } from '../../services/transportTypes/NRole';
import { IRoleDetails } from '../../utilities/frontendTypes';
import ToggleButtonDefault from '../base/ToggleButtonDefault';

export interface RoleButtonProps {
  title: ReactNode;
  selected?: boolean;
  onClick: () => void;
}

const RoleButton = (props: RoleButtonProps): JSX.Element => {
  const { title, selected, onClick } = props;

  return (
    <Paper
      // className={`project-roles-buttons__card ${
      //   selected && 'project-roles-buttons__card--active'
      // }`}
      sx={{
        px: 2,
        py: 1.5,
        minWidth: 150,
        backgroundColor: selected ? Colours.Red.Primary : Colours.White.Pure,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: selected
            ? Colours.Red.Darkened
            : Colours.White.Darkened,
        },
      }}
      elevation={0}
      onClick={onClick}
    >
      <Typography
        variant="body1"
        textAlign="center"
        color={selected ? Colours.White.Pure : undefined}
        className="project-roles-buttons__card-name"
      >
        {title}
      </Typography>
    </Paper>
  );
};

export interface RolesButtonListProps {
  roles: IRole[];
  selected?: IRole['id'];
  onSelect: (role: IRole) => void;
  onAdd: () => void;
  isLoading?: boolean;
}
const RolesButtonsList = (props: RolesButtonListProps): JSX.Element => {
  const { roles, selected, onSelect, onAdd, isLoading } = props;

  const handleClick = (role: IRoleDetails) => () => {
    onSelect(role);
  };

  return (
    <Stack direction="row" spacing={2} sx={{ mb: 4 }} alignItems="center">
      {isLoading ? (
        <>
          <Skeleton variant="rounded" width={150} height={48} />
          <Skeleton variant="rounded" width={150} height={48} />
          <Skeleton variant="rounded" width={150} height={48} />
          <Skeleton variant="rounded" width={150} height={48} />
          <Skeleton variant="rounded" width={150} height={48} />
        </>
      ) : (
        <>
          {roles.map((role) => (
            <ToggleButtonDefault
              key={role.id}
              value={role.id}
              selected={role.id === selected}
              onClick={handleClick(role)}
              sx={{
                minWidth: 150,
              }}
            >
              <Typography
                sx={{
                  color: 'inherit',
                  maxWidth: 300,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {role.role_name}
              </Typography>
            </ToggleButtonDefault>
          ))}
          <ToggleButtonDefault
            value={-1}
            selected={selected === undefined}
            onClick={onAdd}
            sx={{
              minWidth: 150,
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <AddCircleIcon />
              <Typography
                sx={{
                  color: 'inherit',
                  maxWidth: 300,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                Add Role
              </Typography>
            </Stack>
          </ToggleButtonDefault>
        </>
      )}
    </Stack>
  );
};
export default RolesButtonsList;
