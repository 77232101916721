import { Article } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BarChartIcon from '@mui/icons-material/BarChart';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import ListIcon from '@mui/icons-material/List';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import SchemaIcon from '@mui/icons-material/Schema';
import StorageIcon from '@mui/icons-material/Storage';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { NavLink } from 'react-router-dom';
import SpacesInteractiveLogo from '../assets/images/spaces-logo.png';
import PATHS from '../constants/pathGenerators';
import reviewService from '../services/reviewService';
import { PermissionNames } from '../services/transportTypes/BaseTypes';
import { ServiceNames } from '../services/transportTypes/NService';
// import { getReviewChanges } from '../store/reviewSlice';
import usePermission from '../utilities/hooks/usePermission';
import useProject from '../utilities/hooks/useProject';
import useSelf from '../utilities/hooks/useSelf';
import BadgeCircle from './base/BadgeCircle';

export interface SideNavProps {}

const SideNav = (props: SideNavProps): JSX.Element => {
  const [user, userLoading] = useSelf();
  const [currentProject] = useProject();
  const projectSlug = currentProject?.slug;

  // Permission checks
  // p*: a product specific permission
  // a*: an admin permission
  const aUser = usePermission(PermissionNames.AdminUser, 'read');
  const pUser = usePermission(PermissionNames.ProjectUser, 'read');
  const pRole = usePermission(PermissionNames.Role, 'read');
  const aRole = usePermission(PermissionNames.AdminRole, 'read');
  const pRoleUser = usePermission(PermissionNames.RoleUser, 'read');
  const aRoleUser = usePermission(PermissionNames.AdminRoleUser, 'read');
  const pAnalytics = usePermission(PermissionNames.Analytics, 'read');
  const aAnalytics = usePermission(PermissionNames.AdminAnalytics, 'read');
  const pCms = usePermission(PermissionNames.CMS, 'read');
  const aCms = usePermission(PermissionNames.AdminCMS, 'read');
  const showDataCapture = usePermission(PermissionNames.DataCapture, 'read');
  const pEmailIntegration = usePermission(
    PermissionNames.EmailIntegration,
    'read',
  );
  const aContactForm = usePermission(PermissionNames.AdminContactForm, 'read');
  const pContactForm = usePermission(PermissionNames.ContactForm, 'read');

  // Service Checks
  const services = currentProject?.services ?? [];
  const hasContactForm = services.some(
    (s) => s.name === ServiceNames.ContactForm,
  );

  const showUsers = aUser || pUser;
  const showRoles = pRole || aRole || aRoleUser || pRoleUser;
  const showCms = pCms || aCms;
  const showAnalytics = pAnalytics || aAnalytics;
  const showReview = showCms;
  const showEmailIntegration = pEmailIntegration;
  const showContactForm = hasContactForm && (aContactForm || pContactForm);

  const { data: reviews = [], isLoading } = useQuery({
    queryFn: () => reviewService.getReviewChanges(currentProject!.id),
    queryKey: [reviewService.getReviewChanges.name, currentProject?.slug],
    enabled: !!currentProject,
  });

  return (
    <div className="side-nav">
      <Grid className="side-nav__authorized">
        <Grid className="side-nav__logo">
          <img width="100%" src={SpacesInteractiveLogo} alt="spaces logo" />
        </Grid>
        <Grid className="side-nav__menu-container">
          <NavLink end className="side-nav__menu" to={PATHS.HOME(projectSlug)}>
            <HomeIcon />
            <p className="side-nav__menu-text">Home</p>
          </NavLink>
          {showUsers && (
            <NavLink className="side-nav__menu" to={PATHS.USERS(projectSlug)}>
              <GroupIcon />
              <p className="side-nav__menu-text">Users</p>
            </NavLink>
          )}
          <NavLink className="side-nav__menu" to={PATHS.PROJECTS(projectSlug)}>
            <DashboardIcon />
            <p className="side-nav__menu-text">Projects</p>
          </NavLink>
          {currentProject && (
            <div>
              <NavLink
                end
                className="side-nav__menu side-nav__menu--sub"
                to={PATHS.PROJECTS_HOME(projectSlug)}
              >
                <BarChartIcon />
                <p className="side-nav__menu-text">{currentProject.name}</p>
              </NavLink>
              {showRoles && (
                <NavLink
                  className="side-nav__menu side-nav__menu--sub"
                  to={PATHS.ROLES(projectSlug)}
                >
                  <ListIcon />
                  <p className="side-nav__menu-text">Roles</p>
                </NavLink>
              )}
              {showCms && (
                <NavLink
                  className="side-nav__menu side-nav__menu--sub"
                  to={PATHS.DOC_SCHEMAS(projectSlug)}
                >
                  <SchemaIcon />
                  <p className="side-nav__menu-text">Doc Schemas</p>
                </NavLink>
              )}
              {showCms && (
                <NavLink
                  className="side-nav__menu side-nav__menu--sub"
                  to={PATHS.DOCUMENTS(projectSlug)}
                >
                  <Article />
                  <p className="side-nav__menu-text">Documents</p>
                </NavLink>
              )}
              {showAnalytics && (
                <NavLink
                  className="side-nav__menu side-nav__menu--sub"
                  to={PATHS.ANALYTICS(projectSlug)}
                >
                  <TimelineIcon />
                  <p className="side-nav__menu-text">Analytics</p>
                </NavLink>
              )}
              {showReview && (
                <NavLink
                  className="side-nav__menu side-nav__menu--sub"
                  to={PATHS.REVIEW(projectSlug)}
                >
                  <PlaylistAddCheckIcon />
                  <p className="side-nav__menu-text">Review</p>
                  <BadgeCircle
                    className="side-nav__badge"
                    size="18px"
                    icon={
                      <p style={{ color: 'white', fontSize: '12px' }}>
                        {reviews.length}
                      </p>
                    }
                  />
                </NavLink>
              )}
              {showDataCapture && (
                <NavLink
                  className="side-nav__menu side-nav__menu--sub"
                  to={PATHS.DATA_CAPTURE()}
                >
                  <StorageIcon />
                  <p className="side-nav__menu-text">Data Capture</p>
                </NavLink>
              )}
              {showEmailIntegration && (
                <NavLink
                  className="side-nav__menu side-nav__menu--sub"
                  to={PATHS.FORM_DATA()}
                >
                  <DescriptionOutlinedIcon />
                  <p className="side-nav__menu-text">Form Data</p>
                </NavLink>
              )}
              {showContactForm && (
                <NavLink
                  className="side-nav__menu side-nav__menu--sub"
                  to={PATHS.CONTACT_FORM(projectSlug)}
                >
                  <MailOutlineIcon />
                  <p className="side-nav__menu-text">Contact Form</p>
                </NavLink>
              )}
            </div>
          )}
        </Grid>

        <Grid className="side-nav__user-info">
          {user && (
            <NavLink
              className="side-nav__menu side-nav__menu--user"
              to={PATHS.MY_ACCOUNT()}
            >
              <Grid className="side-nav__user-detail-container">
                <AccountCircleIcon style={{ fontSize: '42px' }} />

                <Grid className="side-nav__user-detail">
                  <p className="side-nav__user-detail-name">{user.name}</p>
                  <p className="side-nav__user-detail-email">{user.email}</p>
                </Grid>
              </Grid>
            </NavLink>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default SideNav;
