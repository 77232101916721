import React, { ChangeEvent, useEffect, useState } from 'react';
import { stripToFloat, stripToInteger } from '../../utilities/functions';
import InputDefault, { InputDefaultProps } from './InputDefault';

export interface NumericInputProps
  extends Omit<InputDefaultProps, 'value' | 'onChange'> {
  value: number | null;
  onChange: (value: number | null) => void;
  float?: boolean;
}

const NumericInput = (props: NumericInputProps): JSX.Element => {
  const { value, float, onChange, ...others } = props;

  const [valueStr, setValueStr] = useState(value ? value.toString() : '');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const raw = e.target.value;
    const cleaned = float ? stripToFloat(raw) : stripToInteger(raw);
    setValueStr(cleaned);

    if (!cleaned) {
      // console.log('NumericInput handleChange', {
      //   value,
      //   valueStr,
      //   raw,
      //   cleaned,
      //   change: null,
      // });
      onChange(null);
    } else {
      const change = float ? parseFloat(cleaned) : parseInt(cleaned);

      // console.log('NumericInput handleChange', {
      //   value,
      //   valueStr,
      //   raw,
      //   cleaned,
      //   change,
      // });
      if (change !== value && !Number.isNaN(change)) {
        onChange(change);
      }
    }
  };

  useEffect(() => {
    // console.log('NumericInput valueEffect', {
    //   value,
    //   valueStr,
    // });
    setValueStr(value ? value.toString() : '');
  }, [value]);

  return (
    <InputDefault
      value={valueStr}
      inputProps={{
        inputMode: 'numeric',
        pattern: float ? '[0-9]*' : '[0-9]*(.[0-9]*)?',
      }}
      onChange={handleChange}
      {...others}
    />
  );
};

export default NumericInput;
