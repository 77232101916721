import { differenceInDays, startOfToday } from 'date-fns';
import React from 'react';
import { defaultOptionsFilters } from '../../../constants/analyticsConstants';
import { IIPData } from '../../../services/transportTypes/BaseTypes';
import AnalyticsTable, {
  AnalyticsTableProps,
  AnalyticsTableRow,
} from '../charts/AnalyticsTable';
import FilterSelect from '../FilterSelect';
import groupEvents from '../utilities/groupEvents';
import useFilterSelect from '../utilities/useFilterSelect';

export interface LoginHistoryAnalyticsProps
  extends Pick<AnalyticsTableProps, 'isLoading' | 'sx'> {
  data: IIPData[];
}

const LocationTable = (props: LoginHistoryAnalyticsProps): JSX.Element => {
  const { data, ...others } = props;

  // console.log('LocationTable', props);

  const filterProps = useFilterSelect(
    defaultOptionsFilters,
    defaultOptionsFilters[0],
  );

  const currentFilterDays =
    filterProps.selected?.value ?? defaultOptionsFilters[0].value;
  const filteredEvents = data.filter((event) => {
    if (event.created_at) {
      return differenceInDays(startOfToday(), event.created_at) <= currentFilterDays;
    }
    return false;
  });

  const aggregateData = groupEvents(
    (e) => ({
      id: `${e.country_name}, ${e.city};`,
      name: `${e.country_name}, ${e.city};`,
      data: 1,
      percentage: 0,
      events: [e],
    }),
    filteredEvents,
  );

  // To display when login data is empty
  const placeholder: AnalyticsTableRow[] = new Array(5)
    .fill(undefined)
    .map((_, index) => ({
      id: index,
      name: 'No data to show',
      data: 0,
    }));

  return (
    <AnalyticsTable
      name="Logins"
      category="Location"
      data={aggregateData.length === 0 ? placeholder : aggregateData}
      headerChildren={<FilterSelect {...filterProps} />}
      {...others}
    />
  );
};

export default LocationTable;
