import Interval, {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInHours,
  eachDayOfInterval,
  eachHourOfInterval,
  eachMonthOfInterval,
  eachQuarterOfInterval,
  eachWeekOfInterval,
  eachYearOfInterval,
} from 'date-fns';
import { KeysOf } from '../services/transportTypes/BaseTypes';

export function increaseMinMax(
  [min, max]: [number, number],
  value: number,
): [number, number] {
  return [value < min ? value : min, value > max ? value : max];
}

export const AggregatePeriods = {
  HOUR: 'hour',
  THREE_HOUR: '3hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year',
} as const;

export type AggregatePeriod = KeysOf<typeof AggregatePeriods>;

interface AggregateBucket<T extends { date: Date }> {
  date: Date;
  items: T[];
}

export function isDateInInterval(
  date: Date | string | number,
  period: Interval,
): boolean {
  const createdAt = new Date(date);
  return period.start <= createdAt && createdAt <= period.end;
}

export function pickBestAggregate(
  interval: Interval,
  maxNodes: number = 32,
): AggregatePeriod {
  // console.log('pickBestAggregate', maxNodes);

  const hours = differenceInHours(interval.end, interval.start);
  // console.log('hours', hours);

  if (hours <= maxNodes) {
    return AggregatePeriods.HOUR;
  }

  if (hours / 3 <= maxNodes) {
    return AggregatePeriods.THREE_HOUR;
  }

  const days = differenceInCalendarDays(interval.end, interval.start);
  // console.log('days', days);

  if (days <= maxNodes) {
    return AggregatePeriods.DAY;
  }

  if (days / 7 <= maxNodes) {
    return AggregatePeriods.WEEK;
  }

  const months = differenceInCalendarMonths(interval.end, interval.start);
  // console.log('months', months);

  if (months <= maxNodes) {
    return AggregatePeriods.MONTH;
  }

  if (months / 3 <= maxNodes) {
    return AggregatePeriods.QUARTER;
  }

  return AggregatePeriods.YEAR;
}

export function generateBuckets(
  interval: Interval,
  aggregate: AggregatePeriod,
) {
  switch (aggregate) {
    case AggregatePeriods.HOUR:
      return eachHourOfInterval(interval);
    case AggregatePeriods.THREE_HOUR:
      return eachHourOfInterval(interval).filter((d, i) => i % 3 === 0);
    case AggregatePeriods.DAY:
      return eachDayOfInterval(interval);
    case AggregatePeriods.WEEK:
      return eachWeekOfInterval(interval);
    case AggregatePeriods.MONTH:
      return eachMonthOfInterval(interval);
    case AggregatePeriods.QUARTER:
      return eachQuarterOfInterval(interval);
    case AggregatePeriods.YEAR:
      return eachYearOfInterval(interval);
    default:
      return [];
  }
}

export function aggregateEvents<T extends { date: Date }>(
  items: T[],
  interval: Interval,
  aggregate: AggregatePeriod = pickBestAggregate(interval),
): AggregateBucket<T>[] {
  const buckets = generateBuckets(interval, aggregate).map<AggregateBucket<T>>(
    (date) => ({ date, items: [] }),
  );
  const sortedItems = items.sort((a, b) => a.date.valueOf() - b.date.valueOf());

  let itemIndex = 0;
  let bucketIndex = 0;
  while (bucketIndex < buckets.length && itemIndex < sortedItems.length) {
    const nextBucket = buckets[bucketIndex];
    const item = sortedItems[itemIndex];

    // console.log(`Bucket ${bucketIndex}, Item ${itemIndex}`);
    if (item.date < nextBucket.date) {
      if (bucketIndex > 0) {
        buckets[bucketIndex - 1].items.push(item);
        // console.log('Added');
      } else {
        // console.log('Before start of interval');
      }
      itemIndex += 1;
    } else {
      // console.log('Check next bucket');
      bucketIndex += 1;
    }
  }

  return buckets;
}
