import { Grid, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import React, { ChangeEvent, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ButtonDefault from '../components/base/ButtonDefault';
import PasswordRequirementList, {
  Requirement,
} from '../components/PasswordRequirementList';
import PathGenerators from '../constants/pathGenerators';
import UnauthorizedPage from '../containers/UnauthorizedPage';
import { ErrorResponse } from '../services/transportTypes/BaseTypes';
import userService from '../services/userService';
import useAlert from '../utilities/hooks/useAlert';

export interface SetPasswordData {
  password: string;
  confirmPassword: string;
}

const PasswordRegexes = {
  ContainsLowercase: new RegExp('[a-z]'),
  ContainsUppercase: new RegExp('[A-Z]'),
  ContainsNumber: new RegExp('\\d'),
  ContainsSymbol: new RegExp('[^\\w\\d]'),
  Trimmed: new RegExp('^\\S.+\\S$'),
};

const SetPassword = (): JSX.Element => {
  const navigate = useNavigate();
  const alert = useAlert();
  const [searchParams] = useSearchParams();

  const [password, setPassword] = useState('');
  const [checkPassword, setCheckPassword] = useState('');

  const token = searchParams.get('token');

  const resetPasswordMutation = useMutation(userService.resetPassword, {
    onSuccess: () => {
      alert({
        message: 'Your password has been updated',
        severity: 'success',
      });
      setTimeout(() => navigate(PathGenerators.LOGIN()), 3000);
    },
    onError: (data: ErrorResponse) =>
      alert({ message: data.message, severity: 'error' }),
  });

  const handlePasswordChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setPassword(e.target.value);
  };

  const handleCheckPasswordChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setCheckPassword(e.target.value);
  };

  const handleSubmit = () => {
    if (token) {
      resetPasswordMutation.mutate({
        password,
        token,
      });
    } else {
      alert({
        message:
          'A problem has occured, please open this page using the link provided in your email',
        severity: 'error',
      });
    }
  };

  const lengthValid = password.length >= 8 && password.length <= 64;
  const containsLower = PasswordRegexes.ContainsLowercase.test(password);
  const containsUpper = PasswordRegexes.ContainsUppercase.test(password);
  const containsNumber = PasswordRegexes.ContainsNumber.test(password);
  const containsSymbol = PasswordRegexes.ContainsSymbol.test(password);
  const varied =
    [containsLower, containsUpper, containsNumber, containsSymbol].filter(
      (b) => b,
    ).length >= 3;
  const trimmed = PasswordRegexes.Trimmed.test(password);
  const matching = password === checkPassword;

  const passwordOk = lengthValid && varied && trimmed && matching;

  const requirements: Requirement[] = [
    {
      name: 'Contain at least 8 characters and no more than 64 characters',
      satisfied: lengthValid,
    },
    {
      name: 'Include at least 3 of the following:',
      satisfied: varied,
      childRequirements: [
        {
          name: 'lowercase characters',
          satisfied: containsLower,
        },
        {
          name: 'uppercase  characters',
          satisfied: containsUpper,
        },
        {
          name: 'numbers',
          satisfied: containsNumber,
        },
        {
          name: 'symbols',
          satisfied: containsSymbol,
        },
      ],
    },
    {
      name: 'Not start or end with whitespace',
      satisfied: trimmed,
    },
    {
      name: 'Match',
      satisfied: matching,
    },
    // {
    //   name: 'Ok',
    //   satisfied: passwordOk,
    // },
  ];

  return (
    <UnauthorizedPage>
      <Grid
        className="set-password-container"
        container
        direction="column"
        item
        xs={12}
      >
        <Typography variant="title" sx={{ pb: 6 }}>
          Set password
        </Typography>

        <Grid container direction="row-reverse" spacing={3}>
          <Grid container item xs={12} lg={6}>
            <PasswordRequirementList requirements={requirements} />
          </Grid>
          <Grid
            container
            item
            xs={12}
            lg={6}
            direction="column"
            spacing={3}
            component="form"
          >
            <Grid item>
              <TextField
                variant="outlined"
                label="Password"
                type="password"
                className="set-password-container__input"
                value={password}
                onChange={handlePasswordChange}
                required
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                label="Confirm Password"
                type="password"
                className="set-password-container__input"
                value={checkPassword}
                onChange={handleCheckPasswordChange}
                required
              />
            </Grid>
            <Grid item>
              <ButtonDefault
                className="set-password-container__button"
                onClick={handleSubmit}
                large
                disabled={!passwordOk}
              >
                CONFIRM
              </ButtonDefault>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </UnauthorizedPage>
  );
};

export default SetPassword;
