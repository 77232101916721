import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { combineSxProp } from '../../utilities/functions';

export type InputDefaultProps = TextFieldProps;

const InputDefault = (props: InputDefaultProps): JSX.Element => {
  const { sx, variant = 'outlined', ...others } = props;

  return (
    <TextField
      {...others}
      variant={variant}
      sx={combineSxProp(
        {
          width: '100%',
        },
        sx,
      )}
    />
  );
};

export default InputDefault;
