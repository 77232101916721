import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { CardActionArea, CardActionAreaProps } from '@mui/material';
import SpacesInteractiveLogo from '../assets/images/spaces-logo.png';

export interface ProjectCardProps {
  disabled?: boolean;
  logoUrl?: string | null;
  name: string;
  onClick: CardActionAreaProps['onClick'];
  onDelete: IconButtonProps['onClick'];
}

const ProjectCard = (props: ProjectCardProps): JSX.Element => {
  const { disabled, logoUrl, name, onClick, onDelete } = props;

  return (
    <Card
      sx={{
        borderRadius: 2.5,
        position: 'relative',
        display: 'flex',
      }}
      elevation={4}
    >
      <CardActionArea
        onClick={onClick}
        sx={{
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          padding: 1,
        }}
      >
        <Box
          component="img"
          src={logoUrl || SpacesInteractiveLogo}
          alt={name}
          sx={{
            width: 140,
            height: 140,
            objectFit: 'contain',
          }}
        />
        <Typography variant="body1">View Project</Typography>
        <Typography variant="h3">{name}</Typography>
      </CardActionArea>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <IconButton
          aria-label="close"
          size="large"
          disabled={disabled}
          onClick={onDelete}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Card>
  );
};

export default ProjectCard;
