import { Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonDefault from '../components/base/ButtonDefault';
import PageHeader from '../components/base/PageHeader';
import { BodyPanel } from '../components/base/Panel';
import SchemaEditor, {
  SchemaEditorField,
} from '../components/cms/SchemaEditor';
import PathGenerators from '../constants/pathGenerators';
import AuthorizedPage from '../containers/AuthorizedPage';
import cmsService from '../services/cmsService';
import { AllDocumentFieldTypes } from '../services/transportTypes/NCms';
import { isValidGraphQLName } from '../utilities/DocumentUtils';
import useAlert from '../utilities/hooks/useAlert';
import useProject from '../utilities/hooks/useProject';

const DocSchemaCreate = (): JSX.Element => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const alert = useAlert();

  const [project, projectLoading] = useProject();

  const [name, setName] = useState('');
  const [fields, setFields] = useState<SchemaEditorField[]>([
    {
      id: 1,
      name: '',
      type: AllDocumentFieldTypes[0],
    },
  ]);
  const [errors, setErrors] = useState<string[]>([]);

  const createSchemaMutation = useMutation({
    mutationKey: [cmsService.addSchema.name],
    mutationFn: cmsService.addSchema,
    onSuccess: (data) => {
      alert({
        message: `${data.name} created.`,
        severity: 'success',
      });
      navigate(
        PathGenerators.DOC_SCHEMA_EDIT(project?.slug, data.id.toString()),
      );
      queryClient.setQueriesData(
        [
          cmsService.getSchema.name,
          { projectId: project?.slug, schemaId: data.id },
        ],
        data,
      );
    },
    onError: (error: string) => {
      console.error('Create schema error', { error });
      alert({
        message: error,
        severity: 'error',
      });
    },
  });

  const handleSave = () => {
    // todo validation
    const saveErrors = fields.flatMap((f) => {
      if (!f.name || !isValidGraphQLName(name)) {
        return [`field.${f.id}.name`];
      }
      return [];
    });
    if (fields.length === 0) {
      saveErrors.push('fields');
    }

    if (!name || !isValidGraphQLName(name)) {
      saveErrors.push('name');
    }

    console.log('Create Schema', { name, fields });

    if (project && saveErrors.length === 0) {
      createSchemaMutation.mutate({
        projectId: project.slug,
        body: {
          name,
          fields: fields.map(({ name: fieldName, type }, i) => ({
            name: fieldName,
            type,
            sequence: i,
            properties: null,
          })),
        },
      });
    }
    setErrors(saveErrors);
  };

  const isLoading =
    projectLoading ||
    createSchemaMutation.isLoading ||
    createSchemaMutation.isSuccess;

  return (
    <AuthorizedPage>
      <PageHeader
        title="Create Schema"
        hintMessage="Define schemas for your cms documents."
        displayGoBack
      />
      <BodyPanel>
        <Stack direction="column" spacing={2}>
          <SchemaEditor
            name={name}
            fields={fields}
            errors={errors}
            isLoading={isLoading}
            onNameChange={(n) => {
              setName(n);
            }}
            onFieldsChange={(f) => setFields(f)}
          />
          <Stack direction="row" justifyContent="flex-end">
            <p>{errors.join(', ')}</p>
            <ButtonDefault onClick={handleSave} disabled={isLoading}>
              Save
            </ButtonDefault>
          </Stack>
        </Stack>
      </BodyPanel>
    </AuthorizedPage>
  );
};

export default DocSchemaCreate;
