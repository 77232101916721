import { useSearchParams } from 'react-router-dom';
import { applyFilter, Filter } from '../../components/base/TableDefault/Filter';
import { RowData } from '../../components/base/TableDefault';
import queryConstants from '../../constants/queryConstants';

export default function useUrlFilters<Data extends RowData>(): [
  Filter<Data>[],
  (newFilters: Filter<Data>[]) => void,
  (data: Data[]) => Data[],
  ] {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = searchParams
    .getAll(queryConstants.FILTERS)
    .map<Filter<Data>>((serialized) => JSON.parse(serialized));

  const updateFilters = (newFilters: Filter<Data>[]) => {
    searchParams.delete(queryConstants.FILTERS);
    newFilters.forEach((f) => {
      searchParams.append(queryConstants.FILTERS, JSON.stringify(f));
    });
    setSearchParams(searchParams);
  };

  const applyFilters = (rawData: Data[]) =>
    rawData.filter((d) => filters.every((f) => applyFilter(d, f)));

  return [filters, updateFilters, applyFilters];
}
