import { Grid, Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonDefault from '../components/base/ButtonDefault';
import PageHeader from '../components/base/PageHeader';
import Panel from '../components/base/Panel';
import DefaultRolePicker from '../components/project/DefaultRolePicker';
import ProjectPropertiesEditor, {
  ProjectPropertiesDictionary,
} from '../components/project/ProjectPropertiesEditor';
import ServiceEditor from '../components/project/ServiceEditor';
import MESSAGES from '../constants/messagesConstants';
import PathGenerators from '../constants/pathGenerators';
import AuthorizedPage from '../containers/AuthorizedPage';
import projectService from '../services/projectService';
import { ServiceNames } from '../services/transportTypes/NService';
import { IService } from '../utilities/backendTypes';
import useAlert from '../utilities/hooks/useAlert';
import useProject from '../utilities/hooks/useProject';
import useServiceList from '../utilities/hooks/useServiceList';

const ProjectEdit = (): JSX.Element => {
  const navigate = useNavigate();
  const alert = useAlert();

  const [project] = useProject();
  const [services] = useServiceList();

  const [properties, setProperties] = useState<ProjectPropertiesDictionary>({
    name: '',
    client: '',
    logo_url: null,
    slug: '',
  });
  const [fieldErrors, setFieldErrors] = useState<string[]>([]);
  const [serviceNames, setServiceNames] = useState<IService['name'][]>([]);

  const updateProjectMutation = useMutation(projectService.updateProject, {
    onSuccess: (update) => {
      alert({
        message: MESSAGES.PROJECT_UPDATED,
        severity: 'success',
      });
      navigate(PathGenerators.PROJECTS_HOME(update.slug));
    },
    onError: (error: Error) => {
      alert({
        message: error.message,
        severity: 'error',
      });
    },
  });

  const handleSave = () => {
    const errors: string[] = [];

    if (!properties.name) {
      errors.push('name');
    }
    if (!properties.client) {
      errors.push('client');
    }
    if (errors.length) {
      alert({
        message: 'Please fill all mandatory fields.',
        severity: 'error',
      });
    }

    if (!serviceNames.includes(ServiceNames.Core)) {
      alert({
        message: 'Core services must be selected.',
        severity: 'error',
      });
      errors.push('slug');
    }
    // Only dashboard project can have admin
    if (
      serviceNames.includes(ServiceNames.PlatformAdmin) &&
      project?.id !== 1
    ) {
      alert({
        message: 'Admin services may not be selected.',
        severity: 'error',
      });
      errors.push('slug');
    }

    console.log('onSave', { properties, serviceIds: serviceNames, errors });
    setFieldErrors(errors);
    if (errors.length === 0) {
      if (project) {
        updateProjectMutation.mutate({
          idOrSlug: project.slug,
          body: {
            name: properties.name,
            client: properties.client,
            logo_url: properties.logo_url,
            slug: properties.slug,
            services: services
              .filter((s) => serviceNames.includes(s.name))
              .map((s) => s.id),
            special_permissions: false,
          },
        });
      } else {
        console.error('Project save called while project still loading');
        alert({
          message: 'Project has not yet loaded.',
          severity: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (project) {
      if (project.services) {
        setServiceNames(project.services.map((s) => s.name));
      }

      setProperties(project);
    }
  }, [project]);

  return (
    <AuthorizedPage>
      <PageHeader
        title={project?.name ?? 'Edit Project'}
        hintMessage="Edit project settings"
      />
      <Panel>
        <Grid container spacing={10} alignItems="flex-start">
          <Grid item xs={12} sm={12} md={12} lg={7}>
            <ProjectPropertiesEditor
              values={properties}
              errors={fieldErrors}
              onChange={(update) => setProperties(update)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5}>
            <Stack alignItems="stretch" spacing={4}>
              <ServiceEditor
                services={services.filter(
                  (s) => s.name !== ServiceNames.PlatformAdmin,
                )}
                selected={serviceNames}
                onChange={(update) => setServiceNames(update)}
              />
              <DefaultRolePicker
                roles={project?.roles ?? []}
                selected={project?.roles.map((r) => r.id) ?? []}
                disabled
              />
            </Stack>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="flex-end">
          <ButtonDefault
            large
            onClick={handleSave}
            isLoading={updateProjectMutation.isLoading}
            disabled={!project}
          >
            Save
          </ButtonDefault>
        </Stack>
      </Panel>
    </AuthorizedPage>
  );
};

export default ProjectEdit;
