import { useQuery } from '@tanstack/react-query';
import { IPermission, IProject } from '../../services/transportTypes/BaseTypes';
import userService from '../../services/userService';

export default function (): [IPermission[], boolean] {
  const { data: permissions, isLoading } = useQuery<IPermission[]>(
    [userService.listSelfPermissions.name],
    () => userService.listSelfPermissions(),
  );

  return [permissions ?? [], isLoading];
}
