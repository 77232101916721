import React from 'react';
import { Grid } from '@mui/material';
import PageHeader from '../components/base/PageHeader';
import AuthorizedPage from '../containers/AuthorizedPage';
import TableDefault, {
  ColumnData,
  RowData,
} from '../components/base/TableDefault';

interface detailsParams {
  details: string;
}

export interface AnalyticsDetailsProps<Data extends RowData> {
  columns: ColumnData<Data>[];
  rows: Data[];
  hint: string;
}

function AnalyticsDetails<Data extends RowData>(props: AnalyticsDetailsProps<Data>): JSX.Element {
  const { columns, rows, hint } = props;

  return (
    <AuthorizedPage>
      <PageHeader title="Analytics details" shouldCapitalizeHint hintMessage={hint} displayGoBack />
      <Grid item>
        <TableDefault<Data>
          columns={columns}
          rows={rows}
          filters={[]}
          isReadOnly
        />
      </Grid>
    </AuthorizedPage>
  );
}

export default AnalyticsDetails;
