import React from 'react';
import { FormControlLabel, Grid, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { DefaultRole } from '../../utilities/backendTypes';
import RedCheckbox from '../base/RedCheckbox';
import HeaderComponent from '../base/HeaderComponent';

export interface DefaultRolePickerProps {
  roles: DefaultRole[];
  selected: DefaultRole['id'][];
  onChange?: (update: DefaultRole['id'][]) => void;
  disabled?: boolean;
  sx?: SxProps;
}

const DefaultRolePicker = (props: DefaultRolePickerProps): JSX.Element => {
  const { roles, selected, onChange, disabled, sx } = props;

  const handleCheckboxChange = (changed: DefaultRole['id']) => {
    if (onChange) {
      if (selected.includes(changed)) {
        onChange(selected.filter((id) => id !== changed));
      } else {
        onChange([...selected, changed]);
      }
    }
  };

  return (
    <Stack alignItems="stretch" sx={sx}>
      <HeaderComponent title="Roles" />
      <Grid container spacing={1}>
        {roles.map((item) => (
          <Grid item xs={4} key={item.id}>
            <FormControlLabel
              control={
                <RedCheckbox
                  onChange={() => handleCheckboxChange(item.id)}
                  name={item.role_name}
                  checked={selected.includes(item.id)}
                />
              }
              disabled={disabled}
              label={item.role_name}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
export default DefaultRolePicker;
