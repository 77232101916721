import React, { useState } from 'react';
import { IProject } from '../../services/transportTypes/BaseTypes';
import useProjectActivityHistory, {
  ActivityLogRecord,
} from '../../utilities/hooks/useProjectActivityHistory';
import ActivityLog, { ActivityLogProps } from '../base/controlled/ActivityLog';
import ViewActivityOverlay from '../base/ViewActivityOverlay';

export interface ProjectActivityLogProps
  extends Omit<ActivityLogProps, 'records' | 'isLoading' | 'onClick'> {
  project?: IProject;
}

const ProjectActivityLog = (props: ProjectActivityLogProps): JSX.Element => {
  const { project, ...others } = props;
  const [records, isLoading] = useProjectActivityHistory(project);

  const [selected, setSelected] = useState<ActivityLogRecord>();

  const handleSelect = (record: ActivityLogRecord | undefined) => {
    setSelected(record);
  };
  return (
    <>
      <ActivityLog
        records={records}
        isLoading={isLoading}
        onClick={handleSelect}
        {...others}
      />
      <ViewActivityOverlay
        record={selected}
        allRecords={records}
        onClose={() => handleSelect(undefined)}
      />
    </>
  );
};

export default ProjectActivityLog;
