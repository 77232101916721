import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import PATHS from '../constants/pathGenerators';
import ActivityLog from '../pages/ActivityLog';
import Analytics from '../pages/Analytics';
import AnalyticsBrowserDetails from '../pages/AnalyticsBrowserDetails';
import AnalyticsLoginDetails from '../pages/AnalyticsLoginDetails';
import AnalyticsPDFDetails from '../pages/AnalyticsPDFDetails';
import ContactForm from '../pages/ContactForm';
import ContactFormManagement from '../pages/ContactFormManagement';
import DataCapture from '../pages/DataCapture';
import DocSchemaCreate from '../pages/DocSchemaCreate';
import DocSchemaEdit from '../pages/DocSchemaEdit';
import DocSchemas from '../pages/DocSchemas';
import DocumentCreate from '../pages/DocumentCreate';
import DocumentEdit from '../pages/DocumentEdit';
import Documents from '../pages/Documents';
import ForgotPassword from '../pages/ForgotPassword';
import FormData from '../pages/FormData';
import Home from '../pages/Home';
import Login from '../pages/Login';
import LoginHistory from '../pages/LoginHistory';
import MyAccount from '../pages/MyAccount';
import ProjectCreate from '../pages/ProjectCreate';
import ProjectEdit from '../pages/ProjectEdit';
import ProjectHome from '../pages/ProjectHome';
import Projects from '../pages/Projects';
import Review from '../pages/Review';
import Roles from '../pages/Roles';
import RolesDefine from '../pages/RolesDefine';
import SetPassword from '../pages/SetPassword';
import TwoFactorLogin from '../pages/TwoFactorLogin';
import UserCreate from '../pages/UserCreate';
import UserEdit from '../pages/UserEdit';
import Users from '../pages/Users';
import userService from '../services/userService';
import { useAppDispatch, useAppSelector } from '../store/store';
import { logout, tokenVerified } from '../store/userSlice';

const App = () => {
  const dispatch = useAppDispatch();
  const { token, isTokenValid } = useAppSelector((state) => state.user);

  const verifyAuth = useMutation({
    mutationKey: [userService.verifyAuth.name],
    mutationFn: userService.verifyAuth,
    onSuccess: () => {
      dispatch(tokenVerified());
    },
    onError: () => {
      dispatch(logout());
    },
  });

  useEffect(() => {
    // Verify tokens that have been saved to localstorage
    if (token && !isTokenValid) {
      verifyAuth.mutate();
    }
  }, [token, isTokenValid]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={PATHS.LOGIN()} element={<Login />} />
        <Route path={PATHS.TWO_FACTOR_LOGIN()} element={<TwoFactorLogin />} />
        <Route path={PATHS.FORGOT_PASSWORD()} element={<ForgotPassword />} />
        <Route path={PATHS.SET_PASSWORD()} element={<SetPassword />} />
        <Route path={PATHS.HOME()} element={<Home />} />
        <Route path={PATHS.USERS()} element={<Users />} />
        <Route path={PATHS.PROJECTS()} element={<Projects />} />
        <Route path={PATHS.PROJECTS_CREATE()} element={<ProjectCreate />} />
        <Route path={PATHS.PROJECTS_HOME()} element={<ProjectHome />} />
        <Route path={PATHS.PROJECTS_EDIT()} element={<ProjectEdit />} />
        <Route path={PATHS.USER_CREATE()} element={<UserCreate />} />
        <Route path={PATHS.USER_EDIT()} element={<UserEdit />} />
        <Route path={PATHS.MY_ACCOUNT()} element={<MyAccount />} />
        <Route path={PATHS.ROLES()} element={<Roles />} />
        <Route path={PATHS.ROLES_DEFINE()} element={<RolesDefine />} />
        <Route path={PATHS.ANALYTICS()} element={<Analytics />} />
        <Route
          path={PATHS.ANALYTICS_BROWSER_TYPES()}
          element={<AnalyticsBrowserDetails />}
        />
        <Route
          path={PATHS.ANALYTICS_PDFS_DOWNLOADED()}
          element={<AnalyticsPDFDetails />}
        />
        <Route
          path={PATHS.ANALYTICS_UNIQUE_LOGINS()}
          element={<AnalyticsLoginDetails />}
        />
        <Route path={PATHS.REVIEW()} element={<Review />} />
        <Route path={PATHS.DATA_CAPTURE()} element={<DataCapture />} />
        <Route path={PATHS.FORM_DATA()} element={<FormData />} />
        <Route path={PATHS.CONTACT_FORM()} element={<ContactForm />} />
        <Route
          path={PATHS.CONTACT_FORM_MANAGEMENT()}
          element={<ContactFormManagement />}
        />
        <Route path={PATHS.LOGIN_HISTORY()} element={<LoginHistory />} />
        <Route path={PATHS.ACTIVITY_LOG()} element={<ActivityLog />} />
        <Route path={PATHS.DOC_SCHEMAS()} element={<DocSchemas />} />
        <Route path={PATHS.DOC_SCHEMA_CREATE()} element={<DocSchemaCreate />} />
        <Route path={PATHS.DOC_SCHEMA_EDIT()} element={<DocSchemaEdit />} />
        <Route path={PATHS.DOCUMENTS()} element={<Documents />} />
        <Route path={PATHS.DOCUMENT_CREATE()} element={<DocumentCreate />} />
        <Route path={PATHS.DOCUMENT_EDIT()} element={<DocumentEdit />} />
        <Route path="*" element={<Navigate to={PATHS.LOGIN()} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
