import { Grid } from '@mui/material';
import React from 'react';
import PageHeader from '../components/base/PageHeader';
import TableDefault, { ColumnData } from '../components/base/TableDefault';
import AuthorizedPage from '../containers/AuthorizedPage';
import { ILoginHistory } from '../services/transportTypes/NAdminUser';
import { formatDate } from '../utilities/functions';
import useIdParam from '../utilities/hooks/useIdParam';
import useLoginHistory from '../utilities/hooks/useLoginHistory';
import useUser from '../utilities/hooks/useUser';

const LoginHistory = (): JSX.Element => {
  const userId = useIdParam();
  const [user, userLoading] = useUser(userId);
  const [history, historyLoading] = useLoginHistory(userId)

  const columns: ColumnData<ILoginHistory>[] = [
    {
      id: 'email',
      type: 'string',
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'device_type',
      type: 'string',
      disablePadding: false,
      label: 'Device',
    },
    {
      id: 'os',
      type: 'string',
      disablePadding: false,
      label: 'OS',
    },
    {
      id: 'name',
      type: 'string',
      disablePadding: false,
      label: 'Browser',
    },
    {
      id: 'created_at',
      type: 'date',
      disablePadding: false,
      label: 'Date/Time',
      formatter: (dateStr: Date) => formatDate(dateStr),
    },
  ];

  return (
    <AuthorizedPage>
      <PageHeader title="Login History" hintMessage={user?.name ?? ''} displayGoBack />
      <Grid className="dashboard-body">
        <Grid item>
          <TableDefault<ILoginHistory>
            columns={columns}
            rows={history}
            filters={[]}
            isLoading={historyLoading}
            isReadOnly
          />
        </Grid>
      </Grid>
    </AuthorizedPage>
  );
};

export default LoginHistory;
