import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Card, CardActionArea, Grid, Skeleton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AlertModal from '../components/base/AlertModal';
import PageHeader from '../components/base/PageHeader';
import ProjectCard from '../components/ProjectCard';
import DesignConstants from '../constants/designConstants';
import PATHS from '../constants/pathGenerators';
import queryConstants from '../constants/queryConstants';
import AuthorizedPage from '../containers/AuthorizedPage';
import projectService from '../services/projectService';
import { PermissionNames } from '../services/transportTypes/BaseTypes';
import { IProjectSummary } from '../services/transportTypes/NProject';
import useAlert from '../utilities/hooks/useAlert';
import usePermission from '../utilities/hooks/usePermission';
import useProjectList from '../utilities/hooks/useProjectList';

const PlaceholderCard = () => (
  <Skeleton
    variant="rounded"
    sx={{
      width: 156,
      height: '100%',
      minHeight: 199,
      borderRadius: 2.5,
    }}
  />
);

const Projects = (): JSX.Element => {
  const navigate = useNavigate();
  const alert = useAlert();
  const aUser = usePermission(PermissionNames.AdminUser, 'read');

  const [toDelete, setToDelete] = useState<IProjectSummary>();
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get(queryConstants.SEARCH);

  /*
   * React Query Implementations
   */
  const queryClient = useQueryClient();
  const [allProjects, projectsLoading] = useProjectList(true);

  const deleteProjectMutation = useMutation(projectService.deleteProject, {
    onError: (error) => {
      alert({
        message: error as string,
        severity: 'error',
      });
    },
    onSuccess: () => {
      queryClient.resetQueries(['getProjects']);
      alert({
        message: 'Project deleted',
        severity: 'success',
      });
    },
  });

  const handleDeleteModal = (item: IProjectSummary) => {
    setToDelete(item);
  };

  const performDelete = () => {
    // delete current item
    if (toDelete) {
      deleteProjectMutation.mutate(toDelete.slug); // TODO: Change endpoint to accept slug
      setToDelete(undefined);
    }
  };

  const handleProjectHome = (item: IProjectSummary) => {
    navigate(PATHS.PROJECTS_HOME(item.slug));
  };

  const handleAdd = () => {
    navigate(PATHS.PROJECTS_CREATE());
  };

  let filteredProjects = allProjects;
  if (searchText) {
    filteredProjects = allProjects.filter((p) =>
      p.name.toLowerCase().match(searchText.toLowerCase()),
    );
  }

  return (
    <AuthorizedPage>
      <PageHeader
        title="Projects"
        hintMessage="Add, view and remove projects."
        displaySearch
      />
      <Grid
        className="projects"
        container
        item
        xs={12}
        spacing={2}
        alignItems="stretch"
      >
        {projectsLoading ? (
          <>
            <Grid item>
              <PlaceholderCard />
            </Grid>
            <Grid item>
              <PlaceholderCard />
            </Grid>
            <Grid item>
              <PlaceholderCard />
            </Grid>
            <Grid item>
              <PlaceholderCard />
            </Grid>
            <Grid item>
              <PlaceholderCard />
            </Grid>
          </>
        ) : (
          filteredProjects.map((project) => (
            <Grid item key={project.id}>
              <ProjectCard
                logoUrl={project.logo_url}
                name={project.name}
                disabled={!aUser}
                onClick={() => handleProjectHome(project)}
                onDelete={() => handleDeleteModal(project)}
              />
            </Grid>
          ))
        )}

        <Grid item>
          <Tooltip title="Create Project">
            <Card
              sx={{
                borderRadius: 2.5,
                position: 'relative',
                display: 'flex',
                width: 156,
                height: '100%',
                minHeight: 199,
                background: DesignConstants.COLOR_LIGHT_GREY,
              }}
              elevation={4}
            >
              <CardActionArea
                onClick={() => handleAdd()}
                sx={{
                  display: 'flex',
                  flexFlow: 'column',
                  alignItems: 'center',
                  padding: 1,
                }}
              >
                <AddCircleIcon style={{ fontSize: ' 40px' }} />
              </CardActionArea>
            </Card>
          </Tooltip>
        </Grid>
      </Grid>

      {toDelete && (
        <AlertModal
          title="Delete Confirmation"
          modalStatus={!!toDelete}
          content={`Are you sure you want to delete ${toDelete.name}?`}
          handleCancel={() => setToDelete(undefined)}
          handleConfirm={performDelete}
        />
      )}
    </AuthorizedPage>
  );
};

export default Projects;
