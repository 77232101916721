import { IEndUserLoginHistory } from '../services/transportTypes/BaseTypes';

export interface UniqueEndUserLoginData extends IEndUserLoginHistory {
  firstLogin: string;
  latestLogin: string;
  totalLogins: number;
}

export default function (loginData: IEndUserLoginHistory[]): UniqueEndUserLoginData[] {
  const record = loginData.reduce<Record<string, UniqueEndUserLoginData>>(
    (acc, event) => {
      const userEmail = event.email;
      const eventDate = event.created_at.toString();
      const previous = acc[userEmail];

      if (!previous) {
        // This is the first login event found for the user
        acc[userEmail] = {
          ...event,
          firstLogin: eventDate, // this is both the earliest and latest login time
          latestLogin: eventDate, // this is both the earliest and latest login time
          totalLogins: 1,
        };
      } else if (eventDate < previous.firstLogin) {
        // Update tracked event to new first event
        acc[userEmail] = {
          ...event,
          firstLogin: eventDate, // this is the new earliest login
          latestLogin: previous.latestLogin, // keep track of previous latest login time
          totalLogins: previous.totalLogins + 1,
        };
      } else if (previous.latestLogin < eventDate) {
        // Update latest login time only
        acc[userEmail] = {
          ...previous,
          latestLogin: eventDate, // update latest login
          totalLogins: previous.totalLogins + 1,
        };
      }
      return acc;
    },
    {},
  );

  return Object.values(record);
}
