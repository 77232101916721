import { Grid, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ButtonDefault from '../components/base/ButtonDefault';
import PATHS from '../constants/pathGenerators';
import UnauthorizedPage from '../containers/UnauthorizedPage';
import { NAuth } from '../services/transportTypes/NAuth';
import userService from '../services/userService';
import { useAppDispatch } from '../store/store';
import { twoFactorVerified } from '../store/userSlice';
import useAlert from '../utilities/hooks/useAlert';

const TwoFactorLogin = (): JSX.Element => {
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useAppDispatch();

  const twoFactorVerify = useMutation({
    mutationKey: [userService.twoFactorVerify.name],
    mutationFn: userService.twoFactorVerify,
    onError: (error) => {
      console.error('Two factor authentication failed: ', error);
      alert({
        message: 'Authentication failed',
        severity: 'error',
      });
    },
    onSuccess: () => {
      dispatch(twoFactorVerified());
      alert({
        message: 'Authentication success',
        severity: 'success',
      });
      navigate(PATHS.HOME());
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: NAuth.Verify.Post.Request) => {
    twoFactorVerify.mutate(data);
  };

  return (
    <UnauthorizedPage>
      <Grid
        className="login-container"
        container
        direction="column"
        item
        xs={12}
      >
        <h1 className="login-container__title">
          Two-Factor
          <br />
          Authentication
        </h1>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Controller
              name="otp"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  label="Code"
                  placeholder="123123"
                  className="login-container__input"
                  error={!!errors.code}
                  {...field}
                />
              )}
            />
          </div>
          <div className="login-container__button">
            <ButtonDefault
              onClick={handleSubmit(onSubmit)}
              isLoading={twoFactorVerify.isLoading}
            >
              Verify Code
            </ButtonDefault>
          </div>
        </form>
      </Grid>
    </UnauthorizedPage>
  );
};

export default TwoFactorLogin;
