import { useQuery } from '@tanstack/react-query';
import analyticsService from '../../services/analyticsService';
import {
  AnalyticsRecord,
  NAnalytics,
} from '../../services/transportTypes/NAnalytics';

export default function useAnalyticsData<Record extends AnalyticsRecord>(
  projectSlug: string | undefined,
  nameOrFilters: Record['name'] | NAnalytics.Get.Params,
): [Record[], boolean] {
  let params: NAnalytics.Get.Params;
  if (typeof nameOrFilters === 'string') {
    params = {
      name: nameOrFilters,
    };
  } else {
    params = nameOrFilters;
  }

  // Fetches grouped data via reactQuery from the API
  const { data, isLoading, isPaused } = useQuery(
    ['getAnalyticsData', { projectSlug, params }],
    () =>
      analyticsService.getAnalyticsData<Record>({
        projectSlug: projectSlug!,
        params,
      }),
    { enabled: !!projectSlug },
  );

  return [data?.results ?? [], isLoading || isPaused];
}
