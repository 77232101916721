import { IActivityLog, IPermission, IProject, KeysOf } from './BaseTypes';
import { IProjectSummary } from './NProject';
import { IRole } from './NRole';

export interface IAdminUser<DateType extends string | Date = Date> {
  id: number;
  email: string;
  name: string;
  reset_password_token: string | null;
  reset_password_expires: DateType | null;
  two_factor_mode: TTwoFactorMode;
  two_factor_state: TTwoFactorState;
  created_at: DateType;
  updated_at: DateType;
}

export const AdminUserResourceIds = {
  base(id: IAdminUser['id']) {
    return ['user', id].join('.');
  },
  field(id: IAdminUser['id'], field: keyof IAdminUser | 'password' | 'two_factor_secret') {
    return [this.base(id), field].join('.');
  },
}

export type IAdminUserSummary = Pick<IAdminUser, 'id' | 'name'>;

export const TwoFactorMode = {
  Disabled: null,
  // Email: 'email',
  App: 'app',
} as const;

export type TTwoFactorMode = KeysOf<typeof TwoFactorMode>;

export const TwoFactorState = {
  Disabled: null,
  Pending: 'pending',
  Active: 'active',
} as const;
export type TTwoFactorState = KeysOf<typeof TwoFactorState>;

export interface ILoginHistory<DateType extends Date | string = Date> {
  id: number;
  user_id: number;
  email: string;
  full_ua?: string;
  name?: string;
  version?: string;
  fullname?: string;
  os?: string;
  device_type?: string;
  remote_address?: string;
  created_at: DateType;
  updated_at: DateType;
}

export namespace NAdminUser {
  export namespace Get {
    export type Request = null;
    export type Response = IAdminUserSummary[];
    export type Params = null;
  }

  export namespace Post {
    export type Request = {
      email: string;
      name: string;
      projects?: (Pick<IProject, 'id'> & {
        roles: Pick<IRole, 'id'>[];
      })[];
    };
    export type Response<DateType extends string | Date = Date> =
      IAdminUser<DateType> & {
        projects: IProject[];
        roles: IRole[];
      };
    export type Params = null;
  }

  export namespace Self {
    export namespace Get {
      export type Request = null;
      export type Response<DateType extends string | Date = Date> =
        IAdminUser<DateType>;
      export type Params = null;
    }

    export namespace Put {
      export type Request =
        | {
            email?: string;
            name: string;
          }
        | {
            email: string;
            name?: string;
          };
      export type Response<DateType extends string | Date = Date> =
        IAdminUser<DateType>;
      export type Params = null;
    }

    export namespace TwoFactorAuth {
      export namespace Put {
        export type Request = {
          mode: TTwoFactorMode;
        };
        export type Response = {
          two_factor_mode: TTwoFactorMode;
          two_factor_state: TTwoFactorState;
          qr?: string;
        };
        export type Params = null;
      }

      export namespace Verify {
        export namespace Post {
          export type Request = {
            otp: string;
          };
          export type Response = {
            success: true;
            two_factor_state: typeof TwoFactorState.Active;
          };
          export type Params = null;
        }
      }
    }

    export namespace History {
      export namespace Activity {
        export namespace Get {
          export type Request = null;
          export type Response<DateType extends Date | string = Date> =
            IActivityLog<DateType>[];
          export type Params = null;
        }
      }

      export namespace Login {
        export namespace Get {
          export type Request = null;
          export type Response<DateType extends Date | string = Date> =
            ILoginHistory<DateType>[];
          export type Params = null;
        }
      }
    }

    export namespace Permission {
      export namespace Get {
        export type Request = null;
        export type Response = IPermission[];
        export type Params = null;
      }
    }

    export namespace Role {
      export namespace Get {
        export type Request = null;
        export type Response = IRole[];
        export type Params = null;
      }
    }
  }

  export namespace UserId {
    export namespace Get {
      export type Request = null;
      export type Response<DateType extends string | Date = Date> =
        IAdminUser<DateType>;
      export type Params = null;
    }

    export namespace Put {
      export type Request = {
        email?: string;
        name?: string;
        two_factor_mode?: null;
        projects?: (Pick<IProject, 'id'> & {
          roles: Pick<IRole, 'id'>[];
        })[];
      };
      export type Response<DateType extends string | Date = Date> =
        IAdminUser<DateType> & {
          projects: IProject[];
          roles: IRole[];
        };
      export type Params = null;
    }

    export namespace Delete {
      export type Request = null;
      export type Response = { success: boolean };
      export type Params = null;
    }

    export namespace Project {
      export namespace Get {
        export type Request = null;
        export type Response = IProjectSummary[];
        export type Params = null;
      }

      export namespace Role {
        export namespace Get {
          export type Request = null;
          export type Response = IRole[];
          export type Params = null;
        }
      }
    }

    export namespace History {
      export namespace Activity {
        export namespace Get {
          export type Request = null;
          export type Response<DateType extends Date | string = Date> =
            IActivityLog<DateType>[];
          export type Params = null;
        }
      }

      export namespace Login {
        export namespace Get {
          export type Request = null;
          export type Response<DateType extends Date | string = Date> =
            ILoginHistory<DateType>[];
          export type Params = null;
        }
      }
    }
  }
}
