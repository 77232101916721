import { useQueries } from '@tanstack/react-query';
import { IAdminUserSummary } from '../../services/transportTypes/NAdminUser';
import { IRole } from '../../services/transportTypes/NRole';
import userService from '../../services/userService';

export default function (
  roles: IRole[] = [],
): [
  { roleId: IRole['id']; users: IAdminUserSummary[]; isLoading: boolean }[],
  boolean,
] {
  const results = useQueries({
    queries: roles.map((role) => ({
      queryKey: [
        userService.getRoleUsers.name,
        { project_id: role.project_id, roleId: role.id },
      ],
      queryFn: () => userService.getRoleUsers(role),
    })),
  });

  return [
    roles.map((role, index) => ({
      roleId: role.id,
      users: results[index].data ?? [],
      isLoading: results[index].isLoading,
    })),
    results.some((r) => r.isLoading),
  ];
}
