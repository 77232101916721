import { parseISO } from 'date-fns';
import API from '../constants/apiConstants';
import addAuthHeader from '../utilities/auth';
import { DefaultRole, IFormData } from '../utilities/backendTypes';
import { ProjectSlugOrId } from '../utilities/frontendTypes';
import handleResponse from '../utilities/handleResponse';
import { IActivityLog, IProject } from './transportTypes/BaseTypes';
import { NProject } from './transportTypes/NProject';

const apiUrl = process.env.REACT_APP_API;

function listProjects(): Promise<NProject.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}`,
    requestOptions,
  ).then<NProject.Get.Response>(handleResponse);
}

function getProject(slugOrId: IProject['slug'] | IProject['id']) {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${slugOrId}`,
    requestOptions,
  ).then<NProject.Project.Get.Response>(handleResponse);
}

interface addProjectArgs extends Omit<IProject, 'id' | 'assigned' | 'deleted'> {
  services: number[];
  roles: DefaultRole[];
}

function addProject(project: addProjectArgs): Promise<NProject.Post.Response> {
  const requestOptions = {
    method: 'POST',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(project),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}`,
    requestOptions,
  ).then<NProject.Post.Response>(handleResponse);
}

interface updateProjectArgs {
  idOrSlug: IProject['slug'] | IProject['id'];
  body: NProject.Project.Put.Request;
}

function updateProject({
  idOrSlug,
  body,
}: updateProjectArgs): Promise<NProject.Project.Put.Response> {
  const requestOptions = {
    method: 'PUT',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${idOrSlug}`,
    requestOptions,
  ).then<NProject.Project.Put.Response>(handleResponse);
}

function deleteProject(
  idOrSlug: IProject['slug'] | IProject['id'],
): Promise<NProject.Project.Delete.Response> {
  const requestOptions = {
    method: 'DELETE',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${idOrSlug}`,
    requestOptions,
  ).then<NProject.Project.Delete.Response>(handleResponse);
}

interface getEmailIntArgs {
  projectSlug: string;
  serviceId: number;
}

function getEmailIntegrationData({
  projectSlug,
  serviceId,
}: getEmailIntArgs): Promise<IFormData> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };

  return fetch(
    `${apiUrl}${API.PROJECT}/${projectSlug}/${serviceId}/form/`,
    requestOptions,
  ).then<IFormData>(handleResponse);
}

function listProjectActivity(
  slugOrId: ProjectSlugOrId,
): Promise<NProject.Project.History.Activity.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };

  return fetch(
    `${apiUrl}${API.PROJECT}/${slugOrId}/history/activity`,
    requestOptions,
  )
    .then<NProject.Project.History.Activity.Get.Response<string>>(
      handleResponse,
    )
    .then((records) =>
      records.map<IActivityLog>((r) => ({
        ...r,
        created_at: parseISO(r.created_at),
      })),
    );
}

const projectService = {
  listProjects,
  getProject,
  addProject,
  updateProject,
  deleteProject,
  getEmailIntegrationData,
  listProjectActivity,
};

export default projectService;
