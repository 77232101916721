import {
  Alert,
  AlertColor,
  Snackbar,
  SnackbarCloseReason,
  SnackbarProps,
} from '@mui/material';
import React from 'react';

export interface CustomSnackbarProps
  extends Omit<
    SnackbarProps,
    'message' | 'anchorOrigin' | 'autoHideDuration' | 'onClose'
  > {
  severity: AlertColor;
  message: string;
  onClose?: (reason: SnackbarCloseReason | 'alert') => void;
  onExited?: () => void;
}
export default function CustomSnackbar(props: CustomSnackbarProps) {
  const { severity, message, onClose, onExited, ...snackbarProps } = props;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      autoHideDuration={4000}
      TransitionProps={{
        onExited,
      }}
      onClose={(_, reason) => onClose?.(reason)}
      {...snackbarProps}
    >
      <Alert severity={severity} onClose={() => onClose?.('alert')}>
        {message}
      </Alert>
    </Snackbar>
  );
}
