import React from 'react';
import { Grid, Skeleton } from '@mui/material';
import ServiceCard, { ServiceCardProps } from './ServiceCard';

export interface ManualServiceCardListProps {
  services: ServiceCardProps[];
  isLoading?: boolean
}

export const ManualServiceCardList = (
  props: ManualServiceCardListProps,
): JSX.Element => {
  const { services, isLoading } = props;

  return (
    <Grid item container spacing={2} direction="row">
      { isLoading ?
        Array(5)
          .fill(0)
          .map((_, id) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item key={id}>
              <Skeleton
                variant="rectangular"
                width={150}
                height={150}
                sx={{
                  borderRadius: 1,
                }}
              />
            </Grid>
          ))
        :
        services.map((s) => (
          <Grid item key={s.name}>
            <ServiceCard {...s} />
          </Grid>
        ))}
    </Grid>
  );
};

export default ManualServiceCardList;
