import React from 'react';
import { Grid } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ButtonDefault from '../base/ButtonDefault';
import ReviewPopup from './ReviewPopup';
import ReviewChanges from './ReviewChanges';
import { IReviewChange } from '../../utilities/backendTypes';

interface ReviewSummaryProps {
  approveState: boolean;
  rejectState: boolean;
  title: string;
  user: string;
  dateStr: string;
  changeType: string;
  changes: IReviewChange[];
  handleYes: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleNo: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleApprove: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleReject: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ReviewSummary = (props: ReviewSummaryProps): JSX.Element => {
  const {
    title,
    user,
    changes,
    dateStr,
    changeType,
    handleYes,
    handleNo,
    handleApprove,
    handleReject,
    rejectState,
    approveState,
  } = props;

  return (
    <Grid container justifyContent="space-between" alignItems="center" className="review-summary-wrapper">
      <Grid container className="review-summary">
        <Grid
          container
          item
          xs={12}
          direction="row"
          alignItems="center"
          className="review-summary__row"
        >
          <Grid container item xs={6} direction="row" alignItems="center">
            <p className="title">{title}</p>
          </Grid>
          <Grid container item xs={6} direction="row" alignItems="center" justifyContent="flex-end">
            <ButtonDefault disabled className="needs-review">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className="needs-review__label"
              >
                <p>NEEDS REVIEW</p>
              </Grid>
            </ButtonDefault>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          direction="row"
          alignItems="center"
          className="review-summary__row"
        >
          <Grid container item xs={6} direction="row" alignItems="center">
            <div className="round-icon">
              <AccountCircleIcon />
            </div>
            <p>{user}</p>
          </Grid>
          <Grid container item xs={6} direction="row" alignItems="center" justifyContent="flex-end">
            <div className="round-icon">
              <CalendarTodayIcon />
            </div>
            <p>{dateStr}</p>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          direction="row"
          alignItems="center"
          className="review-summary__row"
        >
          <ReviewChanges changes={changes} changeType={changeType} />
        </Grid>
        <Grid
          container
          item
          xs={12}
          direction="row"
          justifyContent="flex-end"
          className="review-summary__buttons-row"
        >
          <ButtonDefault onClick={handleApprove} className="approve-btn">
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className="approve-btn__label"
            >
              <CheckCircleOutlineIcon />
              <p>APPROVE</p>
            </Grid>
          </ButtonDefault>
          <ButtonDefault onClick={handleReject} className="reject-button">
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className="approve-btn__label"
            >
              <CancelOutlinedIcon />
              <p>REJECT</p>
            </Grid>
          </ButtonDefault>
        </Grid>
      </Grid>
      {(approveState || rejectState) && (
        <ReviewPopup
          approveState={approveState}
          rejectState={rejectState}
          handleYes={handleYes}
          handleNo={handleNo}
        />
      )}
    </Grid>
  );
};
export default ReviewSummary;
