import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IAdminUserSummary } from '../../services/transportTypes/NAdminUser';
import ButtonDefault from '../base/ButtonDefault';
import RedCheckbox from '../base/RedCheckbox';

interface UserSelectProps {
  name: string;
  selected: boolean;
  onChange: () => void;
}
const UserSelect = (props: UserSelectProps): JSX.Element => {
  const { name, selected, onChange } = props;

  return (
    <div>
      <RedCheckbox checked={selected} onChange={onChange} />
      {name}
    </div>
  );
};

export interface ManageUsersOverlayProps {
  open?: boolean;
  roleName?: string;
  allUsers: IAdminUserSummary[];
  includedUsers: IAdminUserSummary[];
  onClose?: (event: object, reason: string) => void;
  onSave?: (included: IAdminUserSummary[]) => void;
}

const ManageUsersOverlay = (props: ManageUsersOverlayProps): JSX.Element => {
  const {
    open = true,
    roleName,
    includedUsers,
    allUsers,
    onClose,
    onSave,
  } = props;

  const [selected, setSelected] = useState(includedUsers);

  useEffect(() => {
    setSelected(includedUsers);
  }, [includedUsers]);

  const handleSave = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onSave) {
      onSave(selected);
    }

    if (onClose) {
      onClose(e, 'save');
    }
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClose) {
      onClose(e, 'cancel');
    }
  };

  const toggleUser = (user: IAdminUserSummary) => () => {
    if (selected.some((u) => u.id === user.id)) {
      setSelected(selected.filter((u) => u.id !== user.id));
    } else {
      setSelected([...selected, user]);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{roleName} Users</DialogTitle>
      <DialogContent>
        {allUsers.map((user) => (
          <UserSelect
            key={user.id}
            selected={selected.some((u) => u.id === user.id)}
            onChange={toggleUser(user)}
            name={user.name}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <ButtonDefault className="btn-medium btn-cancel" onClick={handleCancel}>
          Cancel
        </ButtonDefault>
        <ButtonDefault className="btn-medium btn-save" onClick={handleSave}>
          Save
        </ButtonDefault>
      </DialogActions>
    </Dialog>
  );
};

export default ManageUsersOverlay;
