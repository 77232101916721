import { Grid } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonDefault from '../components/base/ButtonDefault';
import PageHeader from '../components/base/PageHeader';
import { BodyPanel } from '../components/base/Panel';
import LoginHistory from '../components/user/LoginHistory';
import ProjectsAndRoles from '../components/user/ProjectsAndRoles';
import ResetPasswordButton from '../components/user/ResetPasswordButton';
import TwoFactorStateButtons from '../components/user/TwoFactorStateButtons';
import UserPropertiesEditor, {
  UserPropertiesDictionary,
} from '../components/user/UserPropertiesEditor';
import MESSAGES from '../constants/messagesConstants';
import PathGenerators from '../constants/pathGenerators';
import AuthorizedPage from '../containers/AuthorizedPage';
import userService from '../services/userService';
import useAlert from '../utilities/hooks/useAlert';
import useProjectList from '../utilities/hooks/useProjectList';
import useSelf from '../utilities/hooks/useSelf';
import useSelfLoginHistory from '../utilities/hooks/useSelfLoginHistory';
import useSelfRoleList from '../utilities/hooks/useSelfRoleList';

const MyAccount = (): JSX.Element => {
  const navigate = useNavigate();
  const alert = useAlert();

  const [user, userLoading, invalidateSelf] = useSelf();
  const [userRoles, rolesLoading] = useSelfRoleList();
  const [projects, projectsLoading] = useProjectList();
  const [loginHistory, historyLoading] = useSelfLoginHistory();

  const [userProperties, setUserProperties] =
    useState<UserPropertiesDictionary>({
      name: '',
      email: '',
      two_factor_state: null,
    });
  const [fieldErrors, setFieldErrors] = useState<string[]>([]);

  const roles = userRoles.map((projectRole) => ({
    ...projectRole,
    assigned: true,
  }));

  const updateUserMutation = useMutation({
    mutationFn: userService.updateUser,
    mutationKey: [userService.updateUser.name],
    onSuccess: () => {
      alert({
        message: MESSAGES.USER_SAVED,
        severity: 'success',
      });
      Promise.all([invalidateSelf()]).catch((err) => console.error(err));
    },
    onError: (error: Error) => {
      console.error('updateUser error', { error });
      alert({
        message: error.message,
        severity: 'error',
      });
    },
  });

  const handlePropertyChange = (props: UserPropertiesDictionary) => {
    setUserProperties(props);
  };

  const handleViewHistory = () => {
    if (user) {
      navigate(PathGenerators.LOGIN_HISTORY(user.id.toString()));
    }
  };

  const handleSave = () => {
    const errors: string[] = [];

    if (!userProperties.name) {
      errors.push('name');
    }
    if (!userProperties.email) {
      errors.push('email');
    }

    if (user && errors.length === 0) {
      updateUserMutation.mutate(
        {
          id: user.id,
          name: userProperties.name,
          email: userProperties.email,
        },
        {},
      );
    }
    setFieldErrors(errors);
  };

  const handleInvalidate = () => {
    invalidateSelf().catch((err) => {
      console.error(err);
      alert({
        message: 'Something went wrong',
        severity: 'error',
      });
    });
  };

  // Update properties when user loads
  useEffect(() => {
    if (user) {
      // eslint-disable-next-line camelcase
      const { name, email, two_factor_state } = user;
      // eslint-disable-next-line camelcase
      setUserProperties({ name, email, two_factor_state });
    }
  }, [user]);

  return (
    <AuthorizedPage>
      <PageHeader title="My Account" hintMessage="Edit your details." />
      <BodyPanel>
        <Grid
          container
          spacing={3}
          alignItems="stretch"
          alignContent="space-between"
        >
          <Grid item xs={3}>
            <UserPropertiesEditor
              values={userProperties}
              errors={fieldErrors}
              onChange={handlePropertyChange}
              isLoading={userLoading}
            >
              <ResetPasswordButton user={user} self />
              <TwoFactorStateButtons
                user={user}
                onEnable2fa={handleInvalidate}
                onDisable2fa={handleInvalidate}
                self
              />
            </UserPropertiesEditor>
          </Grid>
          <Grid item xs={5}>
            <ProjectsAndRoles
              disabled
              projects={projects}
              roles={roles}
              isLoading={userLoading || projectsLoading || rolesLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <LoginHistory
              history={loginHistory}
              isLoading={historyLoading}
              onViewMore={handleViewHistory}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <ButtonDefault onClick={handleSave} disabled={userLoading}>
              Save
            </ButtonDefault>
          </Grid>
        </Grid>
      </BodyPanel>
    </AuthorizedPage>
  );
};

export default MyAccount;
