import { Box, Grid } from '@mui/material';
import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import BrowserTable from '../components/analytics/widgets/BrowserTable';
import LocationTable from '../components/analytics/widgets/LocationTable';
import HeaderComponent from '../components/base/HeaderComponent';
import PageHeader from '../components/base/PageHeader';
import ServiceCardList from '../components/home/ServiceCardList';
import ProjectActivityLog from '../components/project/ProjectActivityLog';
import PATHS from '../constants/pathGenerators';
import AuthorizedPage from '../containers/AuthorizedPage';
import { PermissionNames } from '../services/transportTypes/BaseTypes';
import useBrowserData from '../utilities/hooks/useBrowserData';
import useLoginData from '../utilities/hooks/useLoginData';
import useProject from '../utilities/hooks/useProject';
import useSelfPermissionsList from '../utilities/hooks/useSelfPermissionsList';
import {
  isAdminPermitted,
  showAnalyticsControls,
} from '../utilities/permissionChecks';

const ProjectHome = (): JSX.Element => {
  const navigate = useNavigate();
  const [currentProject, projectLoading] = useProject();
  const [permissions, permissionsLoading] = useSelfPermissionsList();

  if (!currentProject && !projectLoading) {
    return <Navigate to={PATHS.PROJECTS()} />;
  }

  const projectSlug = currentProject?.slug;

  const [loginData, loginDataLoading] = useLoginData(projectSlug);
  const [browserListData, browserDataLoading] = useBrowserData(projectSlug);

  const handleViewMore = () => {
    if (projectSlug) {
      navigate(PATHS.ANALYTICS_BROWSER_TYPES(projectSlug));
    }
  };

  const aUser = isAdminPermitted(
    permissions,
    PermissionNames.AdminUser,
    'read',
  );
  const showAnalytics = showAnalyticsControls(currentProject, permissions);

  return (
    <AuthorizedPage>
      <PageHeader
        title={`${currentProject?.name ?? 'Loading...'}`}
        hintMessage="Here is a summary of your project."
        displayEdit={aUser}
        handleEdit={() => navigate(PATHS.PROJECTS_EDIT(projectSlug))}
      />
      <Box className="dashboard-body">
        <Grid container spacing={2}>
          {/* services */}
          <Grid container item md={12} lg={8} spacing={2}>
            <Grid item xs={12} className="service-title">
              <HeaderComponent title="Services" />
            </Grid>
            <Grid item xs={12} alignItems="stretch">
              <ServiceCardList project={currentProject} />
            </Grid>
            {showAnalytics && (
              <>
                <Grid item md={12} lg={6}>
                  <LocationTable
                    data={loginData.location}
                    isLoading={loginDataLoading}
                    sx={{ height: '100%', maxHeight: 320 }}
                  />
                </Grid>
                <Grid item md={12} lg={6}>
                  <BrowserTable
                    data={browserListData}
                    onClick={handleViewMore}
                    isLoading={browserDataLoading}
                    sx={{ height: '100%', maxHeight: 320 }}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid item md={12} lg={4}>
            <ProjectActivityLog
              project={currentProject}
              onViewMore={() =>
                navigate(PATHS.ACTIVITY_LOG(currentProject?.slug))
              }
            />
          </Grid>
        </Grid>
      </Box>
    </AuthorizedPage>
  );
};

export default ProjectHome;
