import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Checkbox, CheckboxProps } from '@mui/material';
import DESIGN from '../../constants/designConstants';

const RedCheckbox = withStyles({
  root: {
    '&$checked': {
      color: DESIGN.COLOR_RED,
    },
    padding: '5px',
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export default RedCheckbox;
