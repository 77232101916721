import { useEffect, useState } from 'react';
import { IOptionsFilter } from '../../../constants/analyticsConstants';
import { FilterSelectProps } from '../FilterSelect';

export default function (
  options: IOptionsFilter[],
  def: IOptionsFilter | null,
): FilterSelectProps {
  const [filterId, setFilterId] = useState(def?.id);
  const selected = options.find((opt) => opt.id === filterId) ?? null;

  const handleChange = (s: IOptionsFilter | null) => {
    setFilterId(s?.id ?? def?.id);
  };

  useEffect(() => {
    if (!options.some((opt) => opt.id === selected?.id)) {
      setFilterId(def?.id);
    }
  }, [selected, options]);

  return {
    selected,
    options,
    onChange: handleChange,
  };
}
