import React, { ReactNode } from 'react';
import { Container, Grid } from '@mui/material';
import { Navigate, useLocation } from 'react-router-dom';
import SideNav from '../components/SideNav';
import { useAppSelector } from '../store/store';
import PATHS from '../constants/pathGenerators';

export interface AuthorizedPageProps {
  children?: ReactNode;
}

const AuthorizedPage = (props: AuthorizedPageProps): JSX.Element => {
  const { children } = props;
  const location = useLocation();

  const { token, isTokenValid } = useAppSelector((state) => state.user);

  if (token && !isTokenValid) {
    // Don't show anything while token is being validated
    return <div />;
  }

  if (!token) {
    // No token, return to login
    return <Navigate to={PATHS.LOGIN()} state={{ from: location }} />;
  }

  return (
    <Grid className="dashboard-container">
      <SideNav />
      <Grid className="dashboard-body-container">
        <Container>
          <div>{children}</div>
        </Container>
      </Grid>
    </Grid>
  );
};

export default AuthorizedPage;
