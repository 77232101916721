import {
  DocumentDataField,
  DocumentSchemaField,
} from '../services/transportTypes/NCms';

const graphQLNameValidator = new RegExp(/^[a-zA-Z][a-zA-Z0-9_]*$/);
export function isValidGraphQLName(name: string): boolean {
  return graphQLNameValidator.test(name);
}

export function validateFieldValue(
  value: DocumentDataField,
  definition: DocumentSchemaField,
): boolean {
  if (value === null) {
    return true;
  }

  switch (definition.type) {
    case 'boolean':
      return typeof value === 'boolean';
    case 'int':
      return typeof value === 'number' && Number.isInteger(value);
    case 'float':
      return typeof value === 'number';
    case 'string':
      return typeof value === 'string';
    default:
      console.error(`Unknown field type [${definition.type}]`);
      return false;
  }
}
