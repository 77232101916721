import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SearchField from './controlled/SearchField';
import queryConstants from '../../constants/queryConstants';

export interface SearchFieldProps {}

export default function UrlSearchField(props: SearchFieldProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchValue = searchParams.get(queryConstants.SEARCH);

  const [text, setText] = useState(searchValue);
  const [expanded, setExpanded] = useState(!!searchValue);

  const handleChange = (change: string | null) => {
    setText(change);
    if (change === null) {
      setExpanded(false);
      searchParams.delete(queryConstants.SEARCH);
      setSearchParams(searchParams);
    }
  };

  const handleSearch = () => {
    setExpanded(true);
    if (text === null) {
      searchParams.delete(queryConstants.SEARCH);
    } else {
      searchParams.set(queryConstants.SEARCH, text);
    }
    setSearchParams(searchParams);
  };

  // Reflect changes in param into searchbox
  useEffect(() => {
    setText(searchValue);
  }, [searchValue]);

  return (
    <SearchField
      text={text}
      expanded={expanded}
      onChange={handleChange}
      onSearch={handleSearch}
    />
  );
}
