import { useQuery } from '@tanstack/react-query';
import {
  IAdminUser,
  ILoginHistory,
} from '../../services/transportTypes/NAdminUser';
import userService from '../../services/userService';

export default function (
  userId: IAdminUser['id'] | null,
): [ILoginHistory[], boolean] {
  const { data, isLoading, isPaused } = useQuery({
    queryKey: ['getUserLoginHistory', { id: userId }],
    queryFn: () => userService.getUserLoginHistory(userId!),
    enabled: !!userId,
  });

  return [data ?? [], isLoading || isPaused];
}
