import { IProject } from './BaseTypes';

export const AllDocumentFieldTypes = [
  'boolean',
  'int',
  'float',
  'string',
] as const;
export type DocumentFieldType = typeof AllDocumentFieldTypes[number];

export type DocumentDataField = boolean | number | string | null;

export interface DocumentSchemaField<DateType extends Date | string = Date> {
  id: number;
  name: string;
  type: DocumentFieldType;
  sequence: number;
  properties: Record<string, never> | null; // DocumentSchemaItemProperties | null;
  created_at: DateType;
  updated_at: DateType | null;
}

export type DocumentSchemaFieldDefinition = Pick<
  DocumentSchemaField,
  'name' | 'type' | 'sequence' | 'properties'
>;

export type DocumentSchemaFieldUpdate = Pick<DocumentSchemaField, 'id'> &
  Partial<DocumentSchemaFieldDefinition>;

export interface IDocumentSchema<DateType extends Date | string = Date> {
  id: number;
  project_id: number;
  name: string;
  deleted: boolean;
  fields: DocumentSchemaField<DateType>[];
  created_by: number;
  created_at: DateType;
  edited_by: number | null;
  updated_at: DateType | null;
}

export interface IDocument<DateType extends Date | string = Date> {
  id: number;
  schema_id: number;
  name: string;
  data: Record<string, DocumentDataField>;
  sequence: number;
  deleted: boolean;
  created_by: number;
  created_at: DateType;
  edited_by: number | null;
  updated_at: DateType | null;
}

/**
 * Generators for resource id's used to identify fields in Activity Log
 */
export const DocumentSchemaResourceIds = {
  base(projectId: IProject['id'], id: IDocumentSchema['id']) {
    return ['project', projectId, 'doc-schema', id].join('.');
  },
  objectFieldValue(
    projectId: IProject['id'],
    id: IDocumentSchema['id'],
    field: 'name' | 'deleted',
  ) {
    return [this.base(projectId, id), field].join('.');
  },
  schemaField(
    projectId: IProject['id'],
    id: IDocumentSchema['id'],
    fieldId: DocumentSchemaField['id'],
  ) {
    return [this.base(projectId, id), 'fields', fieldId].join('.');
  },
  schemaFieldValue(
    projectId: IProject['id'],
    id: IDocumentSchema['id'],
    fieldId: DocumentSchemaField['id'],
    field: keyof DocumentSchemaFieldDefinition,
  ) {
    return [this.schemaField(projectId, id, fieldId), field].join('.');
  },
};

/**
 * Generators for resource id's used to identify fields in Activity Log
 */
export const DocumentResourceIds = {
  base(projectId: IProject['id'], id: IDocument['id']) {
    return ['project', projectId, 'document', id].join('.');
  },
  field(
    projectId: IProject['id'],
    id: IDocument['id'],
    field: keyof IDocument,
  ) {
    return [this.base(projectId, id), field].join('.');
  },
  dataField(projectId: IProject['id'], id: IDocument['id'], field: string) {
    return [this.base(projectId, id), 'data', field].join('.');
  },
};

export namespace NCms {
  export namespace Document {
    /**
     * List all documents in the project
     */
    export namespace Get {
      export type Request = null;
      export type Response<DateType extends Date | string = Date> =
        IDocument<DateType>[];
      export type Params = {
        includeDeleted: boolean | 'true' | 'false';
      };
    }

    /**
     * Create a document
     */
    export namespace Post {
      export type Request = Pick<
        IDocument,
        'schema_id' | 'name' | 'sequence' | 'data'
      >;
      export type Response<DateType extends Date | string = Date> =
        IDocument<DateType>;
      export type Params = null;
    }

    export namespace DocumentId {
      /**
       * Fetch a document
       */
      export namespace Get {
        export type Request = null;
        export type Response<DateType extends Date | string = Date> =
          IDocument<DateType>;
        export type Params = null;
      }

      /**
       * Update a document
       */
      export namespace Put {
        export type Request = Pick<IDocument, 'name' | 'sequence' | 'data'>;
        export type Response<DateType extends Date | string = Date> =
          IDocument<DateType>;
        export type Params = null;
      }

      /**
       * Delete a document
       */
      export namespace Delete {
        export type Request = null;
        export type Response = {
          message: string;
        };
        export type Params = null;
      }
    }
  }

  export namespace DocumentSchema {
    /**
     * List all Schemas in the project
     */
    export namespace Get {
      export type Request = null;
      export type Response<DateType extends Date | string = Date> =
        IDocumentSchema<DateType>[];
      export type Params = {
        includeDeleted: boolean | 'true' | 'false';
      };
    }

    /**
     * Create a new Schema
     */
    export namespace Post {
      export type Request = {
        name: string;
        fields: DocumentSchemaFieldDefinition[];
      };
      export type Response<DateType extends Date | string = Date> =
        IDocumentSchema<DateType>;
      export type Params = null;
    }

    export namespace SchemaId {
      /**
       * Fetch a schema
       */
      export namespace Get {
        export type Request = null;
        export type Response<DateType extends Date | string = Date> =
          IDocumentSchema<DateType>;
        export type Params = null;
      }

      /**
       * Update a Schema
       */
      export namespace Put {
        export type Request = {
          name?: string;
          fields: (DocumentSchemaFieldDefinition | DocumentSchemaFieldUpdate)[];
        };
        export type Response<DateType extends Date | string = Date> =
          IDocumentSchema<DateType>;
        export type Params = null;
      }

      /**
       * Delete a Schema
       */
      export namespace Delete {
        export type Request = null;
        export type Response = {
          message: string;
        };
        export type Params = null;
      }

      export namespace Document {
        /**
         * List all Documents in the Schema
         */
        export namespace Get {
          export type Request = null;
          export type Response<DateType extends Date | string = Date> =
            IDocument<DateType>[];
          export type Params = {
            includeDeleted: boolean | 'true' | 'false';
          };
        }
      }
    }
  }

  export namespace GraphQL {
    /**
     * Hosts GraphiQL UI for CMS testing
     */
    export namespace Get {
      export type Request = null;
      export type Response = string;
      export type Params = null;
    }

    /**
     * Read only GraphQL endpoint for Document access
     */
    export namespace Post {
      export type Request = {
        query: string;
        variables: Record<string, unknown>;
      };
      export type Response = unknown;
      export type Params = null;
    }
  }
}
