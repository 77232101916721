// Database record constants
const DB = {
  SERVICE_CMS: 'cms',
  SERVICE_ANALYTICS: 'analytics',
  SERVICE_REVIEW: 'review',
  SERVICE_ROLES: 'roles',
  SERVICE_USER: 'user',

  SERVICE_ID_CMS: 1,
  SERVICE_ID_ANALYTICS: 2,
  SERVICE_ID_REVIEW: 3,
  SERVICE_ID_ROLES: 4,

  ADMIN_PROJECT_ID: 1,

  REVIEW_STATUS_APPROVED: 'Approved',
  REVIEW_STATUS_REJECTED: 'Rejected',
  REVIEW_STATUS_PENDING: 'Pending',
};

export default DB;
