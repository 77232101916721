import { useQuery } from '@tanstack/react-query';
import { compareDesc } from 'date-fns';
import { useMemo } from 'react';
import projectService from '../../services/projectService';
import {
  IActivityLog,
  IProject,
} from '../../services/transportTypes/BaseTypes';
import { IAdminUser } from '../../services/transportTypes/NAdminUser';
import { ActivityArea, findActivityArea } from '../functions';
import useUserSummaryList from './useUserSummaryList';

export type ActivityLogRecord = Pick<
  IActivityLog,
  'id' | 'detail' | 'items' | 'created_at'
> & { area: ActivityArea; userName: IAdminUser['name'] };

/**
 * Fetches the Activity Log for a given project.
 * in the project object
 * @param project the project
 * @return {
 *   log: the activity log for the project,
 *   loading: whether requests are still loading
 * }
 */
export default (project?: IProject): [ActivityLogRecord[], boolean] => {
  const { data: activityRecords = [], isLoading: ActivityLoading } = useQuery({
    queryKey: [
      projectService.listProjectActivity.name,
      { slug: project?.slug },
    ],
    queryFn: () => projectService.listProjectActivity(project?.slug!),
    enabled: !!project,
  });
  const [users, usersLoading] = useUserSummaryList();

  const records = useMemo<ActivityLogRecord[]>(
    () =>
      activityRecords
        .sort((a, b) => compareDesc(a.created_at, b.created_at))
        .map((record) => ({
          ...record,
          userName: users.find((u) => u.id === record.user_id)?.name ?? '[Deleted]',
          area: findActivityArea(record.detail),
        })),
    [activityRecords, users],
  );

  return [records, ActivityLoading || usersLoading || project === undefined];
};
