import React from 'react';
import { Grid } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ButtonDefault from '../base/ButtonDefault';
import MESSAGES from '../../constants/messagesConstants';

interface ReviewPopupProps {
  approveState: boolean;
  rejectState: boolean;
  handleYes: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleNo: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ReviewPopup = (props: ReviewPopupProps): JSX.Element => {
  const { handleYes, handleNo, rejectState, approveState } = props;

  return (
    <div className="overlay">
      <div className="review-pop-up">
        <Grid container item xs={12} direction="row" alignItems="center">
          <h4>{approveState ? MESSAGES.APPROVE_CHANGE : MESSAGES.REJECT_CHANGE}</h4>
        </Grid>
        <Grid container item xs={12} direction="row" justifyContent="center">
          <ButtonDefault
            onClick={handleYes}
            className={approveState ? 'approve-btn no-margin' : 'reject-button no-margin'}
          >
            {approveState && (
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className="approve-btn__label"
              >
                <CheckCircleOutlineIcon />
                <p>APPROVE</p>
              </Grid>
            )}
            {rejectState && (
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className="approve-btn__label"
              >
                <CancelOutlinedIcon />
                <p>REJECT</p>
              </Grid>
            )}
          </ButtonDefault>
        </Grid>
        <Grid container item xs={12} direction="row" justifyContent="center">
          <ButtonDefault onClick={handleNo} className="btn-transparent">
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className="approve-btn__no-label"
            >
              <p>NO</p>
            </Grid>
          </ButtonDefault>
        </Grid>
      </div>
    </div>
  );
};
export default ReviewPopup;
