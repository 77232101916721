import React, { ChangeEvent, FormEvent, MouseEvent } from 'react';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Stack, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { Colours } from '../../../constants/designConstants';

export interface SearchFieldProps {
  text: string | null;
  expanded?: boolean;
  onChange?: (text: string | null) => void;
  onSearch?: () => void;
}

export default function SearchField(props: SearchFieldProps) {
  const { text, expanded, onChange, onSearch } = props;

  const handleClearClick = () => {
    if (onChange) {
      onChange(null);
    }
  };

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const handleSearch = (event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (onSearch) {
      onSearch();
    }
  };

  if (!text && !expanded) {
    return (
      <Tooltip title="Search">
        <IconButton
          aria-label="Search"
          size="large"
          sx={{
            boxShadow: ' 0px 2px 2px rgba(0, 0, 0, 0.25)',
            background: Colours.White.Pure,
          }}
          onClick={handleSearch}
        >
          <SearchIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <form onSubmit={handleSearch}>
      <Paper
        sx={{
          px: 2,
          py: 1,
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'stretch',
          alignItems: 'center',
          marginRight: '25px',
          borderRadius: '10px',
        }}
      >
        <TextField
          sx={{
            flex: 1,
          }}
          variant="standard"
          placeholder="Search"
          aria-label="search"
          onChange={handleInput}
          value={text}
        />
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClearClick}
          type="reset"
          size="large"
        >
          <CloseIcon />
        </IconButton>
        <IconButton
          aria-label="search"
          type="submit"
          size="large"
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    </form>
  );
}
