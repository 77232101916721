import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import PendingRequests from '../utilities/PendingRequests';
import userSlice from './userSlice';

const userPersistConfig = {
  key: 'user',
  storage,
  // isTokenValid is not persisted, so we always know to check for stale tokens on load.
  blacklist: ['isTokenValid'],
};

const appReducer = combineReducers({
  user: persistReducer(userPersistConfig, userSlice),
  pendingRequests: PendingRequests,
});

export default appReducer;
