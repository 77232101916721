import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import React from 'react';
import { VisuallyHidden } from '../../../DashboardTheme';
import { ColumnData, Order, RowData, useStyles } from '../TableDefault';


interface TableDefaultHeadProps<Data extends RowData> {
  isLoading?: boolean;
  numSelected: number;
  onSortChange: (by: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: keyof Data;
  rowCount: number;
  columns: ColumnData<Data>[];
  actionsColumn: boolean;
  isReadOnly?: boolean;
}

function TableDefaultHead<Data extends RowData>(
  props: TableDefaultHeadProps<Data>,
) {
  const {
    isLoading,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    columns,
    actionsColumn,
    isReadOnly,
    onSortChange,
  } = props;

  return (
    <TableHead>
      <TableRow>
        {!isReadOnly && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all rows' }}
              disabled={isLoading}
            />
          </TableCell>
        )}
        {columns.map((c) => (
          <TableCell
            key={c.id.toString()}
            align={c.type === 'number' ? 'right' : 'left'}
            padding={c.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === c.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === c.id}
              direction={orderBy === c.id ? order : 'asc'}
              onClick={() => onSortChange(c.id)}
            >
              <>
                {c.label ?? c.id}
                {orderBy === c.id ? (
                  <Box sx={VisuallyHidden} >
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </>
            </TableSortLabel>
          </TableCell>
        ))}
        {actionsColumn && <TableCell />}
      </TableRow>
    </TableHead>
  );
}

export default TableDefaultHead;
