import { Grid, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import ButtonDefault from '../components/base/ButtonDefault';
import CustomSnackbar from '../components/base/CustomSnackbar';
import PATHS from '../constants/pathGenerators';
import UnauthorizedPage from '../containers/UnauthorizedPage';
import userService from '../services/userService';
import { useAppDispatch } from '../store/store';

interface ForgotPasswordData {
  email: string;
}

const ForgotPassword = (): JSX.Element => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [displayMessage, setDisplayMessage] = React.useState(false);

  const forgotPassword = useMutation({
    mutationKey: [userService.forgotPassword.name],
    mutationFn: userService.forgotPassword,
    onSuccess: () => {
      setDisplayMessage(true);
      setTimeout(() => {
        navigate(PATHS.LOGIN());
      }, 2500);
    },
  });

  const onSubmit = (data: ForgotPasswordData) => {
    if (data && data.email) {
      forgotPassword.mutate(data);
    }
  };

  return (
    <UnauthorizedPage>
      <Grid
        className="login-container"
        container
        direction="column"
        item
        xs={12}
      >
        <Typography variant="title" sx={{ pb: 6 }}>
          Forgot password?
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                // eslint-disable-next-line no-useless-escape
                pattern:
                  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
              }}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  label="Email"
                  placeholder="example@example.co"
                  className="login-container__input"
                  error={!!errors.email}
                  {...field}
                />
              )}
            />
          </div>
          <div className="login-container__button">
            <ButtonDefault onClick={handleSubmit(onSubmit)} large>
              CONFIRM
            </ButtonDefault>
          </div>
          <div className="login-container__forgot-password">
            <Link to={PATHS.LOGIN()}>
              <Typography variant="body1">I remembered my password.</Typography>
            </Link>
          </div>
        </form>
      </Grid>
      <CustomSnackbar
        severity="success"
        message="Please check your email inbox"
        open={displayMessage}
      />
    </UnauthorizedPage>
  );
};

export default ForgotPassword;
