import React from 'react';
import { Grid } from '@mui/material';
import { IReviewChange, IVideoInfo } from '../../utilities/backendTypes';
import COMPONENT from '../../constants/componentConstants';

interface ReviewChangesProps {
  changes: IReviewChange[];
  changeType: string;
}

const ReviewChanges = (props: ReviewChangesProps): JSX.Element => {
  const { changes, changeType } = props;

  return (
    <Grid container item xs={12} direction="row" justifyContent="center" className="review-changes">
      {changes.map((change, index) => (
        <Grid
          key={change.id}
          container
          item
          xs={12}
          direction="row"
          className={index === 0 ? 'content before' : 'content after'}
        >
          <Grid item xs={2}>
            <p className="content__bold">{index === 0 ? 'Before' : 'After'}</p>
          </Grid>
          <Grid container direction="column" item xs={10}>
            {changeType === COMPONENT.REVIEW_VIDEO ? (
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <video
                    className="review-changes__video"
                    src={(change.content as IVideoInfo).url}
                  />
                </Grid>
                <Grid container item xs={3} direction="column" justifyContent="center">
                  <p className="content__bold">Size</p>
                  <p>{(change.content as IVideoInfo).size}</p>
                </Grid>
                <Grid container item xs={3} direction="column" justifyContent="center">
                  <p className="content__bold">Length</p>
                  <p>{(change.content as IVideoInfo).length}</p>
                </Grid>
              </Grid>
            ) : (
              <p className="content__text">{change.content.toString()}</p>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
export default ReviewChanges;
