import React, { ReactNode } from 'react';
import { Container, Grid } from '@mui/material';
import spacesLogo from '../assets/images/spaces-alt-logo 1.png';

export interface UnauthorizedPageProps {
  children?: ReactNode;
}

const UnauthorizedPage = (props: UnauthorizedPageProps): JSX.Element => {
  const { children } = props;

  return (
    <Grid className="dashboard-container">
      <div className="side-nav">
        <Grid container justifyContent="center" alignItems="center" className="side-nav__unauthorized">
          <img src={spacesLogo} alt="Spaces Interactive Logo" />
        </Grid>
      </div>
      <Grid className="dashboard-container__unauthorized">
        <Container>
          <div>{children}</div>
        </Container>
      </Grid>
    </Grid>
  );
};

export default UnauthorizedPage;
