import { useQuery } from '@tanstack/react-query';
import { IProject } from '../../services/transportTypes/BaseTypes';
import { IAdminUserSummary } from '../../services/transportTypes/NAdminUser';
import userService from '../../services/userService';

export default (project?: IProject): [IAdminUserSummary[], boolean] => {
  const { data, isLoading, isPaused } = useQuery(
    ['getProjectUsers', { slug: project?.slug }],
    {
      queryFn: () => userService.getProjectUsers(project?.slug!),
      enabled: !!project,
    },
  );

  return [data ?? [], isLoading || isPaused];
};
