import { Grid, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import ButtonDefault from '../components/base/ButtonDefault';
import HeaderComponent from '../components/base/HeaderComponent';
import PageHeader from '../components/base/PageHeader';
import AuthorizedPage from '../containers/AuthorizedPage';
import contactFormService from '../services/contactFormService';
import { IContactForm } from '../utilities/backendTypes';
import useProject from '../utilities/hooks/useProject';

export interface ContactFormManagementState {
  currentForm?: IContactForm;
}

const ContactFormManagement = (): JSX.Element => {
  const location = useLocation();
  const [currentProject] = useProject();

  const { currentForm } = location.state as ContactFormManagementState;

  const addContactForm = useMutation({
    mutationKey: [contactFormService.addContactForm.name, currentProject?.slug],
    mutationFn: contactFormService.addContactForm,
  });

  const updateContactForm = useMutation({
    mutationKey: [
      contactFormService.updateContactForm.name,
      currentProject?.slug,
    ],
    mutationFn: contactFormService.updateContactForm,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: IContactForm) => {
    if (data && currentForm && currentProject) {
      updateContactForm.mutate({
        project_slug: currentProject.slug,
        id: currentForm.id,
        field_data: {
          name: data.name,
          email_address: data.email_address,
          subject: data.subject,
        },
      });
    } else if (data && currentProject && !currentForm) {
      addContactForm.mutate({
        project_slug: currentProject.slug,
        field_data: {
          name: data.name,
          email_address: data.email_address,
          subject: data.subject,
        },
      });
    }
  };

  // useEffect(() => {
  //   if (currentForm && saveBtnClicked && message) {
  //     if (updatedContactForm) {
  //       setUpdateSuccessSnackbar(message!);
  //       setSaveBtnClicked(false);
  //     } else {
  //       setUpdateFailSnackbar(message!);
  //       setSaveBtnClicked(false);
  //     }
  //   } else if (!addContactForm.isLoading && saveBtnClicked && message) {
  //     if (newContactForm) {
  //       setAddSnackbar(message!);
  //       setSaveBtnClicked(false);
  //     } else {
  //       setAddSnackbarFail(message!);
  //       setSaveBtnClicked(false);
  //     }
  //   }
  // }, [
  //   updateContactForm.isLoading,
  //   addContactForm.isLoading,
  //   saveBtnClicked,
  //   message,
  // ]);

  return (
    <AuthorizedPage>
      <div>
        <PageHeader title="Contact Form Management" hintMessage="" />
        <Grid className="dashboard-body user-management">
          <Grid container spacing={3} alignItems="flex-start">
            <Grid container item xs={6} className="user-management__details">
              <Grid>
                <HeaderComponent title="Details" />
                <Grid container item xs={12}>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="form-container"
                  >
                    <div>
                      <Controller
                        name="name"
                        control={control}
                        defaultValue={currentForm ? currentForm.name : ''}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Name"
                            placeholder="Name"
                            className="text-field"
                            error={!!errors.name}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <Controller
                        name="email_address"
                        control={control}
                        defaultValue={
                          currentForm ? currentForm.email_address : ''
                        }
                        rules={{
                          required: true,
                          // eslint-disable-next-line no-useless-escape
                          pattern:
                            /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                        }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Email"
                            placeholder="example@example.co"
                            className="text-field"
                            error={!!errors.email_address}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <Controller
                        name="subject"
                        control={control}
                        defaultValue={currentForm ? currentForm.subject : ''}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Subject"
                            placeholder="Subject"
                            className="text-field"
                            error={!!errors.subject}
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </form>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              item
              xs={12}
              justifyContent="flex-end"
            >
              <div className="login-container__button">
                <ButtonDefault large onClick={handleSubmit(onSubmit)}>
                  Submit
                </ButtonDefault>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </AuthorizedPage>
  );
};

export default ContactFormManagement;
