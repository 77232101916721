import { useQuery } from '@tanstack/react-query';
import cmsService from '../../services/cmsService';
import { IProject } from '../../services/transportTypes/BaseTypes';
import { IDocument, IDocumentSchema } from '../../services/transportTypes/NCms';

export default function (
  project?: IProject,
  schema?: IDocumentSchema,
): [IDocument[], boolean] {
  const { data = [], isLoading } = useQuery({
    queryKey: [
      cmsService.listSchemaDocuments.name,
      {
        projectId: project?.slug,
        schemaId: schema?.id,
      },
    ],
    queryFn: () =>
      cmsService.listSchemaDocuments({
        projectId: project!.slug,
        schemaId: schema!.id,
      }),
    enabled: !!project && !!schema,
  });

  return [data, isLoading];
}
