import { Grid } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import React from 'react';
import PageHeader from '../components/base/PageHeader';
import TableDefault, { ColumnData } from '../components/base/TableDefault';
import AuthorizedPage from '../containers/AuthorizedPage';
import reviewService from '../services/reviewService';
import { IReview } from '../utilities/backendTypes';
import { Key } from '../utilities/frontendTypes';
import useProject from '../utilities/hooks/useProject';

const DataCapture = (): JSX.Element => {
  const [currentProject] = useProject();

  const { data = [], isLoading } = useQuery({
    queryFn: () => reviewService.getReviewChanges(currentProject!.id),
    queryKey: [reviewService.getReviewChanges.name, currentProject?.slug],
    enabled: !!currentProject,
  });

  const updateReviewChanges = useMutation({
    mutationKey: [reviewService.updateReviewChanges.name, currentProject?.slug],
    mutationFn: (r: IReview) => reviewService.updateReviewChanges(r),
  });

  const columns: ColumnData<IReview>[] = [
    {
      id: 'name',
      type: 'string',
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'field',
      type: 'string',
      disablePadding: false,
      label: 'Field',
    },
    {
      id: 'user',
      type: 'string',
      disablePadding: false,
      label: 'User',
    },
    {
      id: 'created_at',
      type: 'string',
      disablePadding: false,
      label: 'Time',
    },
  ];

  const handleRowSelect = (indexes: Key[]) => {};

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {};

  return (
    <AuthorizedPage>
      <PageHeader
        title="Data Capture"
        hintMessage="View and Download Data captured via forms."
      />
      <Grid container justifyContent="space-between" className="review">
        <Grid container item className="table" xs={12}>
          <Grid container item className="dashboard-body project-management">
            <TableDefault<IReview>
              columns={columns}
              rows={data}
              filters={[]}
              onRowSelect={handleRowSelect}
              onSelectAllClick={handleSelectAll}
              selectOnlyOneRow
              hideAddButton
              hideDeleteButton
              onMassDelete={(deleted) => {
                console.log(`DELETE ${deleted.map((u) => u)}`);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </AuthorizedPage>
  );
};

export default DataCapture;
