import { Delete, Edit } from '@mui/icons-material';
import { useMutation, useQueries } from '@tanstack/react-query';
import { isAfter } from 'date-fns';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AlertModal from '../components/base/AlertModal';
import PageHeader from '../components/base/PageHeader';
import TableDefault, { ColumnData } from '../components/base/TableDefault';
import PATHS from '../constants/pathGenerators';
import queryConstants from '../constants/queryConstants';
import AuthorizedPage from '../containers/AuthorizedPage';
import { PermissionNames } from '../services/transportTypes/BaseTypes';
import {
  IAdminUser,
  IAdminUserSummary,
  ILoginHistory,
} from '../services/transportTypes/NAdminUser';
import { IProjectSummary } from '../services/transportTypes/NProject';
import userService from '../services/userService';
import { formatDate } from '../utilities/functions';
import usePermission from '../utilities/hooks/usePermission';
import useUrlFilters from '../utilities/hooks/useUrlFilters';
import useUserSummaryList from '../utilities/hooks/useUserSummaryList';

type PossiblyLoadedUser =
  | (IAdminUser & { detailsLoading: false })
  | (Partial<IAdminUser> & { detailsLoading: true });
type PossiblyLoadedProjects = {
  projects: IProjectSummary[];
  projectsLoading: boolean;
};
type PossiblyLoadedLoginHistory = {
  loginHistory: ILoginHistory[];
  historyLoading: boolean;
};
type UserDetails = IAdminUserSummary &
  PossiblyLoadedUser &
  PossiblyLoadedProjects &
  PossiblyLoadedLoginHistory;

function useLoadUserDetails(): [UserDetails[], boolean, () => void] {
  const [users, usersLoading, invalidate] = useUserSummaryList();

  const userDetails = useQueries({
    queries: users.map(({ id }) => ({
      queryKey: ['getUser', { id }],
      queryFn: () => userService.getUser(id),
    })),
  });

  const userProjects = useQueries({
    queries: users.map(({ id }) => ({
      queryKey: ['getUserProjects', { id }],
      queryFn: () => userService.getUserProjects(id),
    })),
  });

  const userHistory = useQueries({
    queries: users.map(({ id }) => ({
      queryKey: ['getUserLoginHistory', { id }],
      queryFn: () => userService.getUserLoginHistory(id),
    })),
  });

  const result: UserDetails[] = users.map((user, index) => {
    const details = userDetails[index].data;
    const projects = userProjects[index].data;
    const loginHistory = userHistory[index].data;

    return {
      ...user,
      ...(details
        ? {
            ...details,
            detailsLoading: false,
          }
        : {
            detailsLoading: true,
          }),
      projects: projects ?? [],
      projectsLoading: !projects,
      loginHistory: loginHistory ?? [],
      historyLoading: !loginHistory,
    };
  });

  return [result, usersLoading, invalidate];
}

const columns: ColumnData<UserDetails>[] = [
  {
    id: 'name',
    type: 'string',
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'email',
    type: 'string',
    disablePadding: false,
    label: 'Email',
    isLoading: (user) => user.detailsLoading,
  },
  {
    id: 'projects',
    type: 'string',
    disablePadding: false,
    label: 'Projects',
    isLoading: (user) => user.projectsLoading,
    formatter: (projects: IProjectSummary[]) =>
      projects.map((p) => p.name).join(', '),
  },
  {
    id: 'loginHistory',
    type: 'date',
    disablePadding: false,
    label: 'Last Login',
    isLoading: (user) => user.historyLoading,
    formatter: (history: ILoginHistory[] | undefined) => {
      const lastEvent =
        (history?.length &&
          history.reduce((last, val) =>
            isAfter(last.created_at, val.created_at) ? last : val,
          )) ||
        '';
      return lastEvent && formatDate(lastEvent.created_at);
    },
  },
];

const Users = (): JSX.Element => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [filters, setFilters, applyFilters] = useUrlFilters<UserDetails>();
  const aUser = usePermission(PermissionNames.AdminUser, 'read');

  const [users, usersLoading, invalidateUsers] = useLoadUserDetails();

  const [markedForDelete, setMarkedForDelete] = useState<UserDetails[]>([]);

  const deleteUser = useMutation({
    mutationKey: [userService.deleteUser.name],
    mutationFn: userService.deleteUser,
    onSuccess: () => {
      invalidateUsers();
    },
  });

  let filteredUsers = applyFilters(users);
  const searchText = searchParams.get(queryConstants.SEARCH);
  if (searchText) {
    const valueMatchesSearch = (value: string): boolean =>
      value.toLowerCase().includes(searchText.toLowerCase());

    filteredUsers = users.filter(
      (u) =>
        valueMatchesSearch(u.name) || (u.email && valueMatchesSearch(u.email)),
    );
  }

  const handleEdit = (user: UserDetails) => {
    navigate(PATHS.USER_EDIT(user.id.toString()));
  };

  const markForDelete = (usersToDelete: UserDetails | UserDetails[]) => {
    setMarkedForDelete(
      Array.isArray(usersToDelete) ? usersToDelete : [usersToDelete],
    );
  };

  const performDelete = () => {
    markedForDelete.forEach((user) => deleteUser.mutate(user.id));
    setMarkedForDelete([]);
  };

  const handleAddUser = () => {
    navigate({
      pathname: PATHS.USER_CREATE(),
    });
  };

  const tableActions = [
    {
      icon: Edit,
      action: handleEdit,
      tooltip: 'Edit',
      key: 'edit',
    },
    {
      icon: Delete,
      action: markForDelete,
      tooltip: 'Delete',
      key: 'delete',
    },
  ];

  return (
    <AuthorizedPage>
      <PageHeader
        title="Users"
        hintMessage="Add/Edit and remove users."
        displaySearch
        displayGoBack
      />
      <div className="dashboard-body">
        <TableDefault
          columns={columns}
          rows={filteredUsers}
          filters={filters}
          actions={aUser ? tableActions : []}
          onMassDelete={markForDelete}
          onAddNew={handleAddUser}
          hideAddButton={!aUser}
          hideDeleteButton={!aUser}
          onFiltersUpdate={setFilters}
          isLoading={usersLoading}
        />
        {markedForDelete && (
          <AlertModal
            title="Delete Confirmation"
            modalStatus={markedForDelete.length > 0}
            content={`Are you sure you want to delete ${
              markedForDelete.length === 1
                ? markedForDelete[0].name
                : `${markedForDelete.length} users`
            }?`}
            handleCancel={() => setMarkedForDelete([])}
            handleConfirm={performDelete}
          />
        )}
      </div>
    </AuthorizedPage>
  );
};

export default Users;
