import { IReview } from '../utilities/backendTypes';

const apiUrl = process.env.REACT_APP_API;

const reviewsDummyData: IReview[] = [
  {
    id: 1,
    name: 'Booth1',
    field: 'Video',
    user: 'Janet Smith 1',
    project_id: 1,
    changes: [
      {
        id: 1,
        content: {
          url: 'https://storage.spacesinteractive.co/videos/9_oppo.mp4',
          size: '12 kb',
          length: '123 sec',
        },
      },
      {
        id: 2,
        content: {
          url: 'https://storage.spacesinteractive.co/videos/7_Mulpha.mp4',
          size: '123 kb',
          length: '1 min 20sec',
        },
      },
    ],
    status: 'Pending',
    created_at: '2021-05-12 06:13:47.081+00',
    updated_at: '2021-05-12 06:13:47.081+00',
  },
  {
    id: 2,
    name: 'Booth',
    field: 'Description',
    user: 'Janet Smith 2',
    project_id: 1,
    changes: [
      {
        id: 1,
        content:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cum id massa fusce cursus non. Egestas aenean condimentum arcu, dictum sit turpis. Consequat, ut leo quis ut tortor adipiscing hendrerit tortor morbi. ',
      },
      {
        id: 2,
        content: 'Loasd asd asd asdsads ',
      },
    ],
    status: 'Pending',
    created_at: '2021-05-12 06:13:47.081+00',
    updated_at: '2021-05-12 06:13:47.081+00',
  },
  {
    id: 3,
    name: 'Booth',
    field: 'Description',
    user: 'Janet Smith 3',
    project_id: 1,
    changes: [
      {
        id: 1,
        content:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cum id massa fusce cursus non. Egestas aenean condimentum arcu, dictum sit turpis. Consequat, ut leo quis ut tortor adipiscing hendrerit tortor morbi. ',
      },
      {
        id: 2,
        content: 'Loasd asd asd asdsads ',
      },
    ],
    status: 'Pending',
    created_at: '2021-05-12 06:13:47.081+00',
    updated_at: '2021-05-12 06:13:47.081+00',
  },
  {
    id: 4,
    name: 'Booth',
    field: 'Description',
    user: 'Janet Smith 4',
    project_id: 2,
    changes: [
      {
        id: 1,
        content:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cum id massa fusce cursus non. Egestas aenean condimentum arcu, dictum sit turpis. Consequat, ut leo quis ut tortor adipiscing hendrerit tortor morbi. ',
      },
      {
        id: 2,
        content: 'Loasd asd asd asdsads ',
      },
    ],
    status: 'Pending',
    created_at: '2021-05-12 06:13:47.081+00',
    updated_at: '2021-05-12 06:13:47.081+00',
  },
  {
    id: 5,
    name: 'Booth',
    field: 'Description',
    user: 'Janet Smith 5',
    project_id: 2,
    changes: [
      {
        id: 1,
        content:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cum id massa fusce cursus non. Egestas aenean condimentum arcu, dictum sit turpis. Consequat, ut leo quis ut tortor adipiscing hendrerit tortor morbi. ',
      },
      {
        id: 2,
        content: 'Loasd asd asd asdsads ',
      },
    ],
    status: 'Pending',
    created_at: '2021-05-12 06:13:47.081+00',
    updated_at: '2021-05-12 06:13:47.081+00',
  },
];

function getReviewChanges(projectId: number): IReview[] {
  return reviewsDummyData.filter((review) => review.project_id === projectId);
}

function updateReviewChanges(review: IReview): Promise<IReview> {
  // console.log('updateReview service: ', id);
  return Promise.resolve(review);
}

const reviewService = {
  getReviewChanges,
  updateReviewChanges,
};

export default reviewService;
