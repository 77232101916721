import { useQuery } from '@tanstack/react-query';
import { IPermission, IProject } from '../../services/transportTypes/BaseTypes';
import {IRole} from "../../services/transportTypes/NRole";
import userService from '../../services/userService';

export default function (): [IRole[], boolean] {
  const { data, isLoading } = useQuery<IRole[]>(
    [userService.listSelfRoles.name],
    () => userService.listSelfRoles(),
  );

  return [data ?? [], isLoading];
}
