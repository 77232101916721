import { useSearchParams } from 'react-router-dom';

/**
 * Returns null if id param is not present;
 */
export default function (): number | null {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  if (id === null) {
    return null;
  }
  return Number.parseInt(id);
}
