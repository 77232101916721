import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { IAdminUser } from '../../services/transportTypes/NAdminUser';
import userService from '../../services/userService';

export default function (): [
  IAdminUser | undefined,
  boolean,
  () => Promise<void>,
] {
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: [userService.getSelf.name],
    queryFn: () => userService.getSelf(),
  });

  const invalidate = useCallback(
    () => queryClient.invalidateQueries([userService.getSelf.name]),
    [queryClient],
  );

  return [data, isLoading, invalidate];
}
