import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { IAdminUser } from '../../services/transportTypes/NAdminUser';
import userService from '../../services/userService';

export default function (
  userId: number | null,
): [IAdminUser | undefined, boolean, (id: number) => Promise<void>] {
  const queryClient = useQueryClient();

  const { data, isLoading, isPaused } = useQuery(['getUser', { id: userId }], {
    queryFn: () => userService.getUser(userId!),
    enabled: !!userId,
  });

  const invalidate = useCallback(
    (id: number) => queryClient.invalidateQueries(['getUser', { id }]),
    [queryClient],
  );

  return [data, isLoading || isPaused, invalidate];
}
