import { useQuery } from '@tanstack/react-query';
import { IProjectSummary } from '../../services/transportTypes/NProject';
import { IAdminUserSummary, NAdminUser } from '../../services/transportTypes/NAdminUser';
import userService from '../../services/userService';

export default (user?: IAdminUserSummary): [IProjectSummary[], boolean] => {
  const { data: userProjects, isLoading, isPaused } = useQuery(
    [userService.getUserProjects.name, { id: user?.id }],
    {
      queryFn: () => userService.getUserProjects(user?.id!),
      enabled: !!user,
    },
  );

  return [userProjects ?? [], isPaused || isLoading];
};
