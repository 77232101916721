import { useCallback, useRef } from 'react';

export default function useUniqueId(
  maxVal = Number.MAX_SAFE_INTEGER,
): [number, () => number] {
  const id = useRef(0);

  const increment = useCallback(() => {
    const nextId = id.current;
    id.current = nextId === maxVal ? 0 : nextId + 1;

    return nextId;
  }, [maxVal]);

  return [id.current, increment];
}
