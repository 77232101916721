import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Skeleton, Stack } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SystemStyleObject } from '@mui/system';
import { compareDesc } from 'date-fns';
import React from 'react';
import { Colours } from '../../constants/designConstants';
import { ILoginHistory } from '../../services/transportTypes/NAdminUser';
import { formatDate } from '../../utilities/functions';
import ButtonDefault from '../base/ButtonDefault';
import HeaderComponent from '../base/HeaderComponent';

const headingStyle: SystemStyleObject = {
  px: 2,
  py: 1,
  border: 0,
  backgroundColor: Colours.Black.Faded,
  color: Colours.White.Pure,

  '&:first-of-type': {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  '&:last-of-type': {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
};
const cellStyles: SystemStyleObject = {
  px: 2,
  py: 1,
  border: 0,
};

export interface LoginHistoryProps {
  history: ILoginHistory[];
  isLoading?: boolean;
  onViewMore?: () => void;
}
const LoginHistory = (props: LoginHistoryProps): JSX.Element => {
  const { history, isLoading, onViewMore } = props;

  // Show only the most recent 5 items
  const slice = history
    .sort((a, b) => compareDesc(a.created_at, b.created_at))
    .slice(0, 5);

  return (
    <Stack alignItems="stretch" spacing={1} sx={{width: '100%'}}>
      <HeaderComponent title="Login History" sx={{ mb: 2 }} />
      <Table
        sx={{
          borderCollapse: 'separate',
          backgroundColor: Colours.White.Darkened,
          borderRadius: 1,
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={headingStyle}>
              <Typography variant="h3" color="inherit">
                Date
              </Typography>
            </TableCell>
            <TableCell sx={headingStyle}>
              <Typography variant="h3" color="inherit">
                Time of Login
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading &&
            new Array(5).fill(0).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={index}>
                <TableCell sx={cellStyles}>
                  <Typography variant="body1">
                    <Skeleton variant="text" />
                  </Typography>
                </TableCell>
                <TableCell sx={cellStyles}>
                  <Typography variant="body1">
                    <Skeleton variant="text" />
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          {!isLoading &&
            slice.length > 0 &&
            slice.map((item) => {
              const date = formatDate(item.created_at);
              return (
                <TableRow key={item.id}>
                  <TableCell sx={cellStyles}>
                    <Typography variant="body1">
                      {date.split(',')[0]}
                    </Typography>
                  </TableCell>
                  <TableCell sx={cellStyles}>
                    <Typography variant="body1">
                      {date.split(',')[1]}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          {!isLoading && slice.length === 0 && (
            <TableRow>
              <TableCell sx={{textAlign: 'center'}} colSpan={2}>
                <Typography variant="body1">No Data to display</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <ButtonDefault
        variant="subtle"
        size="small"
        onClick={onViewMore}
        sx={{ alignSelf: 'flex-end' }}
        disabled={isLoading}
      >
        <MoreHorizIcon sx={{ mr: 1 }} />
        View More
      </ButtonDefault>
    </Stack>
  );
};

export default LoginHistory;
