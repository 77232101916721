import React from 'react';
import { useNavigate } from 'react-router-dom';
import SubjectIcon from '@mui/icons-material/Subject';
import TimelineIcon from '@mui/icons-material/Timeline';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ListIcon from '@mui/icons-material/List';
import PeopleIcon from '@mui/icons-material/People';
import PATHS from '../../constants/pathGenerators';
import {
  IPermission,
  IProject,
  PermissionName,
  PermissionNames,
} from '../../services/transportTypes/BaseTypes';
import { ServiceCardProps } from './ServiceCard';
import ManualServiceCardList from './ManualServiceCardList';
import { IProjectSummary } from '../../services/transportTypes/NProject';
import { ServiceNames } from '../../services/transportTypes/NService';
import useSelfPermissionsList from '../../utilities/hooks/useSelfPermissionsList';
import databaseConstants from '../../constants/databaseConstants';

export interface ServiceCardListProps {
  project: IProjectSummary | undefined;
}

function isPermitted(
  permissions: IPermission[] | undefined,
  name: PermissionName,
  projectId: IProject['id'] | undefined,
) {
  return permissions?.find((p) => p.name === name && p.project_id === projectId)
    ?.read;
}

const ServiceCardList = (props: ServiceCardListProps): JSX.Element => {
  const { project } = props;
  const navigate = useNavigate();

  // p*: a product specific permission
  // a*: an admin permission

  const [permissions, permissionsLoading] = useSelfPermissionsList();
  const services = project?.services ?? [];

  const aUser = isPermitted(
    permissions,
    PermissionNames.AdminUser,
    databaseConstants.ADMIN_PROJECT_ID,
  );

  const pRole = isPermitted(permissions, PermissionNames.Role, project?.id);
  const aRole = isPermitted(
    permissions,
    PermissionNames.AdminRole,
    databaseConstants.ADMIN_PROJECT_ID,
  );
  const pRoleUser = isPermitted(
    permissions,
    PermissionNames.RoleUser,
    project?.id,
  );
  const aRoleUser = isPermitted(
    permissions,
    PermissionNames.AdminRoleUser,
    databaseConstants.ADMIN_PROJECT_ID,
  );
  const pAnalytics = isPermitted(
    permissions,
    PermissionNames.Analytics,
    project?.id,
  );
  const aAnalytics = isPermitted(
    permissions,
    PermissionNames.AdminAnalytics,
    databaseConstants.ADMIN_PROJECT_ID,
  );
  const pCms = isPermitted(permissions, PermissionNames.CMS, project?.id);
  const aCms = isPermitted(
    permissions,
    PermissionNames.AdminCMS,
    databaseConstants.ADMIN_PROJECT_ID,
  );

  const showUsers = aUser;
  const showRoles = pRole || aRole || aRoleUser || pRoleUser;
  const showCms =
    (pCms || aCms) && services.some((s) => s.name === ServiceNames.CMS);
  const showAnalytics =
    (pAnalytics || aAnalytics) &&
    services.some((s) => s.name === ServiceNames.Analytics);
  const showReview = false;

  const cards: ServiceCardProps[] = [];

  // if (showCms) {
  //   cards.push({
  //     icon: <SubjectIcon />,
  //     name: 'CMS',
  //     onClick: () => navigate(PATHS.CMS(project?.slug)),
  //   });
  // }
  if (showAnalytics) {
    cards.push({
      icon: <TimelineIcon />,
      name: 'Analytics',
      onClick: () => navigate(PATHS.ANALYTICS(project?.slug)),
    });
  }
  if (showReview) {
    cards.push({
      icon: <PlaylistAddCheckIcon />,
      name: 'Review',
      onClick: () => navigate(PATHS.REVIEW(project?.slug)),
    });
  }
  if (showRoles) {
    cards.push({
      icon: <ListIcon />,
      name: 'Roles',
      onClick: () => navigate(PATHS.ROLES(project?.slug)),
    });
  }
  if (showUsers) {
    cards.push({
      icon: <PeopleIcon />,
      name: 'Users',
      onClick: () => navigate(PATHS.USERS(project?.slug)),
    });
  }

  return (
    <ManualServiceCardList
      services={cards}
      isLoading={!project || permissionsLoading}
    />
  );
};

export default ServiceCardList;
