import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { parseISO } from 'date-fns';
import { IActivityLog } from '../services/transportTypes/BaseTypes';

export function copyTo<T>(from: Partial<T>, to: T) {
  return {
    ...to,
    ...from,
  };
}

export function patchList<T>(
  list: T[],
  update: Partial<T> | ((item: T) => Partial<T>),
  place: number | ((entry: T) => boolean),
  defaults: T,
) {
  const copy = [...list];

  // Find the index
  let index = -1;
  if (typeof place === 'number' && place >= 0 && place < list.length) {
    index = place;
  } else if (typeof place === 'function' && copy.find(place)) {
    index = copy.findIndex(place);
  }

  // Get the object to update
  let value: T = defaults;
  if (index >= 0) {
    value = copy[index];
  }

  // Update the object
  let changed: T | undefined;
  if (typeof update === 'function') {
    changed = copyTo(update(value), value);
  } else {
    changed = copyTo(update, value);
  }

  // Replace the original object with the changed one, or insert if no original
  if (index >= 0) {
    copy[index] = changed;
  } else {
    copy.push(changed);
  }

  return copy;
}

export function arrayDiff<T>(current: T[], upcoming: T[]) {
  const added: T[] = [];
  const removed = current.slice();

  upcoming.forEach((service) => {
    const index = removed.indexOf(service);
    if (index >= 0) {
      removed.splice(index, 1);
    } else if (!added.includes(service)) {
      added.push(service);
    }
  });

  return {
    added,
    removed,
  };
}

export function formatDate(date: Date) {
  return date.toLocaleString('en-AU');
}

export function formatDateFromString(dateStr: string) {
  return formatDate(new Date(dateStr));
}

export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}

export function toSearchParam(obj: Record<string, string>): string {
  return `?${new URLSearchParams(obj).toString()}`;
}

/**
 * Takes in a list of possible classnames, filters out any that are undefined or otherwise falsy,
 * and then combines them into a single space-separated string for use in the classname property.
 * e.g. toClassString('a', 'b', false && 'c', true && 'd') returns 'a b d'
 * @param strings an array of strings to join. False and nullish values are permitted in order to
 *  improve ease of use of the function.
 * @returns a valid classname string
 */
export function toClassString(
  ...strings: (string | false | undefined | null)[]
) {
  return strings.filter((s) => s).join(' ');
}

export function combineSxProp(
  ...prop: (SxProps<Theme> | null | undefined)[]
): SxProps<Theme> {
  return prop.filter(notEmpty).flatMap((p) => (Array.isArray(p) ? p : [p]));
}

export function XOR(a: boolean, b: boolean) {
  return a !== b;
}

export function parseIntOrDefault(
  text: string | null | undefined,
  def: number,
): number {
  return text ? Number.parseInt(text) : def;
}

export function camelCaseToSpaceSeparated(text: string): string {
  return text.replace(/([A-Z])/g, ' $1').trim();
}

export type ActivityArea = 'project' | 'role' | 'user';
export function findActivityArea(detail: IActivityLog['detail']): ActivityArea {
  switch (detail) {
    case 'Created User':
    case 'Updated Details':
    case 'Updated 2FA Settings':
    case 'Confirmed 2FA Settings':
    case 'Deleted User':
    case 'Password Reset':
      return 'user';
    case 'Created Role':
    case 'Updated Role Settings':
    case 'Deleted Role':
    case 'Updated Role Permissions':
    case 'Added Role Users':
    case 'Updated Role Users':
    case 'Removed Role Users':
      return 'role';
    case 'Added Users':
    case 'Updated User List':
    case 'Removed Users':
    case 'Updated User Roles':
    case 'Created Project':
    case 'Updated Project Settings':
    case 'Deleted Project':
    case 'Added Services':
    case 'Updated Services':
    case 'Removed Services':
      return 'project';
    default:
      console.error('Unmapped ActivityLog detail message', detail);
      return 'project';
  }
}

interface Hydrateable<DateType extends Date | string> {
  created_at: DateType;
  updated_at: DateType | undefined | null;
}

export function hydrateTimestamps<T>(
  obj: T & Hydrateable<string>,
): T & Hydrateable<Date> {
  // eslint-disable-next-line camelcase
  const { created_at, updated_at } = obj;
  return {
    ...obj,
    created_at: parseISO(created_at),
    updated_at: notEmpty(updated_at) ? parseISO(updated_at) : null,
  };
}

export function findMaxId(list: { id: number }[]): number {
  // add 0 to ensure we get a usable number on empty lists
  return Math.max(0, ...list.map((i) => i.id));
}

/**
 * Creates an array containing the first X natural numbers, in order
 * @param count how many numbers to return
 * @return [0, count)
 */
export function numbersUpTo(count: number): number[] {
  return new Array(count).fill(1).map((_, i) => i);
}

export function flattenObject<T extends {}, K extends keyof T>(
  obj: T,
  key: K,
): Omit<T, K> & T[K] {
  return {
    ...obj,
    [key]: undefined,
    ...obj[key],
  };
}

const nonNumericCharacters = new RegExp(/\D/);
export function stripToInteger(integerString: string): string {
  return integerString.replace(nonNumericCharacters, '');
}

const nonFloatCharacters = new RegExp(/[^0-9.]/);
export function stripToFloat(floatString: string): string {
  const cleaned = floatString.replace(nonFloatCharacters, '');

  const fragments = cleaned.split('.');
  if (fragments.length > 1) {
    // Strip any extra decimal places
    return `${fragments[0]}.${fragments.slice(1).join('')}`;
  }
  // There were no decimals
  return cleaned;
}
