import { Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonDefault from '../components/base/ButtonDefault';
import PageHeader from '../components/base/PageHeader';
import { BodyPanel } from '../components/base/Panel';
import DocumentEditor from '../components/cms/DocumentEditor';
import PathGenerators from '../constants/pathGenerators';
import AuthorizedPage from '../containers/AuthorizedPage';
import cmsService from '../services/cmsService';
import { DocumentDataField } from '../services/transportTypes/NCms';
import { validateFieldValue } from '../utilities/DocumentUtils';
import useAlert from '../utilities/hooks/useAlert';
import useDocument from '../utilities/hooks/useDocument';
import useDocumentSchema from '../utilities/hooks/useDocumentSchema';
import useIdParam from '../utilities/hooks/useIdParam';
import useProject from '../utilities/hooks/useProject';

const DocumentEdit = (): JSX.Element => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const alert = useAlert();

  const documentId = useIdParam();
  const [project, projectLoading] = useProject();
  const [document, documentLoading] = useDocument(project, documentId);
  const [schema, schemaLoading] = useDocumentSchema(
    project,
    document?.schema_id,
  );

  const [name, setName] = useState<string>('');
  const [sequence, setSequence] = useState<number>(0);

  const [data, setData] = useState<Record<string, DocumentDataField> | null>(
    null,
  );
  const [errors, setErrors] = useState<string[]>([]);

  const updateDocumentMutation = useMutation({
    mutationKey: [cmsService.updateDocument.name],
    mutationFn: cmsService.updateDocument,
    onSuccess(response) {
      alert({
        message: `${response.name} saved.`,
        severity: 'success',
      });
      navigate(
        PathGenerators.DOCUMENTS(project?.slug, document?.schema_id.toString()),
      );
      queryClient.setQueriesData(
        [
          cmsService.getDocument.name,
          { projectId: project?.slug, schemaId: response.id },
        ],
        data,
      );
    },
  });

  const handleDataChange = (fieldName: string, value: DocumentDataField) => {
    // console.log('handleDataChange', {
    //   fieldName,
    //   value,
    //   previousData: data,
    // });

    setData((previous) => ({
      ...previous,
      [fieldName]: value,
    }));
  };

  const handleSave = () => {
    const saveErrors: string[] = [];
    if (!name) {
      saveErrors.push('name');
    }

    if (!sequence || sequence < 0) {
      saveErrors.push('sequence');
    }

    if (!data) {
      saveErrors.push('data');
    } else {
      schema?.fields.forEach((f) => {
        if (!validateFieldValue(data[f.name], f)) {
          saveErrors.push(f.name);
        }
      });
    }

    if (!saveErrors.length) {
      console.log('Save', { name, sequence, data });
      updateDocumentMutation.mutate({
        projectId: project!.id,
        documentId: documentId!,
        body: {
          name,
          sequence,
          data: data!,
        },
      });
    }

    setErrors(saveErrors);
  };

  useEffect(() => {
    if (document && !data) {
      console.log('Resetting data fields');
      setName(document.name);
      setSequence(document.sequence);
      setData(document.data);
    }
  }, [document, schema]);

  return (
    <AuthorizedPage>
      <PageHeader
        title={`${schema?.name ?? 'CMS'} - ${
          document?.name ?? 'Edit Document'
        }`}
        hintMessage="Edit an existing document."
        displaySearch
        displayGoBack
      />
      <BodyPanel>
        <Stack spacing={2}>
          <DocumentEditor
            name={name}
            sequence={sequence}
            fields={schema?.fields ?? []}
            data={data}
            errors={errors}
            isLoading={
              projectLoading || documentLoading || schemaLoading || !data
            }
            onNameChange={setName}
            onSequenceChange={setSequence}
            onDataChange={handleDataChange}
          />
          <Stack direction="row" justifyContent="flex-end">
            <ButtonDefault
              onClick={handleSave}
              disabled={
                projectLoading ||
                documentLoading ||
                schemaLoading ||
                !data ||
                updateDocumentMutation.isSuccess
              }
            >
              Save
            </ButtonDefault>
          </Stack>
        </Stack>
      </BodyPanel>
    </AuthorizedPage>
  );
};

export default DocumentEdit;
