import { ArrowDownward, ArrowUpward, Delete } from '@mui/icons-material';
import {
  capitalize,
  FormControl,
  IconButton,
  InputLabel,
  Stack,
} from '@mui/material';
import React, { ChangeEvent } from 'react';
import {
  AllDocumentFieldTypes,
  DocumentFieldType,
} from '../../services/transportTypes/NCms';
import InputDefault from '../base/InputDefault';
import Panel from '../base/Panel';
import SelectDefault from '../base/SelectDefault';

export interface SchemaFieldCardProps {
  name: string;
  type: DocumentFieldType;
  errors?: string[];
  isFirst?: boolean;
  isLast?: boolean;
  onChange?: (name: string, type: DocumentFieldType) => void;
  onReorder?: (up: boolean) => void;
  onDelete?: () => void;
}

const typeOptions = AllDocumentFieldTypes.map((opt) => ({
  id: opt,
  display: capitalize(opt),
}));

const SchemaFieldCard = (props: SchemaFieldCardProps): JSX.Element => {
  const { name, type, errors, isFirst, isLast, onChange, onReorder, onDelete } =
    props;

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.value, type);
    }
  };
  const handleTypeChange = (value: string | null) => {
    if (
      value &&
      AllDocumentFieldTypes.includes(value as DocumentFieldType) &&
      onChange
    ) {
      onChange(name, value as DocumentFieldType);
    }
  };

  return (
    <Panel sx={{ px: 0, pt: 1.5, pb: 1 }} elevation={1}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack direction="column">
          <IconButton
            sx={{ py: 0, borderRadius: 1 }}
            disabled={isFirst}
            onClick={() => onReorder && onReorder(true)}
          >
            <ArrowUpward />
          </IconButton>
          <IconButton
            sx={{ py: 0, borderRadius: 1 }}
            disabled={isLast}
            onClick={() => onReorder && onReorder(false)}
          >
            <ArrowDownward />
          </IconButton>
        </Stack>
        <InputDefault
          label="Name"
          placeholder="Name"
          value={name}
          onChange={handleNameChange}
          sx={{ flex: '1 1 0' }}
          error={errors?.includes('name')}
        />
        <FormControl sx={{ flex: '1 1 0' }}>
          <InputLabel id="type-label">Type</InputLabel>
          <SelectDefault
            labelId="type-label"
            label="Type"
            value={type}
            options={typeOptions}
            variant="outlined"
            onChange={handleTypeChange}
            error={errors?.includes('type')}
          />
        </FormControl>
        <IconButton onClick={onDelete} disabled={isFirst && isLast}>
          <Delete />
        </IconButton>
      </Stack>
    </Panel>
  );
};

export default SchemaFieldCard;
