import React, { useState } from 'react';
import AlertModal from '../../components/base/AlertModal';

export interface DeleteConfirmationOptions<T> {
  performDelete: (items: T[]) => void;
  summary: (items: T[]) => string;
}

export default function <T>({
  performDelete,
  summary,
}: DeleteConfirmationOptions<T>): [JSX.Element, (items: T[]) => void] {
  const [toDelete, setToDelete] = useState<T[]>([]);
  return [
    <AlertModal
      title="Delete Confirmation"
      modalStatus={!!toDelete.length}
      content={`Are you sure you want to delete ${summary(toDelete)}?`}
      handleCancel={() => setToDelete([])}
      handleConfirm={() => {
        performDelete(toDelete);
        setToDelete([]);
      }}
    />,
    setToDelete,
  ];
}
