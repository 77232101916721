/* eslint-disable react/jsx-wrap-multilines */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import DESIGN from '../../constants/designConstants';
import { IProject } from '../../services/transportTypes/BaseTypes';
import { IRole } from '../../services/transportTypes/NRole';
import { IRoleDetails } from '../../utilities/frontendTypes';
import HeaderComponent from '../base/HeaderComponent';

const RedCheckbox = withStyles({
  root: {
    '&$checked': {
      color: DESIGN.COLOR_RED,
    },
    padding: '5px',
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export type ProjectItem = Pick<IProject, 'id' | 'name'> & {
  assigned: boolean;
};

export type RoleItem = Pick<IRole, 'id' | 'project_id' | 'role_name'> & {
  assigned: boolean;
};

export interface ProjectsAndRolesProps {
  disabled?: boolean;
  isLoading?: boolean;
  projects: ProjectItem[];
  roles: RoleItem[];
  onAddProject?: (projectId: IProject['id']) => void;
  onRemoveProject?: (projectId: IProject['id']) => void;
  onAddRole?: (projectId: IProject['id'], roleId: IRole['id']) => void;
  onRemoveRole?: (projectId: IProject['id'], roleId: IRole['id']) => void;
}

const ProjectsAndRoles = (props: ProjectsAndRolesProps): JSX.Element => {
  const {
    disabled,
    isLoading,
    projects,
    roles,
    onAddProject,
    onRemoveProject,
    onAddRole,
    onRemoveRole,
  } = props;

  const [newProject, setNewProject] = React.useState('');

  const handleAddProject = (event: SelectChangeEvent) => {
    // console.log('handleAddProject: ', event.target.value);
    if (event.target.value !== '' && onAddProject) {
      const selectedId = Number.parseInt(event.target.value as string);

      const project = projects.find((p) => p.id === selectedId);

      if (project && !project.assigned) {
        onAddProject(project.id);
        // clear this to avoid errors when we filter out the added project
        setNewProject('');
      }
    }
  };

  const handleRemoveProject = (project: ProjectItem) => () => {
    if (onRemoveProject) {
      onRemoveProject(project.id);
    }
  };

  const handleCheckBoxChange =
    (role: IRoleDetails) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        if (onAddRole) {
          onAddRole(role.project_id, role.id);
        }
      } else if (onRemoveRole) {
        onRemoveRole(role.project_id, role.id);
      }
    };

  return (
    <div className="projects-roles">
      <HeaderComponent title="Assign Projects and Roles" />
      <Grid container spacing={2}>
        {isLoading ? (
          <>
            <Grid item xs={4}>
              <Skeleton variant="rounded" height={150} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="rounded" height={150} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton variant="rounded" height={150} />
            </Grid>
          </>
        ) : (
          projects
            .filter((p) => p.assigned)
            .map((project) => (
              <Grid item xs={4} key={project.id}>
                <div className="project-title">
                  <Typography variant="h3">{project.name}</Typography>
                  {!disabled && (
                    <IconButton
                      color="inherit"
                      onClick={handleRemoveProject(project)}
                      aria-label="close"
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
                {roles
                  .filter((r) => r.project_id === project.id)
                  .filter((r) => !disabled || r.assigned)
                  .map((role) => (
                    <Grid item key={role.id}>
                      <FormControlLabel
                        control={
                          <RedCheckbox
                            checked={role.assigned}
                            onChange={handleCheckBoxChange(role)}
                            name={role.role_name}
                          />
                        }
                        label={role.role_name}
                        disabled={disabled}
                      />
                    </Grid>
                  ))}
                {roles
                  .filter((r) => r.project_id === project.id)
                  .filter((r) => !disabled || r.assigned).length === 0 && (
                  <Typography variant="body1">
                    No roles in this project
                  </Typography>
                )}
              </Grid>
            ))
        )}
      </Grid>
      {!disabled && (
        <Grid container direction="column">
          <Grid container className="add-project-title">
            <AddCircleIcon />
            <Typography variant="body1">Add Project</Typography>
          </Grid>
          <Grid container>
            <FormControl variant="outlined" className="project-select-wrapper">
              <Select
                value={newProject}
                onChange={handleAddProject}
                inputProps={{ 'aria-label': 'Without label' }}
                placeholder="Project List"
                autoWidth
                disabled={isLoading}
              >
                <MenuItem value="" disabled>
                  Project List
                </MenuItem>
                {projects
                  ?.filter((p) => !p.assigned)
                  .map((item) => (
                    <MenuItem key={`proj-${item.id}`} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ProjectsAndRoles;
