import { useQuery } from '@tanstack/react-query';
import cmsService from '../../services/cmsService';
import { IProject } from '../../services/transportTypes/BaseTypes';
import { IDocumentSchema } from '../../services/transportTypes/NCms';

export default function (
  project?: IProject,
  schemaId?: IDocumentSchema['id'] | null,
): [IDocumentSchema | null, boolean] {
  const { data: schema, isLoading } = useQuery({
    queryKey: [
      cmsService.getSchema.name,
      // eslint-disable-next-line react/destructuring-assignment
      { projectId: project?.slug, schemaId },
    ],
    queryFn: () =>
      cmsService.getSchema({
        projectId: project!.slug,
        schemaId: schemaId!,
      }),
    enabled: !!project && !!schemaId,
  });
  return [schema ?? null, isLoading];
}
