import { CSSProperties } from 'react';
import { createTheme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { Colours } from './constants/designConstants';

// Override typography settings to create a 'title' variant
declare module '@mui/material/styles' {
  interface TypographyVariants {
    title: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title?: CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
  }
}

export default createTheme({
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    allVariants: {
      color: Colours.Black.Text,
    },
    title: {
      // Unauthorised Page Titles
      fontSize: 64,
      fontWeight: 700,
    },
    h1: {
      // Page Titles
      fontSize: 40,
      fontWeight: 900,
    },
    subtitle1: {
      // Page Descriptions
      fontSize: 15,
      fontWeight: 400,
    },
    h2: {
      // Section Titles
      fontSize: 18,
      fontWeight: 700,
    },
    h3: {
      // Component Titles
      fontSize: 16,
      fontWeight: 700,
    },
    subtitle2: {
      // Component descriptions
      fontSize: 14,
      fontWeight: 600,
      color: Colours.Red.Primary,
    },
    body1: {
      // Most body text
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      // Smaller text inside tables etc
      fontSize: 14,
      fontWeight: 400,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // Ensure title renders as a h1 tag
          title: 'h1',
        },
      },
    },
  },
});

export const ScrollBars: SystemStyleObject<Theme> = {
  '&::-webkit-scrollbar': {
    width: 7,
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 5px grey',
    borderRadius: 1,
    borderBottomLeftRadius: 0,
  },

  '&::-webkit-scrollbar-thumb': {
    background: 'grey',
    borderRadius: 1,
    borderBottomLeftRadius: 0,
  },
};

export const VisuallyHidden: SystemStyleObject<Theme> = {
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  top: 0,
  width: 1,
}
