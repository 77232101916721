import {
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material';
import React from 'react';
import { combineSxProp } from '../../utilities/functions';

export interface SelectDefaultOption {
  id: string;
  display: string;
}

export interface SelectDefaultProps
  extends Omit<SelectProps<string>, 'value' | 'onChange'> {
  value: string | null | undefined;
  options: SelectDefaultOption[];
  onChange?: (value: string | null) => void;
}

const SelectDefault = (props: SelectDefaultProps): JSX.Element => {
  const {
    value,
    options,
    onChange,
    variant = 'standard',
    sx,
    ...others
  } = props;

  const handleChange = (event: SelectChangeEvent) => {
    if (onChange) {
      const selected = options.find(
        (option) => option.id === event.target.value,
      );

      if (selected && onChange) {
        onChange(selected.id);
      } else {
        onChange(null);
      }
    }
  };

  return (
    <Select
      {...others}
      value={value ?? ''}
      variant={variant}
      onChange={handleChange}
      sx={combineSxProp(
        {
          width: '100%',
        },
        sx,
      )}
    >
      {options.map((opt) => (
        <MenuItem key={opt.id} value={opt.id}>
          {opt.display}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectDefault;
