import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import serviceService from '../../services/serviceService';
import { IPermission, IProject } from '../../services/transportTypes/BaseTypes';
import { IProjectSummary } from '../../services/transportTypes/NProject';

/**
 * Fetches the services for a given project.
 * in the project object
 * @param project the project
 * @return {
 *   services: the list of services used by the project,
 *   loading: whether requests are still loading
 * }
 */
export default (
  project?: IProject,
): [IPermission[], boolean, (p: IProjectSummary) => Promise<void>] => {
  const queryClient = useQueryClient();

  const { data, isLoading, isPaused } = useQuery(
    ['getProjectServicePermissions', { projectSlug: project?.slug }],
    {
      queryFn: () => serviceService.getProjectServicePermissions(project!.slug),
      enabled: !!project,
    },
  );

  const permissions = useMemo(() => data ?? [], [data])

  const invalidate = useCallback(
    (p: IProjectSummary) =>
      queryClient.resetQueries([
        'getProjectServicePermissions',
        { projectSlug: p.slug },
      ]),
    [queryClient],
  );

  return [permissions, isLoading || isPaused, invalidate];
};
