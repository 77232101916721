import { Difference } from '@mui/icons-material';
import React, { useState } from 'react';
import PageHeader from '../components/base/PageHeader';
import { BodyPanel } from '../components/base/Panel';
import TableDefault, { ColumnData } from '../components/base/TableDefault';
import ViewActivityOverlay from '../components/base/ViewActivityOverlay';
import AuthorizedPage from '../containers/AuthorizedPage';
import { formatDate } from '../utilities/functions';
import useProject from '../utilities/hooks/useProject';
import useProjectActivityHistory, {
  ActivityLogRecord,
} from '../utilities/hooks/useProjectActivityHistory';
import useUrlFilters from '../utilities/hooks/useUrlFilters';

const columns: ColumnData<ActivityLogRecord>[] = [
  {
    id: 'id',
    type: 'number',
  },
  {
    id: 'userName',
    type: 'string',
    label: 'User Name',
  },
  {
    id: 'area',
    type: 'string',
    label: 'Area',
  },
  {
    id: 'detail',
    type: 'string',
    label: 'Detail',
  },
  {
    id: 'items',
    type: 'number',
    label: 'Number of changes',
    formatter: (items) => items.length,
  },
  {
    id: 'created_at',
    type: 'date',
    label: 'Date',
    formatter: (date) => formatDate(date),
  },
];

const ActivityLog = (): JSX.Element => {
  const [project, projectLoading] = useProject();
  const [records, recordsLoading] = useProjectActivityHistory(project);

  const [filters, setFilters, applyFilters] =
    useUrlFilters<ActivityLogRecord>();

  const [selected, setSelected] = useState<ActivityLogRecord>();

  const handleSelect = (record: ActivityLogRecord | undefined) => {
    setSelected(record);
  };

  return (
    <AuthorizedPage>
      <PageHeader
        title="Activity Log"
        hintMessage={project?.name ?? 'Loading...'}
      />
      <BodyPanel>
        <TableDefault
          columns={columns}
          rows={applyFilters(records)}
          filters={filters}
          onFiltersUpdate={setFilters}
          isLoading={projectLoading || recordsLoading}
          actions={[
            {
              key: 'view',
              tooltip: 'View Changes',
              icon: Difference,
              action: handleSelect,
            },
          ]}
        />
        <ViewActivityOverlay
          record={selected}
          allRecords={records}
          onClose={() => handleSelect(undefined)}
        />
      </BodyPanel>
    </AuthorizedPage>
  );
};

export default ActivityLog;
