import { useQueries } from '@tanstack/react-query';
import roleService from '../../services/rolesService';
import { IRole } from '../../services/transportTypes/NRole';
import { IPermission } from '../backendTypes';

export default function (
  roles: IRole[] = [],
): [
  { roleId: IRole['id']; permissions: IPermission[]; isLoading: boolean }[],
  boolean,
] {
  const results = useQueries({
    queries: roles.map((role) => ({
      queryKey: [
        roleService.getRolePermissions.name,
        { project_id: role.project_id, roleId: role.id },
      ],
      queryFn: () =>
        roleService.getRolePermissions({
          projectSlug: role.project_id,
          roleId: role.id,
        }),
    })),
  });

  return [
    roles.map((role, index) => ({
      roleId: role.id,
      permissions: results[index].data ?? [],
      isLoading: results[index].isLoading,
    })),
    results.some((r) => r.isLoading),
  ];
}
