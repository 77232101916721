import { Grid } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonDefault from '../components/base/ButtonDefault';
import PageHeader from '../components/base/PageHeader';
import { BodyPanel } from '../components/base/Panel';
import LoginHistory from '../components/user/LoginHistory';
import ProjectsAndRoles from '../components/user/ProjectsAndRoles';
import ResetPasswordButton from '../components/user/ResetPasswordButton';
import TwoFactorStateButtons from '../components/user/TwoFactorStateButtons';
import UserPropertiesEditor, {
  UserPropertiesDictionary,
} from '../components/user/UserPropertiesEditor';
import MESSAGES from '../constants/messagesConstants';
import PathGenerators from '../constants/pathGenerators';
import AuthorizedPage from '../containers/AuthorizedPage';
import { IProject } from '../services/transportTypes/BaseTypes';
import { NAdminUser } from '../services/transportTypes/NAdminUser';
import { IRole } from '../services/transportTypes/NRole';
import userService from '../services/userService';
import { XOR } from '../utilities/functions';
import useAlert from '../utilities/hooks/useAlert';
import useIdParam from '../utilities/hooks/useIdParam';
import useLoginHistory from '../utilities/hooks/useLoginHistory';
import useProjectList from '../utilities/hooks/useProjectList';
import useUser from '../utilities/hooks/useUser';
import useUserProjects from '../utilities/hooks/useUserProjects';
import useUserRoles from '../utilities/hooks/useUserRoles';

const UserEdit = (): JSX.Element => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const alert = useAlert();

  const userId = useIdParam();
  const [user, userLoading, invalidateUser] = useUser(userId);
  const [allProjects, allProjectsLoading] = useProjectList(true);
  const [userProjects, userProjectsLoading] = useUserProjects(user);
  const [userRoles, rolesLoading] = useUserRoles(user);
  const [loginHistory, historyLoading] = useLoginHistory(userId);

  const [userProperties, setUserProperties] =
    useState<UserPropertiesDictionary>({
      name: '',
      email: '',
      two_factor_state: null,
    });
  const [toggledProjects, setToggledProjects] = useState<IProject['id'][]>([]);
  const [toggledRoles, setToggledRoles] = useState<IRole['id'][]>([]);
  const [fieldErrors, setFieldErrors] = useState<string[]>([]);

  const updateUserMutation = useMutation({
    mutationFn: userService.updateUser,
    mutationKey: ['updateUser'],
    onError: (error: Error) => {
      console.error('updateUser error', { error });
      alert({
        message: error.message,
        severity: 'error',
      });
    },
  });

  const workingProjects = allProjects.map((project) => ({
    ...project,
    // XOR the assigned value with the toggled value
    assigned: XOR(
      userProjects.some((userProject) => userProject.id === project.id),
      toggledProjects.includes(project.id),
    ),
  }));
  const workingRoles = allProjects
    .flatMap((p) => p.roles)
    .map((projectRole) => ({
      ...projectRole,
      // XOR the assigned value with the toggled value
      assigned: XOR(
        userRoles.some((userRole) => userRole.id === projectRole.id) ?? false,
        toggledRoles.includes(projectRole.id),
      ),
    }));

  const handlePropertyChange = (props: UserPropertiesDictionary) => {
    setUserProperties(props);
  };

  const handleViewHistory = () => {
    if (user) {
      navigate(PathGenerators.LOGIN_HISTORY(user.id.toString()));
    }
  };

  const toggleProject = (projectId: IProject['id']) => {
    if (toggledProjects.includes(projectId)) {
      setToggledProjects(toggledProjects.filter((p) => p !== projectId));
    } else {
      setToggledProjects([...toggledProjects, projectId]);
    }
  };

  const toggleRole = (projectId: IProject['id'], roleId: IRole['id']) => {
    if (toggledRoles.includes(roleId)) {
      setToggledRoles(toggledRoles.filter((r) => r !== roleId));
    } else {
      setToggledRoles([...toggledRoles, roleId]);
    }
  };

  const handleSave = () => {
    const errors: string[] = [];

    if (!userProperties.name) {
      errors.push('name');
    }
    if (!userProperties.email) {
      errors.push('email');
    }

    if (user && errors.length === 0) {
      let projects: NAdminUser.UserId.Put.Request['projects'];

      if (toggledProjects.length || toggledRoles.length) {
        // Build list of project id's and roles.
        projects = allProjects
          .filter((project) =>
            XOR(
              userProjects.some((p) => p.id === project.id),
              toggledProjects.includes(project.id),
            ),
          )
          .map((project) => ({
            id: project.id,
            roles: project.roles
              .filter((role) =>
                XOR(
                  userRoles.some((r) => r.id === role.id),
                  toggledRoles.includes(role.id),
                ),
              )
              .map((role) => ({ id: role.id })),
          }));
      }

      updateUserMutation.mutate(
        {
          id: user.id,
          name: userProperties.name,
          email: userProperties.email,
          projects,
        },
        {
          onSuccess: () => {
            alert({
              message: MESSAGES.USER_SAVED,
              severity: 'success',
            });
            Promise.all([
              queryClient.resetQueries(['getUser', { id: userId }]),
              queryClient.resetQueries(['getUserProjects', { id: userId }]),
              queryClient.resetQueries(['getUserRoles', userId]),
            ]).then(() => {
              setToggledProjects([]);
              setToggledRoles([]);
            });
          },
        },
      );
    }
    setFieldErrors(errors);
  };

  const handleInvalidate = () => {
    if (userId) {
      invalidateUser(userId).catch((err) => {
        console.error(err);
        alert({
          message: 'Something went wrong',
          severity: 'error',
        });
      });
    }
  };

  // Update properties when user loads
  useEffect(() => {
    if (user) {
      // eslint-disable-next-line camelcase
      const { name, email, two_factor_state } = user;
      // eslint-disable-next-line camelcase
      setUserProperties({ name, email, two_factor_state });
    }
  }, [user]);

  return (
    <AuthorizedPage>
      <PageHeader
        title="Manage User."
        hintMessage={user?.name ?? ''}
        displayGoBack
      />
      <BodyPanel>
        <Grid
          container
          spacing={3}
          alignItems="stretch"
          alignContent="space-between"
        >
          <Grid item xs={3}>
            <UserPropertiesEditor
              values={userProperties}
              errors={fieldErrors}
              onChange={handlePropertyChange}
              isLoading={userLoading}
            >
              <ResetPasswordButton user={user} />
              <TwoFactorStateButtons
                user={user}
                onDisable2fa={handleInvalidate}
              />
            </UserPropertiesEditor>
          </Grid>
          <Grid item xs={5}>
            <ProjectsAndRoles
              projects={workingProjects}
              roles={workingRoles}
              onAddProject={toggleProject}
              onRemoveProject={toggleProject}
              onAddRole={toggleRole}
              onRemoveRole={toggleRole}
              isLoading={
                allProjectsLoading || userProjectsLoading || rolesLoading
              }
            />
          </Grid>
          <Grid item xs={4}>
            <LoginHistory
              history={loginHistory}
              isLoading={historyLoading}
              onViewMore={handleViewHistory}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <ButtonDefault onClick={handleSave} disabled={userLoading}>
              Save
            </ButtonDefault>
          </Grid>
        </Grid>
      </BodyPanel>
    </AuthorizedPage>
  );
};

export default UserEdit;
