import React, { ChangeEvent } from 'react';
import { Stack, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import HeaderComponent from '../base/HeaderComponent';
import { IProject } from '../../services/transportTypes/BaseTypes';

type ProjectProperty = 'name' | 'client' | 'logo_url' | 'slug';
export type ProjectPropertiesDictionary = Pick<IProject, ProjectProperty>;

export interface ProjectPropertiesEditorProps {
  values: ProjectPropertiesDictionary;
  errors: string[];
  onChange: (update: ProjectPropertiesDictionary) => void;
  slugEditable?: boolean;
  sx?: SxProps;
}

const ProjectPropertiesEditor = (props: ProjectPropertiesEditorProps): JSX.Element => {
  const { values, errors, onChange, slugEditable, sx } = props;

  const handleFieldChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: ProjectProperty,
  ) => {
    const update = {
      ...values,
      [field]: event.target.value,
    };
    onChange(update);
  };

  return (
    <Stack alignItems="stretch" sx={sx}>
      <HeaderComponent title="Details" />
      <Stack alignItems="stretch" spacing={2}>
        <TextField
          variant="outlined"
          label="Name"
          placeholder="Name"
          value={values.name}
          onChange={(e) => handleFieldChange(e, 'name')}
          error={errors.includes('name')}
          required
        />
        <TextField
          variant="outlined"
          label="Client"
          placeholder="Client"
          value={values.client}
          onChange={(e) => handleFieldChange(e, 'client')}
          error={errors.includes('client')}
          required
        />
        <TextField
          variant="outlined"
          label="Logo"
          placeholder="Logo"
          value={values.logo_url}
          onChange={(e) => handleFieldChange(e, 'logo_url')}
          error={errors.includes('logo_url')}
        />
        <TextField
          variant="outlined"
          label="Slug"
          placeholder="Slug"
          value={values.slug}
          onChange={(e) => handleFieldChange(e, 'slug')}
          error={errors.includes('slug')}
          required={slugEditable}
          disabled={!slugEditable}
        />
      </Stack>
    </Stack>
  );
};

export default ProjectPropertiesEditor;
