import { Stack } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import ButtonDefault from '../components/base/ButtonDefault';
import PageHeader from '../components/base/PageHeader';
import { BodyPanel } from '../components/base/Panel';
import SchemaEditor, {
  SchemaEditorField,
} from '../components/cms/SchemaEditor';
import AuthorizedPage from '../containers/AuthorizedPage';
import cmsService from '../services/cmsService';
import { isValidGraphQLName } from '../utilities/DocumentUtils';
import useAlert from '../utilities/hooks/useAlert';
import useIdParam from '../utilities/hooks/useIdParam';
import useProject from '../utilities/hooks/useProject';

const DocSchemaEdit = (): JSX.Element => {
  const queryClient = useQueryClient();
  const schemaId = useIdParam();
  const [project, projectLoading] = useProject();
  const alert = useAlert();

  const [name, setName] = useState('');
  const [fields, setFields] = useState<SchemaEditorField[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const { data: schema, isLoading: schemaLoading } = useQuery({
    queryKey: [
      cmsService.getSchema.name,
      { projectId: project?.slug, schemaId },
    ],
    queryFn: () =>
      cmsService.getSchema({
        projectId: project!.slug,
        schemaId: schemaId!,
      }),
    enabled: !!project && !!schemaId,
  });

  const updateSchemaMutation = useMutation({
    mutationKey: [cmsService.updateSchema.name],
    mutationFn: cmsService.updateSchema,
    onSuccess: (data) => {
      alert({
        message: `${data.name} updated.`,
        severity: 'success',
      });
      queryClient.setQueriesData(
        [cmsService.getSchema.name, { projectId: project?.slug, schemaId }],
        data,
      );
    },
    onError: (error: string) => {
      console.error('Create schema error', { error });
      alert({
        message: error,
        severity: 'error',
      });
    },
  });

  const handleSave = () => {
    // todo validation
    const saveErrors = fields.flatMap((f) => {
      if (!f.name || !isValidGraphQLName(name)) {
        return [`field.${f.id}.name`];
      }
      return [];
    });

    if (fields.length === 0) {
      saveErrors.push('fields');
    }

    if (!name || !isValidGraphQLName(name)) {
      saveErrors.push('name');
    }

    console.log('Create Schema', { name, fields });

    if (project && schema && saveErrors.length === 0) {
      updateSchemaMutation.mutate({
        projectId: project.slug,
        schemaId: schema.id,
        body: {
          name: name !== schema.name ? name : undefined,
          fields: fields.map(({ id, name: fieldName, type }, i) => {
            const previous = schema.fields.find((f) => f.id === id);

            if (previous) {
              return {
                id,
                name: previous.name !== fieldName ? fieldName : undefined,
                type: previous.type !== type ? type : undefined,
                sequence: previous.sequence !== i ? i : undefined,
                properties: undefined,
              };
            }
            return {
              name: fieldName,
              type,
              sequence: i,
              properties: null,
            };
          }),
        },
      });
    }
    setErrors(saveErrors);
  };

  const isLoading =
    projectLoading || schemaLoading || updateSchemaMutation.isLoading;

  useEffect(() => {
    if (schema) {
      setName(schema.name);
      setFields(
        schema.fields.map(({ id, name: fieldName, type }) => ({
          id,
          name: fieldName,
          type,
        })),
      );
    }
  }, [schema]);

  return (
    <AuthorizedPage>
      <PageHeader
        title={schema ? `Edit ${schema.name}` : 'Loading...'}
        hintMessage="Define schemas for your cms documents."
        displayGoBack
      />
      <BodyPanel>
        <Stack direction="column" spacing={2}>
          <SchemaEditor
            name={name}
            fields={fields}
            errors={errors}
            isLoading={isLoading}
            onNameChange={(n) => {
              setName(n);
            }}
            onFieldsChange={(f) => setFields(f)}
          />
          <Stack direction="row" justifyContent="flex-end">
            <ButtonDefault onClick={handleSave} disabled={isLoading}>
              Save
            </ButtonDefault>
          </Stack>
        </Stack>
      </BodyPanel>
    </AuthorizedPage>
  );
};

export default DocSchemaEdit;
