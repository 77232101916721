import addAuthHeader from '../utilities/auth';
import {ProjectSlugOrId} from "../utilities/frontendTypes";
import handleResponse from '../utilities/handleResponse';
import API from '../constants/apiConstants';
import { IPermission, IService } from '../utilities/backendTypes';
import {IProject} from "./transportTypes/BaseTypes";
import { IProjectSummary, NProject } from './transportTypes/NProject';
import { NService } from './transportTypes/NService';

const apiUrl = process.env.REACT_APP_API;

function listServices(): Promise<NService.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };

  return fetch(`${apiUrl}${API.SERVICE}`, requestOptions).then<NService.Get.Response>(
    handleResponse,
  );
}

function getService(serviceId: number): Promise<NService.ServiceId.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };

  return fetch(
    `${apiUrl}${API.SERVICE}/${serviceId}/permission`,
    requestOptions,
  ).then<NService.ServiceId.Get.Response>(handleResponse);
}

function getServicePermissions(service: IService):
  Promise<NService.ServiceId.Permission.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };

  return fetch(
    `${apiUrl}${API.SERVICE}/${service.id}/permission`,
    requestOptions,
  ).then<NService.ServiceId.Permission.Get.Response>(handleResponse);
}

function getProjectServicePermissions(project_id: ProjectSlugOrId):
  Promise<NProject.Project.Service.Permission.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };

  return fetch(
    `${apiUrl}${API.PROJECT}/${project_id}${API.SERVICE}/permission`,
    requestOptions,
  ).then<NProject.Project.Service.Permission.Get.Response>(handleResponse);
}

const serviceService = {
  listServices,
  getService,
  getServicePermissions,
  getProjectServicePermissions,
};

export default serviceService;
