import { useQueries } from '@tanstack/react-query';
import { IAdminUserSummary } from '../../services/transportTypes/NAdminUser';
import { IRole } from '../../services/transportTypes/NRole';
import userService from '../../services/userService';
import useUserProjects from './useUserProjects';

export default (user?: IAdminUserSummary): [IRole[], boolean] => {
  const [projects, projectsLoading] = useUserProjects(user);
  const userId = user?.id ?? -1;

  const results = useQueries({
    queries: projects.map((project) => ({
      queryKey: [ userService.getUserRoles.name, userId, project.id],
      queryFn: () =>
        userService.getUserRoles({
          userId,
          projectId: project.id,
        }),
      enabled: userId >= 0 && !projectsLoading,
    })),
  });

  return [
    results.flatMap((r) => r.data ?? []),
    !!user && projectsLoading && results.some((r) => r.isLoading),
  ];
};
