import React, { MouseEvent, ReactNode } from 'react';
import { Button, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Colours } from '../../constants/designConstants';

export interface ServiceCardProps {
  icon?: ReactNode;
  name?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

const ServiceCard = (props: ServiceCardProps): JSX.Element => {
  const { icon, name, onClick } = props;

  return (
    <Button
      sx={{
        width: 150,
        height: 150,
        background: Colours.Red.Primary,
        color: Colours.White.Pure,
        '&:hover': {
          background: Colours.Red.Darkened,
        },
      }}
      onClick={onClick}
    >
      <Grid
        container
        sx={{ width: 1, height: 1 }}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>{icon}</Grid>
        <Grid item>
          <Typography variant="body1" sx={{ color: Colours.White.Pure }}>
            {name}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
};

export default ServiceCard;
