import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
  token: string | null;
  isTokenValid: boolean;
  twoFactorPending: boolean;
  twoFactorQR?: string;
  emailSent: boolean;
}

export const initialState: UserState = {
  token: null,
  emailSent: false,
  isTokenValid: false,
  twoFactorPending: true,
} as const;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginSuccess(
      state,
      action: PayloadAction<{
        token: string;
        twoFactorPending: boolean;
      }>,
    ) {
      state.token = action.payload.token;
      state.twoFactorPending = action.payload.twoFactorPending;
    },
    twoFactorVerified(state) {
      state.twoFactorPending = false;
    },
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
      state.isTokenValid = true;
    },
    tokenVerified(state) {
      state.isTokenValid = true;
    },
    logout() {
      return initialState;
    },
  },
});

export const {
  loginSuccess,
  twoFactorVerified,
  setToken,
  tokenVerified,
  logout,
} = userSlice.actions;
export default userSlice.reducer;
