import { Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SummaryButtons from '../components/analytics/SummaryButtons';
import BrowserTable from '../components/analytics/widgets/BrowserTable';
import LocationTable from '../components/analytics/widgets/LocationTable';
import LoginLineChart from '../components/analytics/widgets/LoginLineChart';
import TimespentPieChart from '../components/analytics/widgets/TimespentPieChart';
import PageHeader from '../components/base/PageHeader';
import PATHS from '../constants/pathGenerators';
import AuthorizedPage from '../containers/AuthorizedPage';
import {
  IContentInteractionRecord,
  ITimespentRecord,
} from '../services/transportTypes/NAnalytics';
import { EventNames } from '../services/transportTypes/project/CoreAnalyticsEvents';
import getUniqueLogins from '../utilities/getUniqueLogins';
import useAnalyticsData from '../utilities/hooks/useAnalyticsData';
import useBrowserData from '../utilities/hooks/useBrowserData';
import useLoginData from '../utilities/hooks/useLoginData';
import useProject from '../utilities/hooks/useProject';

const Analytics = (): JSX.Element => {
  const navigate = useNavigate();
  const [project] = useProject();
  const currentProjectSlug = project?.slug;

  // ReactQuery Calls
  const [loginData, loginDataLoading] = useLoginData(currentProjectSlug);
  const [browserListData, browserDataLoading] =
    useBrowserData(currentProjectSlug);
  const [timeSpentData, timeSpentDataLoading] =
    useAnalyticsData<ITimespentRecord>(
      currentProjectSlug,
      EventNames.TimeSpent,
    );
  // Get PDF Download Totals
  const [pdfData, pdfDataLoading] = useAnalyticsData<IContentInteractionRecord>(
    project?.slug,
    {
      name: EventNames.ContentInteraction,
      data: [{ field: 'type', value: 'pdf' }],
    },
  );

  // Get unique user login data
  const uniqueLoginData = getUniqueLogins(loginData?.login ?? []);

  const handleViewMore = () => {
    if (currentProjectSlug) {
      navigate(PATHS.ANALYTICS_BROWSER_TYPES(currentProjectSlug));
    }
  };

  return (
    <AuthorizedPage>
      <PageHeader
        title="Analytics"
        hintMessage="Project Analytics."
        displayGoBack
      />
      <Grid
        container
        item
        xs={12}
        direction="column"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid container item xs={12} spacing={2}>
          <Grid container item xs={12} md={12} lg={8}>
            <LoginLineChart
              data={loginData.login}
              isLoading={loginDataLoading}
            />
          </Grid>
          <Grid container item xs={12} md={12} lg={4}>
            <TimespentPieChart
              data={timeSpentData}
              isLoading={timeSpentDataLoading}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid container item xs={12} md={12} lg={4}>
            <LocationTable
              data={loginData.location}
              isLoading={loginDataLoading}
              sx={{ height: '100%', maxHeight: 320 }}
            />
          </Grid>
          <Grid container item xs={12} md={9} lg={6}>
            <BrowserTable
              data={browserListData}
              isLoading={browserDataLoading}
              onClick={handleViewMore}
              sx={{ height: '100%', maxHeight: 320 }}
            />
          </Grid>
          <Grid container item xs={12} md={3} lg={2} direction="column">
            <SummaryButtons
              data={[
                {
                  title: 'Unique Logins',
                  value: uniqueLoginData.length,
                  isLoading: loginDataLoading,
                  onClick: () =>
                    navigate(PATHS.ANALYTICS_UNIQUE_LOGINS(currentProjectSlug)),
                },
                {
                  title: 'Browser Types',
                  value: browserListData.length,
                  isLoading: browserDataLoading,
                  onClick: () =>
                    navigate(PATHS.ANALYTICS_BROWSER_TYPES(currentProjectSlug)),
                },
                {
                  title: 'PDFs Downloaded',
                  value: pdfData.length,
                  isLoading: pdfDataLoading,
                  onClick: () =>
                    navigate(
                      PATHS.ANALYTICS_PDFS_DOWNLOADED(currentProjectSlug),
                    ),
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </AuthorizedPage>
  );
};

export default Analytics;
