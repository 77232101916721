import { logout, setToken } from '../store/userSlice';
import store from '../store/store';

function handleResponse<T>(response: Response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      // UNAUTHORISED signals token expiry, so invalidate the existing token
      if (response.status === 401) {
        store.dispatch(logout());
      }

      const error = data?.error || data?.message || response.statusText;
      console.log({ error });

      return Promise.reject(error);
    }

    if (data.token) {
      store.dispatch(setToken(data.token));
    }

    return data as T;
  });
}

export default handleResponse;
