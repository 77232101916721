import React from 'react';
import { ColumnData } from '../components/base/TableDefault';
import { IContentInteractionRecord } from '../services/transportTypes/NAnalytics';
import { EventNames } from '../services/transportTypes/project/CoreAnalyticsEvents';
import { IAnalyticsTable } from '../utilities/backendTypes';
import useAnalyticsData from '../utilities/hooks/useAnalyticsData';
import useFilterPdfData from '../utilities/hooks/useFilterPdfData';
import useProject from '../utilities/hooks/useProject';
import AnalyticsDetails from './AnalyticsDetails';

const columnsPdfsData: ColumnData<IAnalyticsTable>[] = [
  {
    id: 'title',
    type: 'string',
    disablePadding: false,
    label: 'Title',
  },
  {
    id: 'data',
    type: 'number',
    disablePadding: false,
    label: 'Downloads',
  },
];

const AnalyticsPDFDetails = (): JSX.Element => {
  const [project] = useProject();

  // Get PDF Download Totals
  const [pdfData] = useAnalyticsData<IContentInteractionRecord>(project?.slug, {
    name: EventNames.ContentInteraction,
    data: [{ field: 'type', value: 'pdf' }],
  });
  const pdfGroupedData = useFilterPdfData(pdfData);

  return (
    <AnalyticsDetails
      hint="PDF's downloaded"
      columns={columnsPdfsData}
      rows={
        pdfGroupedData.map((data) => ({
          id: data.id,
          title: data.name,
          data: data.totalValue,
        })) ?? []
      }
    />
  );
};

export default AnalyticsPDFDetails;
