import { Skeleton } from '@mui/material';
import React from 'react';
import { IDocumentSchema } from '../../services/transportTypes/NCms';
import Slider, { SliderItem } from '../base/Slider';
import ToggleButtonDefault from '../base/ToggleButtonDefault';

export interface SchemaSliderProps {
  schemas: IDocumentSchema[];
  selected: number | null;
  onChange: (schema: IDocumentSchema) => void;
  isLoading?: boolean;
}

const SchemaSlider = (props: SchemaSliderProps): JSX.Element => {
  const { schemas, selected, onChange, isLoading } = props;

  return (
    <Slider>
      {isLoading
        ? ['a', 'b', 'c', 'd'].map((char) => (
            <SliderItem key={char} itemId={char}>
              <Skeleton
                variant="rectangular"
                width={120}
                height={52}
                sx={{
                  borderRadius: 1,
                }}
              />
            </SliderItem>
          ))
        : schemas.map((schema) => (
            <SliderItem key={schema.id} itemId={schema.id.toString()}>
              <ToggleButtonDefault
                value={schema.id}
                selected={schema.id === selected}
                onClick={() => onChange(schema)}
                sx={{ minWidth: 120 }}
              >
                {schema.name}
              </ToggleButtonDefault>
            </SliderItem>
          ))}
    </Slider>
  );
};

export default SchemaSlider;
