import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BrowserTable from '../components/analytics/widgets/BrowserTable';
import LocationTable from '../components/analytics/widgets/LocationTable';
import HeaderComponent from '../components/base/HeaderComponent';
import PageHeader from '../components/base/PageHeader';
import { BodyPanel } from '../components/base/Panel';
import ProjectSlider from '../components/home/ProjectSlider';
import ServiceCardList from '../components/home/ServiceCardList';
import ProjectActivityLog from '../components/project/ProjectActivityLog';
import PATHS from '../constants/pathGenerators';
import AuthorizedPage from '../containers/AuthorizedPage';
import { IProject } from '../services/transportTypes/BaseTypes';
import useBrowserData from '../utilities/hooks/useBrowserData';
import useLoginData from '../utilities/hooks/useLoginData';
import useProject from '../utilities/hooks/useProject';
import useProjectList from '../utilities/hooks/useProjectList';
import useSelfPermissionsList from '../utilities/hooks/useSelfPermissionsList';
import { showAnalyticsControls } from '../utilities/permissionChecks';

const Home = (): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [allProjects, allProjectsLoading] = useProjectList();
  const [currentProject] = useProject();

  const project =
    currentProject ?? (allProjects.length > 0 ? allProjects[0] : undefined);
  const currentProjectSlug = project?.slug;

  const [permissions] = useSelfPermissionsList();
  const showAnalytics = showAnalyticsControls(project, permissions);

  const [loginData, loginDataLoading] = useLoginData(currentProjectSlug);
  const [browserListData, browserDataLoading] =
    useBrowserData(currentProjectSlug);

  const handleViewMore = () => {
    if (currentProjectSlug) {
      navigate(PATHS.ANALYTICS_BROWSER_TYPES(project?.slug));
    }
  };

  const handleProjectChange = (p: IProject | null) => {
    if (p) {
      navigate(PATHS.HOME(p.slug));
    }
  };

  useEffect(() => {
    const searchSlug = searchParams.get('slug');

    if (!searchSlug && !allProjectsLoading && allProjects.length) {
      searchParams.set('slug', allProjects[0].slug);
      setSearchParams(searchParams);
    }
  }, [searchParams, allProjectsLoading, allProjects]);

  return (
    <AuthorizedPage>
      <PageHeader
        title="Home"
        hintMessage="Here is a summary of your latest projects. View the projects tab for more."
      />
      <BodyPanel>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ProjectSlider
              projects={allProjects}
              selected={currentProjectSlug ?? null}
              onChange={handleProjectChange}
              isLoading={allProjectsLoading}
            />
          </Grid>

          <Grid container item alignItems="stretch" md={12} lg={8} spacing={2}>
            <Grid item xs={12} className="service-title">
              <HeaderComponent title="Summary" />
            </Grid>
            <Grid item xs={12}>
              {!allProjects.length && !allProjectsLoading && (
                <Typography>
                  You are currently not assigned to any projects. Please contact
                  your administrator.
                </Typography>
              )}
              {(allProjects.length || allProjectsLoading) && (
                <ServiceCardList project={project} />
              )}
            </Grid>
            {showAnalytics && (
              <>
                <Grid item md={12} lg={6}>
                  <LocationTable
                    data={loginData.location}
                    isLoading={loginDataLoading}
                    sx={{ height: '100%', maxHeight: 320 }}
                  />
                </Grid>
                <Grid item md={12} lg={6}>
                  <BrowserTable
                    data={browserListData}
                    isLoading={browserDataLoading}
                    onClick={handleViewMore}
                    sx={{ height: '100%', maxHeight: 320 }}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid item md={12} lg={4}>
            <ProjectActivityLog
              project={currentProject}
              onViewMore={() =>
                navigate(PATHS.ACTIVITY_LOG(currentProject?.slug))
              }
            />
          </Grid>
        </Grid>
      </BodyPanel>
    </AuthorizedPage>
  );
};

export default Home;
