import { parseISO } from 'date-fns';
import API from '../constants/apiConstants';
import addAuthHeader from '../utilities/auth';
import { IRole } from '../utilities/backendTypes';
import { ProjectPathParam, ProjectSlugOrId } from '../utilities/frontendTypes';
import handleResponse from '../utilities/handleResponse';
import { IAdminUser, NAdminUser } from './transportTypes/NAdminUser';
import { NAuth } from './transportTypes/NAuth';
import { NProject } from './transportTypes/NProject';
import { NRole } from './transportTypes/NRole';

const apiUrl = process.env.REACT_APP_API;

type Body<T> = {
  body: T;
};

interface UserProjectIds extends ProjectPathParam {
  userId: IAdminUser['id'];
}

interface RoleProjectIds extends ProjectPathParam {
  roleId: IAdminUser['id'];
}

function hydrateUser({
  created_at,
  updated_at,
  reset_password_expires,
  ...others
}: IAdminUser<string>): IAdminUser<Date> {
  return {
    ...others,
    created_at: parseISO(created_at),
    updated_at: parseISO(updated_at),
    reset_password_expires: reset_password_expires
      ? parseISO(reset_password_expires)
      : null,
  };
}

function listUsers(): Promise<NAdminUser.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.USER}`,
    requestOptions,
  ).then<NAdminUser.Get.Response>(handleResponse);
}

function addUser(
  user: NAdminUser.Post.Request,
): Promise<NAdminUser.Post.Response> {
  const requestOptions = {
    method: 'POST',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(user),
  };
  return fetch(
    `${apiUrl}${API.USER}`,
    requestOptions,
  ).then<NAdminUser.Post.Response>(handleResponse);
}

function getUser(id: number): Promise<NAdminUser.UserId.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(`${apiUrl}${API.USER}/${id}`, requestOptions)
    .then<NAdminUser.UserId.Get.Response<string>>(handleResponse)
    .then(hydrateUser);
}

function getUserLoginHistory(
  id: number,
): Promise<NAdminUser.UserId.History.Login.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(`${apiUrl}${API.USER}/${id}/history/login`, requestOptions)
    .then<NAdminUser.UserId.History.Login.Get.Response<string>>(handleResponse)
    .then((history) =>
      history.map(({ created_at, updated_at, ...others }) => ({
        ...others,
        created_at: parseISO(created_at),
        updated_at: parseISO(updated_at),
      })),
    );
}

function updateUser(
  user: NAdminUser.UserId.Put.Request & Pick<IAdminUser, 'id'>,
): Promise<NAdminUser.UserId.Put.Response> {
  // console.log('updateUser', user)

  const requestOptions = {
    method: 'PUT',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(user),
  };
  return fetch(
    `${apiUrl}${API.USER}/${user.id}`,
    requestOptions,
  ).then<NAdminUser.UserId.Put.Response>(handleResponse);
}

function deleteUser(id: number): Promise<NAdminUser.UserId.Delete.Response> {
  const requestOptions = {
    method: 'DELETE',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.USER}/${id}`,
    requestOptions,
  ).then<NAdminUser.UserId.Delete.Response>(handleResponse);
}

function getUserProjects(
  id: number,
): Promise<NAdminUser.UserId.Project.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.USER}/${id}/project`,
    requestOptions,
  ).then<NAdminUser.UserId.Project.Get.Response>(handleResponse);
}

function getSelf(): Promise<NAdminUser.Self.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(`${apiUrl}${API.USER}/self`, requestOptions)
    .then<NAdminUser.Self.Get.Response<string>>(handleResponse)
    .then(hydrateUser);
}

function updateSelf(
  request: NAdminUser.Self.Put.Request,
): Promise<NAdminUser.Self.Put.Response> {
  const requestOptions = {
    method: 'PUT',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(request),
  };
  return fetch(
    `${apiUrl}${API.USER}/self`,
    requestOptions,
  ).then<NAdminUser.Self.Put.Response>(handleResponse);
}

function update2fa(
  request: NAdminUser.Self.TwoFactorAuth.Put.Request,
): Promise<NAdminUser.Self.TwoFactorAuth.Put.Response> {
  const requestOptions = {
    method: 'PUT',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(request),
  };
  return fetch(
    `${apiUrl}${API.USER}/self/2fa`,
    requestOptions,
  ).then<NAdminUser.Self.TwoFactorAuth.Put.Response>(handleResponse);
}

function verify2fa(
  request: NAdminUser.Self.TwoFactorAuth.Verify.Post.Request,
): Promise<NAdminUser.Self.TwoFactorAuth.Verify.Post.Response> {
  const requestOptions = {
    method: 'POST',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(request),
  };
  return fetch(
    `${apiUrl}${API.USER}/self/2fa/verify`,
    requestOptions,
  ).then<NAdminUser.Self.TwoFactorAuth.Verify.Post.Response>(handleResponse);
}

function listSelfLoginHistory(): Promise<NAdminUser.Self.History.Login.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(`${apiUrl}${API.USER}/self/history/login`, requestOptions)
    .then<NAdminUser.Self.History.Login.Get.Response<string>>(handleResponse)
    .then((data) =>
      data.map((event) => ({
        ...event,
        created_at: parseISO(event.created_at),
        updated_at: parseISO(event.updated_at),
      })),
    );
}

function listSelfPermissions(): Promise<NAdminUser.Self.Permission.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.USER}/self/permission`,
    requestOptions,
  ).then<NAdminUser.Self.Permission.Get.Response>(handleResponse);
}

function listSelfRoles(): Promise<NAdminUser.Self.Role.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.USER}/self/role`,
    requestOptions,
  ).then<NAdminUser.Self.Role.Get.Response>(handleResponse);
}

function getRoleUsers(role: IRole): Promise<NRole.RoleId.User.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${role.project_id}/role/${role.id}/user`,
    requestOptions,
  ).then<NRole.RoleId.User.Get.Response>(handleResponse);
}

function getUserRoles({
  userId,
  projectId,
}: UserProjectIds): Promise<NAdminUser.UserId.Project.Role.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.USER}/${userId}${API.PROJECT}/${projectId}/role`,
    requestOptions,
  ).then<NAdminUser.UserId.Project.Role.Get.Response>(handleResponse);
}

function addUsersToRole({
  body,
  projectId,
  roleId,
}: RoleProjectIds &
  Body<NRole.RoleId.User.Post.Request>): Promise<NRole.RoleId.User.Post.Response> {
  const requestOptions = {
    method: 'POST',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${projectId}/role/${roleId}/user`,
    requestOptions,
  ).then<NRole.RoleId.User.Post.Response>(handleResponse);
}

function removeUsersFromRole({
  body,
  projectId,
  roleId,
}: RoleProjectIds &
  Body<NRole.RoleId.User.Delete.Request>): Promise<NRole.RoleId.User.Delete.Response> {
  const requestOptions = {
    method: 'DELETE',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${projectId}/role/${roleId}/user`,
    requestOptions,
  ).then<NRole.RoleId.User.Delete.Response>(handleResponse);
}

function login({
  email,
  password,
}: NAuth.Post.Request): Promise<NAuth.Post.Response> {
  const requestOptions = {
    method: 'POST',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      email,
      password,
    }),
  };
  return fetch(
    `${apiUrl}${API.DEFAULT}/auth`,
    requestOptions,
  ).then<NAuth.Post.Response>(handleResponse);
}

function verifyAuth(): Promise<NAuth.Verify.Get.Response> {
  const requestOptions = {
    method: 'get',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.DEFAULT}/auth/verify`,
    requestOptions,
  ).then<NAuth.Verify.Get.Response>(handleResponse);
}

function twoFactorVerify(
  request: NAuth.Verify.Post.Request,
): Promise<NAuth.Verify.Post.Response> {
  const requestOptions = {
    method: 'POST',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(request),
  };
  return fetch(
    `${apiUrl}${API.DEFAULT}/auth/verify`,
    requestOptions,
  ).then<NAuth.Verify.Post.Response>(handleResponse);
}

function forgotPassword({
  email,
}: NAuth.Forgot.Post.Request): Promise<NAuth.Forgot.Post.Response> {
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      email,
    }),
  };

  return fetch(
    `${apiUrl}${API.FORGOT_PASSWORD}`,
    requestOptions,
  ).then<NAuth.Forgot.Post.Response>(handleResponse);
}

function resetPassword({
  password,
  token,
}: NAuth.ResetToken.Post.Request & {
  token: string;
}): Promise<NAuth.ResetToken.Post.Response> {
  const requestOptions = {
    method: 'POST',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      password,
    }),
  };
  return fetch(
    `${apiUrl}${API.RESET_PASSWORD}/${token}`,
    requestOptions,
  ).then<NAuth.ResetToken.Post.Response>(handleResponse);
}

function getProjectUsers(
  projectId: ProjectSlugOrId,
): Promise<NProject.Project.User.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${projectId}/user`,
    requestOptions,
  ).then<NProject.Project.User.Get.Response>(handleResponse);
}

function addProjectUsers({
  projectId,
  body,
}: ProjectPathParam &
  Body<NProject.Project.User.Post.Request>): Promise<NProject.Project.User.Post.Response> {
  const requestOptions = {
    method: 'POST',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${projectId}/user`,
    requestOptions,
  ).then<NProject.Project.User.Post.Response>(handleResponse);
}

function deleteProjectUsers({
  projectId,
  body,
}: ProjectPathParam & Body<NProject.Project.User.Post.Request>): Promise<
  IAdminUser[]
> {
  const requestOptions = {
    method: 'DELETE',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${projectId}/user`,
    requestOptions,
  ).then<IAdminUser[]>(handleResponse);
}

const userService = {
  listUsers,
  getUser,
  getUserLoginHistory,
  addUser,
  updateUser,
  deleteUser,
  getUserProjects,
  getSelf,
  updateSelf,
  update2fa,
  verify2fa,
  listSelfLoginHistory,
  listSelfPermissions,
  listSelfRoles,
  getRoleUsers,
  getUserRoles,
  addUsersToRole,
  removeUsersFromRole,
  login,
  verifyAuth,
  twoFactorVerify,
  forgotPassword,
  resetPassword,
  getProjectUsers,
  addProjectUsers,
  deleteProjectUsers,
};
export default userService;
