import { Checkbox, Grid, Skeleton, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Colours } from '../../constants/designConstants';
import {
  AllPermissionActions,
  IPermission,
  PermissionAction,
  PermissionName,
} from '../../services/transportTypes/BaseTypes';

type PlaceholderPermission = {
  name: number;
  isPlaceholder: true;
  isLoading: true;
}

type LoadedPermission = {
  isPlaceholder: false;
  isLoading: boolean;
} & Pick<IPermission, 'name' | 'label' | PermissionAction>

type EditorPermission = PlaceholderPermission | LoadedPermission;

interface EditorRowProps
  extends Pick<
    PermissionsCheckboxEditorProps, 'onChange' | 'disabled'
  > {
  permission: EditorPermission;
}

const EditorRow = (props: EditorRowProps) => {
  const { permission, onChange, disabled } = props;

  return (
    <Grid container>
      <Grid
        item
        xs={4}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {permission.isPlaceholder ? (
          <Skeleton variant="text" sx={{width: '100%'}} />
        ) : (
          <Typography variant="body1">{permission.label}</Typography>
        )}
      </Grid>
      {AllPermissionActions.map((action) => (
        <Grid
          key={action}
          item
          xs={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {permission.isLoading ? (
            <Skeleton variant="rounded" height={22} width={22} sx={{m: 1.25}} />
          ) : (
            <Checkbox
              // eslint-disable-next-line react/destructuring-assignment
              checked={permission[action]}
              onChange={() => onChange(permission.name, action)}
              disabled={disabled}
              sx={{
                '&.Mui-checked': {
                  color: Colours.Red.Primary,
                },
              }}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

const placeholderList = new Array(10).fill(0).map<PlaceholderPermission>((_, index) => ({
  name: index,
  isPlaceholder: true,
  isLoading: true,
}))

export interface PermissionsCheckboxEditorProps {
  permissions: Pick<IPermission, 'name' | 'label' | PermissionAction>[];
  onChange: (name: PermissionName, action: PermissionAction) => void;
  listLoading?: boolean;
  valuesLoading?: boolean;
  disabled?: boolean;
}

const PermissionsCheckboxEditor = (
  props: PermissionsCheckboxEditorProps,
): JSX.Element => {
  const { permissions, onChange, listLoading, valuesLoading, disabled } = props;

  const list: EditorPermission[] = listLoading ? placeholderList : permissions.map(p => ({
    ...p,
    isPlaceholder: false,
    isLoading: valuesLoading ?? false,
  }))

  return (
    <Stack alignItems="stretch">
      <Grid container sx={{ mb: 1 }}>
        <Grid container item xs={4}>
          <Typography variant="h3">Permission Type</Typography>
        </Grid>
        <Grid container item xs={2} justifyContent="center">
          <Typography variant="h3">Create</Typography>
        </Grid>
        <Grid container item xs={2} justifyContent="center">
          <Typography variant="h3">Read</Typography>
        </Grid>
        <Grid container item xs={2} justifyContent="center">
          <Typography variant="h3">Update</Typography>
        </Grid>
        <Grid container item xs={2} justifyContent="center">
          <Typography variant="h3">Delete</Typography>
        </Grid>
      </Grid>

      {list.map((perm) => (
        <EditorRow onChange={onChange} key={perm.name} permission={perm} disabled={disabled}/>
      ))}
    </Stack>
  );
};
export default PermissionsCheckboxEditor;
