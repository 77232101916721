import { Box, IconButton, Skeleton, Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import queryConstants from '../../constants/queryConstants';
import { Key } from '../../utilities/frontendTypes';
import { parseIntOrDefault } from '../../utilities/functions';
import { Filter } from './TableDefault/Filter';
import FilterOverlay from './TableDefault/FilterOverlay';
import TableDefaultHead from './TableDefault/TableDefaultHead';
import TableDefaultToolbar from './TableDefault/TableDefaultToolbar';
import { getComparator, stableSort } from './TableDefault/TableUtils';

export type Order = 'asc' | 'desc';

// Type used for the tables header
export interface RowData {
  id: Key;
}

export interface ColumnData<Data extends RowData> {
  id: keyof Data;
  type: 'string' | 'number' | 'date';
  disablePadding?: boolean;
  label?: string;
  isLoading?: boolean | ((item: Data) => boolean);
  formatter?: (value: any) => React.ReactNode;
}

interface Action<Data extends RowData> {
  icon: React.ElementType;
  action: (item: Data) => void;
  tooltip?: string;
  key: Key;
}

export interface TableDefaultProps<Data extends RowData> {
  columns: ColumnData<Data>[];
  rows: Data[];
  isLoading?: boolean;
  isReadOnly?: boolean;
  filters: Filter<Data>[];
  onFiltersUpdate?: (filters: Filter<Data>[]) => void;
  actions?: Action<Data>[];
  onMassDelete?: (items: Data[]) => void;
  onAddNew?: () => void;
  onRowSelect?: (selectedIndexes: Key[]) => void;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectOnlyOneRow?: boolean;
  hideEmptyRows?: boolean;
  hideToolbar?: boolean;
  hideFilterButton?: boolean;
  hideAddButton?: boolean;
  hideDeleteButton?: boolean;
  hideDownloadButton?: boolean;
  toDownload?: any[];
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    row: {
      verticalAlign: 'center',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    actionColumn: {
      width: 1,
      whiteSpace: 'nowrap',
    },
    actionCell: {
      width: 1,
      whiteSpace: 'nowrap',
    },
    actionIcons: {
      fontSize: 19,
      margin: 8,
    },
  }),
);

function TableDefault<Data extends RowData>(
  props: TableDefaultProps<Data>,
): JSX.Element {
  const {
    columns,
    rows,
    isLoading,
    filters,
    onFiltersUpdate,
    actions,
    onMassDelete,
    onAddNew,
    isReadOnly,
    onRowSelect,
    onSelectAllClick,
    selectOnlyOneRow,
    hideEmptyRows,
    hideToolbar,
    hideAddButton,
    hideDeleteButton,
    hideFilterButton,
    hideDownloadButton = true,
    toDownload,
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const classes = useStyles();

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>(columns[0].id);
  const [showFilter, setShowFilter] = useState(false);

  const selected = searchParams.getAll(queryConstants.SELECTED);
  const pageId = parseIntOrDefault(searchParams.get(queryConstants.PAGE), 0);
  const rowsPerPage = parseIntOrDefault(
    searchParams.get(queryConstants.ROWS),
    5,
  );

  // Add page and row number to query string
  const handlePaginationQuery = (pageNum?: number, rowsNum?: number) => {
    searchParams.set(queryConstants.PAGE, (pageNum ?? pageId).toString());
    searchParams.set(queryConstants.ROWS, (rowsNum ?? rowsPerPage).toString());
    setSearchParams(searchParams);
  };

  const handleSelectedQuery = (newSelected: Key[]) => {
    // console.log('Selection changed', newSelected);

    const searchUpdate = new URLSearchParams(searchParams);
    searchUpdate.delete(queryConstants.SELECTED);
    newSelected.forEach((id) =>
      searchUpdate.append(queryConstants.SELECTED, id.toString()),
    );
    setSearchParams(searchUpdate);
  };

  const hasActions: boolean = actions !== undefined;
  const isSelected = (name: Key) => selected.includes(name.toString());

  const sliceStart = isLoading ? 0 : pageId * rowsPerPage;
  const sliceLength = isLoading
    ? 0
    : Math.min(rowsPerPage, rows.length - sliceStart);
  const sliceEnd = sliceStart + sliceLength;

  const emptyRows = hideEmptyRows ? 0 : rowsPerPage - sliceLength;

  const pageSlice = stableSort(rows, getComparator(order, orderBy)).slice(
    sliceStart,
    sliceEnd,
  );

  const handleSortChange = (property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onSelectAllClick) onSelectAllClick!(event);

    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      handleSelectedQuery(newSelected);
      return;
    }
    handleSelectedQuery([]);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLTableRowElement>,
    name: Key,
  ) => {
    const selectedIndex = selected.indexOf(name.toString());
    let newSelected: Key[] = [];

    if (selectOnlyOneRow && selectedIndex === -1) {
      // allow to select only one row
      newSelected[0] = name;
    } else if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    // Add selected array to query string
    handleSelectedQuery(newSelected);
    if (onRowSelect) {
      onRowSelect(newSelected); // callback to parent component
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    handlePaginationQuery(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    handlePaginationQuery(0, parseInt(event.target.value));
  };

  const handleToggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleDeleteSelected = () => {
    const toDelete: Data[] = [];

    selected.forEach((key) => {
      const item = rows.find((r) => r.id === key || r.id.toString() === key);
      if (item) {
        toDelete.push(item);
      }
    });

    if (onMassDelete && toDelete?.length) {
      onMassDelete(toDelete);
    }
  };

  // if a row is removed from the data, remove it from selection
  useEffect(() => {
    if (selected.length && !isLoading) {
      const retained = selected.filter((id) =>
        rows.some((r) => r.id === id || r.id.toString() === id),
      );
      if (retained.length !== selected.length) {
        handleSelectedQuery(retained);
      }
    }
  }, [rows, isLoading, selected]);

  return (
    <div className={classes.root}>
      <Paper className={`${classes.paper} table-paper`}>
        {!hideToolbar && (
          <TableDefaultToolbar
            numSelected={selected.length}
            disabled={isLoading}
            onClickFilter={handleToggleFilter}
            onClickDelete={handleDeleteSelected}
            onClickAdd={onAddNew}
            isReadOnly={isReadOnly}
            hideFilterButton={hideFilterButton}
            hideAddButton={hideAddButton}
            hideDeleteButton={hideDeleteButton}
            hideDownloadButton={hideDownloadButton}
            downloadData={toDownload}
          />
        )}
        <TableContainer className="table-container">
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <TableDefaultHead
              isLoading={isLoading}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onSortChange={handleSortChange}
              rowCount={rows.length}
              columns={columns}
              actionsColumn={hasActions}
              isReadOnly={isReadOnly}
            />
            <TableBody>
              {pageSlice.map((row, index) => {
                // console.log('Sorted item:', { row, index, rows, slice });
                const isItemSelected = isSelected(row.id);

                return (
                  <TableRow
                    hover
                    onClick={
                      !isLoading
                        ? (event) => handleClick(event, row.id)
                        : undefined
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    className={classes.row}
                  >
                    {!isReadOnly && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': `enhanced-table-checkbox-${index}`,
                          }}
                          disabled={isLoading}
                        />
                      </TableCell>
                    )}
                    {columns.map((c, i) => {
                      const cellLoading =
                        (typeof c.isLoading === 'function' &&
                          c.isLoading(row)) ||
                        (typeof c.isLoading === 'boolean' && c.isLoading);

                      return (
                        <TableCell
                          align={c.type === 'number' ? 'right' : 'left'}
                          key={c.id.toString()}
                        >
                          {cellLoading ? (
                            <Skeleton variant="text" />
                          ) : (
                            <Typography variant="body2">
                              {/* TODO this cast is gross */}
                              {c.formatter
                                ? c.formatter(row[c.id])
                                : (row[c.id] as string)}
                            </Typography>
                          )}
                        </TableCell>
                      );
                    })}
                    {hasActions && (
                      <TableCell classes={{ root: classes.actionCell }}>
                        {actions &&
                          actions.map((a) => (
                            <Tooltip title={a.tooltip ?? ''} key={a.key}>
                              <span>
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    a.action(row);
                                  }}
                                  disabled={isLoading}
                                >
                                  <Box
                                    component={a.icon}
                                    className={classes.actionIcons}
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          ))}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {emptyRows > 0 &&
                new Array(emptyRows).fill(0).map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow key={index}>
                    {!isReadOnly && <TableCell />}
                    {columns.map((c) => (
                      <TableCell sx={{ height: 77 }} key={c.id.toString()}>
                        {isLoading && <Skeleton variant="text" />}
                      </TableCell>
                    ))}
                    {hasActions && <TableCell />}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={isLoading ? 0 : rows.length}
          rowsPerPage={rowsPerPage}
          page={isLoading ? 0 : pageId}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FilterOverlay
        open={showFilter}
        columns={columns}
        filters={filters}
        onClose={handleToggleFilter}
        onSave={onFiltersUpdate}
      />
    </div>
  );
}

export default TableDefault;
