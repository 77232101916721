import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { IAdminUserSummary } from '../../services/transportTypes/NAdminUser';
import userService from '../../services/userService';

export default function (): [IAdminUserSummary[], boolean, () => void] {
  const queryClient = useQueryClient();

  const {
    data: usersSummary = [],
    isLoading,
    isPaused,
  } = useQuery([userService.listUsers.name], {
    queryFn: () => userService.listUsers(),
  });

  const invalidate = useCallback(
    () => queryClient.invalidateQueries([userService.listUsers.name]),
    [queryClient],
  );

  return [usersSummary, isLoading || isPaused, invalidate];
}
