// Filter options as immutable arrays
import { ColumnData, RowData } from '../TableDefault';
import typeConstants from '../../../constants/typeConstants';

export const NumericFilterOperators = [
  '<',
  '<=',
  '=',
  '>=',
  '>',
  '!=',
] as const;
export const StringFilterOperators = ['=', '!=', 'empty', 'not empty'] as const;

// Filter option types derived from array contents
export type NumericFilterOperator = typeof NumericFilterOperators[number];
export type StringFilterOperator = typeof StringFilterOperators[number];
export type FilterOperator = NumericFilterOperator | StringFilterOperator;

export interface StringFilter<Data extends RowData> {
  type: 'string';
  field: keyof Data;
  operator: StringFilterOperator | null;
  value: string | null;
}

export interface NumericFilter<Data extends RowData> {
  type: 'number';
  field: keyof Data;
  operator: NumericFilterOperator | null;
  value: string | null;
}

export interface DateFilter<Data extends RowData> {
  type: 'date';
  field: keyof Data;
  operator: NumericFilterOperator | null;
  value: string | null;
}

export interface EmptyFilter<Data extends RowData> {
  type: 'empty';
  field: null;
  operator: null;
  value: null;
}

export type Filter<Data extends RowData> =
  | StringFilter<Data>
  | NumericFilter<Data>
  | DateFilter<Data>
  | EmptyFilter<Data>;

// Filter util methods
export function isStringFilterOperator(s: string): s is StringFilterOperator {
  return StringFilterOperators.indexOf(s as any) !== -1;
}

export function isNumericFilterOperator(s: string): s is NumericFilterOperator {
  return NumericFilterOperators.indexOf(s as any) !== -1;
}

export const isFilterString = (
  operator: StringFilterOperator,
  item1: string | any[] | undefined | null,
  item2: string | undefined | null,
): boolean => {
  // Array handling is for permissions in roles.
  let item1ToString: string = '';
  if (Array.isArray(item1)) {
    item1.forEach((element) => {
      if (element.label && element.label !== '') {
        item1ToString += `, ${element.label}s`;
      } else item1ToString += `, ${element.name}s`;
    });
  }

  let Item1 = item1ToString.toLowerCase();
  if (Item1 === '' && item1) {
    Item1 = item1?.toString().toLowerCase();
  }
  const Item2 = item2?.toLowerCase();
  console.log(Item1);

  switch (operator) {
    case '=':
      return Item1.includes(Item2 ?? '');
    case '!=':
      return Item1 !== Item2;
    case 'empty':
      return !Item1;
    case 'not empty':
      return !!Item1;
    default:
      return false;
  }
};

export const isFilterNumber = (
  operator: NumericFilterOperator,
  item1: number | undefined | null,
  item2: number | undefined | null,
): boolean => {
  if (!item1 || !item2) return false;
  switch (operator) {
    case '=':
      return item1 === item2;
    case '!=':
      return item1 !== item2;
    case '<':
      return item1 < item2;
    case '>':
      return item1 > item2;
    case '<=':
      return item1 <= item2;
    case '>=':
      return item1 >= item2;
    default:
      return false;
  }
};

/*
  =, <=, >= ignores time and only use date format(dd/mm/yyyy) to compare
*/
export const isFilterDate = (
  operator: NumericFilterOperator,
  item1: Date | undefined | null,
  item2: Date | undefined | null,
): boolean => {
  if (!item1 || !item2) return false;

  const Item1 = new Date(item1);
  const Item2 = new Date(item2);

  // converts to string(dd/mm/yyyy)
  const item1DateFormat = Item1.toISOString().split('T')[0];
  const item2DateFormat = Item2.toISOString().split('T')[0];

  switch (operator) {
    case '=':
      return item1DateFormat === item2DateFormat;
    case '!=':
      return Item1 !== Item2;
    case '<':
      return Item1 < Item2;
    case '>':
      return Item1 > Item2;
    case '<=':
      return item1DateFormat <= item2DateFormat;
    case '>=':
      return item1DateFormat >= item2DateFormat;
    default:
      return false;
  }
};

export function applyFilter<Data extends RowData>(
  data: Data,
  filter: Filter<Data>,
): boolean {
  const { type, field, operator, value } = filter;

  if (!operator || !field) {
    return true;
  }

  switch (type) {
    case typeConstants.STRING: {
      const op = isStringFilterOperator(operator)
        ? operator
        : StringFilterOperators[0];
      return isFilterString(op, data[field] as any, value);
    }
    case typeConstants.NUMBER: {
      const op = isNumericFilterOperator(operator)
        ? operator
        : NumericFilterOperators[0];
      return isFilterNumber(
        op,
        data[field] as any,
        value ? Number.parseFloat(value) : null,
      );
    }
    case typeConstants.DATE: {
      const op = isNumericFilterOperator(operator)
        ? operator
        : NumericFilterOperators[0];
      return isFilterDate(
        op,
        data[field] as any,
        value ? new Date(value) : null,
      );
    }
    default:
      break;
  }

  return true;
}

// Filter constructor
export function ColumnToFilter<Data extends RowData>(
  column?: ColumnData<Data>,
  operator?: FilterOperator,
  value?: string,
): Filter<Data> {
  if (!column) {
    return {
      type: typeConstants.EMPTY,
      field: null,
      operator: null,
      value: null,
    };
  }
  const { type, id } = column;
  switch (type) {
    case typeConstants.STRING:
      return {
        type: typeConstants.STRING,
        field: id,
        operator:
          operator && isStringFilterOperator(operator)
            ? operator
            : StringFilterOperators[0],
        value: value ?? null,
      };
    case typeConstants.NUMBER:
      return {
        type: typeConstants.NUMBER,
        field: id,
        operator:
          operator && isNumericFilterOperator(operator)
            ? operator
            : NumericFilterOperators[0],
        value: value ?? null,
      };
    case typeConstants.DATE:
      return {
        type: typeConstants.DATE,
        field: id,
        operator:
          operator && isNumericFilterOperator(operator)
            ? operator
            : NumericFilterOperators[0],
        value: value ?? null,
      };
    default:
      throw new TypeError(`Unknown column type encountered ${type}`);
  }
}

export const handleOperator = (
  operator: string,
  item1: string,
  item2: string,
) => {
  switch (operator) {
    case '<':
      return item1 < item2;
    case '<=':
      return item1 <= item2;
    case '=':
      return item1 === item2;
    case '>=':
      return item1 >= item2;
    case '>':
      return item1 > item2;
    case '!=':
      return item1 !== item2;
    default:
      return null;
  }
};
