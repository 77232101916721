import React from 'react';
import { Box, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  CheckCircleOutline,
  HighlightOff,
  RemoveCircleOutline,
} from '@mui/icons-material';
import { Testable } from '../../utilities/frontendTypes';
import { toClassString } from '../../utilities/functions';

const useStyles = makeStyles({
  root: {},
  icon: {
    fontSize: 20,
  },
  checked: {
    color: '#3CBA94',
  },
  unchecked: {
    color: '#ED3024',
  },
  indeterminate: {
    color: '#EB5757',
  },
});

export type CheckboxState = boolean | 'indeterminate';

export interface IconCheckboxProps extends Testable {
  state: CheckboxState;
  className?: string;
}

const IconCheckbox = (props: IconCheckboxProps): JSX.Element => {
  const { state, 'data-testid': testId, className } = props;
  const classes = useStyles();

  let icon: JSX.Element;
  switch (state) {
    case 'indeterminate':
      icon = (
        <RemoveCircleOutline
          className={toClassString(classes.icon, classes.indeterminate)}
          data-testid={testId ? `${testId}-icon` : undefined}
        />
      );
      break;
    case true:
      icon = (
        <CheckCircleOutline
          className={toClassString(classes.icon, classes.checked)}
          data-testid={testId ? `${testId}-icon` : undefined}
        />
      );
      break;
    default:
      icon = (
        <HighlightOff
          className={toClassString(classes.icon, classes.unchecked)}
          data-testid={testId ? `${testId}-icon` : undefined}
        />
      );
      break;
  }

  return (
    <Box
      className={toClassString(className, classes.root)}
      // size="small"
      data-testid={testId}
    >
      {icon}
    </Box>
  );
};

export default IconCheckbox;
