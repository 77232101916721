import { useQuery } from '@tanstack/react-query';
import projectService from '../../services/projectService';
import { IProjectSummary } from '../../services/transportTypes/NProject';

export default (includeUnassigned = false):
  [IProjectSummary[], boolean] => {
  const { data, isLoading } = useQuery<IProjectSummary[]>([projectService.listProjects.name], () => projectService.listProjects());

  return [data?.filter((p) => includeUnassigned || p.assigned) ?? [], isLoading];
};
