import { AddCircle } from '@mui/icons-material';
import { Alert, Grid, IconButton, Skeleton, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent } from 'react';
import {
  AllDocumentFieldTypes,
  DocumentFieldType,
  DocumentSchemaField,
} from '../../services/transportTypes/NCms';
import { findMaxId, numbersUpTo } from '../../utilities/functions';
import HeaderComponent from '../base/HeaderComponent';
import InputDefault from '../base/InputDefault';
import SchemaFieldCard from './SchemaFieldCard';

export type SchemaEditorField = Pick<
  DocumentSchemaField,
  'id' | 'name' | 'type'
>;

export interface SchemaEditorProps {
  name: string;
  fields: SchemaEditorField[];
  errors?: string[];
  isLoading?: boolean;
  onNameChange: (name: string) => void;
  onFieldsChange: (list: SchemaEditorField[]) => void;
}

const SchemaEditor = (props: SchemaEditorProps): JSX.Element => {
  const { name, fields, errors, isLoading, onNameChange, onFieldsChange } =
    props;

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    onNameChange(event.target.value);
  };

  const handleCreate = () => {
    const newId = findMaxId(fields) + 1;
    onFieldsChange([
      ...fields,
      {
        id: newId,
        name: '',
        type: AllDocumentFieldTypes[0],
      },
    ]);
  };

  const handleChange = (
    id: number,
    fieldName: string,
    type: DocumentFieldType,
  ) => {
    onFieldsChange(
      fields.map((f) => {
        if (f.id === id) {
          return {
            ...f,
            name: fieldName,
            type,
          };
        }
        return f;
      }),
    );
  };

  const handleReorder = (id: number, up: boolean) => {
    const index = fields.findIndex((f) => f.id === id);
    const otherIndex = up ? index - 1 : index + 1;

    const list = fields.slice();
    list[index] = fields[otherIndex];
    list[otherIndex] = fields[index];

    onFieldsChange(list);
  };

  const handleDelete = (id: number) => {
    onFieldsChange(fields.filter((f) => f.id !== id));
  };

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={4}>
        <HeaderComponent title="Details" />
        <Stack direction="column" spacing={2}>
          {isLoading && <Skeleton variant="rounded" height={56} />}
          {!isLoading && (
            <InputDefault
              label="Name"
              placeholder="Name"
              value={name}
              onChange={handleNameChange}
              error={errors?.includes('name')}
            />
          )}
          <Alert severity="warning">
            <Typography variant="body2">
              Field names must start with a letter, and can only include
              letters, numbers, and &lsquo;_&rsquo;.
            </Typography>
            <Typography variant="body2">
              Don&apos;t worry though, the documents can be named whatever you
              would like.
            </Typography>
          </Alert>
        </Stack>
      </Grid>
      <Grid item xs={8}>
        <HeaderComponent title="Fields">
          <IconButton onClick={handleCreate}>
            <AddCircle />
          </IconButton>
        </HeaderComponent>
        <Stack spacing={2}>
          {isLoading &&
            numbersUpTo(4).map((i) => (
              <Skeleton key={i} variant="rounded" height={78} />
            ))}

          {!isLoading &&
            fields.map((f, i) => (
              <SchemaFieldCard
                key={f.id}
                name={f.name}
                type={f.type}
                errors={errors
                  ?.filter((e) => e.startsWith(`field.${f.id}`)) // only pass errors for the field
                  .map((e) => e.split('.').slice(2).join('.'))} // clip the names
                isFirst={i === 0}
                isLast={i === fields.length - 1}
                onChange={(fieldName, type) =>
                  handleChange(f.id, fieldName, type)
                }
                onReorder={(up) => handleReorder(f.id, up)}
                onDelete={() => handleDelete(f.id)}
              />
            ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default SchemaEditor;
