import React from 'react';

export interface BadgeCircleProps {
  icon: React.ReactNode;
  className: string;
  size: string;
}

const BadgeCircle = (props: BadgeCircleProps): JSX.Element => {
  const { icon, size, className } = props;
  return (
    <div className={`${className} circle`} style={{ width: size, height: size }}>
      {icon}
    </div>
  );
};

export default BadgeCircle;
