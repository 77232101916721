import { useQuery } from '@tanstack/react-query';
import serviceService from '../../services/serviceService';
import { IService } from '../../services/transportTypes/NService';

export default function (): [IService[], boolean] {
  const { data, isLoading } = useQuery(['listServices'], () =>
    serviceService.listServices(),
  );
  return [data ?? [], isLoading];
}
