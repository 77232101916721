import { useQuery } from '@tanstack/react-query';
import { IPermission, IProject } from '../../services/transportTypes/BaseTypes';
import {ILoginHistory} from "../../services/transportTypes/NAdminUser";
import {IRole} from "../../services/transportTypes/NRole";
import userService from '../../services/userService';

export default function (): [ILoginHistory[], boolean] {
  const { data, isLoading } = useQuery<ILoginHistory[]>(
    [userService.listSelfLoginHistory.name],
    () => userService.listSelfLoginHistory(),
  );

  return [data ?? [], isLoading];
}
