import { Delete, Edit } from '@mui/icons-material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../components/base/PageHeader';
import { BodyPanel } from '../components/base/Panel';
import TableDefault, { ColumnData } from '../components/base/TableDefault';
import pathGenerators from '../constants/pathGenerators';
import AuthorizedPage from '../containers/AuthorizedPage';
import cmsService from '../services/cmsService';
import { IDocumentSchema } from '../services/transportTypes/NCms';
import { formatDate } from '../utilities/functions';
import useAlert from '../utilities/hooks/useAlert';
import useDeleteConfirmation from '../utilities/hooks/useDeleteConfirmation';
import useDocumentSchemaList from '../utilities/hooks/useDocumentSchemaList';
import useProject from '../utilities/hooks/useProject';
import useUrlFilters from '../utilities/hooks/useUrlFilters';

const columns: ColumnData<IDocumentSchema>[] = [
  {
    id: 'id',
    type: 'number',
  },
  {
    id: 'name',
    type: 'string',
    label: 'Schema Name',
  },
  {
    id: 'fields',
    type: 'string',
    label: 'Fields',
    formatter: (fields: IDocumentSchema['fields']) =>
      fields.map((f) => f.name).join(', '),
  },
  {
    id: 'created_at',
    type: 'date',
    label: 'Created at',
    formatter: (date: IDocumentSchema['created_at']) => formatDate(date),
  },
  {
    id: 'updated_at',
    type: 'date',
    label: 'Last updated at',
    formatter: (date: IDocumentSchema['updated_at']) =>
      date && formatDate(date),
  },
];

const DocSchemas = (): JSX.Element => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const alert = useAlert();

  const [project, projectLoading] = useProject();
  const [projectSchemas, schemasLoading] = useDocumentSchemaList(project);

  const [filters, setFilters, applyFilters] = useUrlFilters<IDocumentSchema>();

  const deleteSchemaMutation = useMutation({
    mutationKey: [cmsService.deleteSchema.name],
    mutationFn: (schema: IDocumentSchema) =>
      cmsService.deleteSchema({
        projectId: schema.project_id,
        schemaId: schema.id,
      }),
    onError: (err: Error) => {
      console.error(err);
      alert({
        message: err.message,
        severity: 'error',
      });
    },
  });

  const performDelete = (items: IDocumentSchema[]) => {
    console.log('Delete Schemas', items);
    Promise.all(items.map((s) => deleteSchemaMutation.mutate(s)))
      .then(() => {
        alert(
          `${
            items.length === 1 ? items[0].name : `${items.length} schemas`
          } deleted`,
        );
        return queryClient.resetQueries([
          cmsService.listProjectSchemas.name,
          { slug: project?.slug },
        ]);
      })
      .catch((err: Error) => {
        console.error(err);
        alert({
          message: err.message,
          severity: 'error',
        });
      });
  };
  const [DeleteConfirmation, markForDelete] = useDeleteConfirmation({
    performDelete,
    summary: (items) =>
      items.length === 1 ? items[0].name : `${items.length} schemas`,
  });

  const filteredSchemas = applyFilters(projectSchemas);

  const handleCreate = () => {
    console.log('Create Schema');
    navigate(pathGenerators.DOC_SCHEMA_CREATE(project?.slug));
  };

  const handleEdit = (item: IDocumentSchema) => {
    // console.log('Edit Schema', item);
    navigate(pathGenerators.DOC_SCHEMA_EDIT(project?.slug, item.id.toString()));
  };

  const handleDelete = (item: IDocumentSchema | IDocumentSchema[]) => {
    if (Array.isArray(item)) {
      markForDelete(item);
    } else {
      markForDelete([item]);
    }
  };

  return (
    <AuthorizedPage>
      <PageHeader
        title="CMS - Document Schemas"
        hintMessage="Define schemas for your cms documents."
        displayGoBack
      />
      <BodyPanel>
        <TableDefault
          columns={columns}
          rows={filteredSchemas}
          filters={filters}
          onFiltersUpdate={setFilters}
          onMassDelete={handleDelete}
          onAddNew={handleCreate}
          isLoading={projectLoading || schemasLoading}
          actions={[
            {
              icon: Edit,
              action: handleEdit,
              tooltip: 'Edit',
              key: 'edit',
            },
            {
              icon: Delete,
              action: handleDelete,
              tooltip: 'Delete',
              key: 'delete',
            },
          ]}
        />
      </BodyPanel>
      {DeleteConfirmation}
    </AuthorizedPage>
  );
};

export default DocSchemas;
