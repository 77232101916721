import { Box, FormControl, FormControlProps, InputLabel } from '@mui/material';
import React from 'react';
import { combineSxProp } from '../../utilities/functions';

export interface InputPanelProps extends FormControlProps {
  label: string;
}

const InputPanel = (props: InputPanelProps): JSX.Element => {
  const { label, sx, children, error, ...others } = props;
  return (
    <FormControl
      sx={combineSxProp({ width: '100%' }, sx)}
      error={error}
      {...others}
    >
      <InputLabel shrink variant="outlined">
        {label}
      </InputLabel>
      <Box
        component="fieldset"
        sx={combineSxProp(
          (theme) => ({
            border: '1px solid',
            borderRadius: 1,
            px: 1,
            borderColor: error
              ? theme.palette.error.main
              : 'rgba(0, 0, 0, 0.23)',

            '&:hover': {
              borderColor: error
                ? theme.palette.error.main
                : 'rgba(0, 0, 0, 0.87)',
            },
          }),
          sx,
        )}
      >
        <Box
          component="legend"
          sx={{ px: 0.5, fontSize: '0.75em', visibility: 'hidden', height: 0 }}
        >
          {label}
        </Box>
        <Box sx={{ px: 0.75 }}>{children}</Box>
      </Box>
    </FormControl>
  );
};

export default InputPanel;
