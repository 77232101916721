import { parseISO } from 'date-fns';
import { stringify } from 'qs';
import API from '../constants/apiConstants';
import addAuthHeader from '../utilities/auth';
import handleResponse from '../utilities/handleResponse';
import {
  AnalyticsRecord,
  IAnalyticsRecord,
  NAnalytics,
} from './transportTypes/NAnalytics';
import { NProject } from './transportTypes/NProject';

const apiUrl = process.env.REACT_APP_API;

function buildQueryString(params: Parameters<typeof stringify>[0]) {
  return stringify(params);
}

function hydrateAnalytics<
  Record extends IAnalyticsRecord<Name, Data, Date>,
  Name extends string = Record['name'],
  Data extends object = Record['data'],
>(event: IAnalyticsRecord<Name, Data, string>): Record {
  return {
    ...event,
    created_at: new Date(event.created_at),
  } as Record;
}

interface getAnalyticsDataArgs {
  projectSlug: string;
  params: NAnalytics.Get.Params;
}
function getAnalyticsData<Record extends AnalyticsRecord>(
  args: getAnalyticsDataArgs,
): Promise<NAnalytics.Get.Response<Record>> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  const queryString = buildQueryString(args.params);
  return fetch(
    `${apiUrl}${API.PROJECT}/${args.projectSlug}${API.ANALYTICS}/?${queryString}`,
    requestOptions,
  )
    .then<
      NAnalytics.Get.Response<
        IAnalyticsRecord<Record['name'], Record['data'], string>
      >
    >(handleResponse)
    .then((resp) => ({
      ...resp,
      results: resp.results.map((r) => hydrateAnalytics<Record>(r)),
    }));
}

function getLoginData(
  projectSlug: string,
): Promise<NProject.Project.History.Login.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${projectSlug}${API.HISTORY}${API.LOGIN_DATA}`,
    requestOptions,
  )
    .then<NProject.Project.History.Login.Get.Response<string>>(handleResponse)
    .then(({ login, location }) => ({
      login: login.map(({ created_at, updated_at, ...others }) => ({
        ...others,
        created_at: parseISO(created_at),
        updated_at: parseISO(updated_at),
      })),
      location: location.map(({ created_at, ...others }) => ({
        ...others,
        created_at: parseISO(created_at),
      })),
    }));
}

const analyticsService = {
  getAnalyticsData,
  getLoginData,
};

export default analyticsService;
