import {
  Button,
  ButtonProps,
  Fade,
  LinearProgress,
  Stack,
} from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import React from 'react';
import { Colours } from '../../constants/designConstants';
import { KeysOf } from '../../services/transportTypes/BaseTypes';
import { combineSxProp } from '../../utilities/functions';

const baseStyles: SystemStyleObject = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'stretch',
  borderRadius: 10,
  fontWeight: 700,
};

const sizeSmall: SystemStyleObject = {
  px: 2,
  py: 1.5,
  fontSize: 16,
};

const sizeMedium: SystemStyleObject = {
  px: 5,
  py: 2,
  fontSize: 19,
};

const sizeLarge: SystemStyleObject = {
  px: 6,
  py: 2,
  fontSize: 25,
};

const variantPrimary: SystemStyleObject = {
  background: Colours.Red.Primary,
  color: Colours.White.Pure,
  '&:hover': {
    background: Colours.Red.Darkened,
  },
  '&:disabled': {
    background: Colours.Red.Faded,
    color: Colours.White.Darkened,
  },
};

const variantSecondary: SystemStyleObject = {
  background: Colours.Blue.Primary,
  color: Colours.White.Pure,
  '&:hover': {
    background: Colours.Blue.Darkened,
  },
  '&:disabled': {
    background: Colours.Blue.Faded,
    color: Colours.White.Darkened,
  },
};

const variantSubtle: SystemStyleObject = {
  background: Colours.White.Pure,
  color: Colours.Black.Pure,
  border: '1px solid #dee6eb',
  borderRadius: 1.5,
  fontWeight: 'normal',
  textTransform: 'none',
  '&:hover': {
    background: Colours.White.Darkened,
  },
  '&:disabled': {
    background: Colours.White.Pure,
    color: Colours.White.Darkened,
  },
};

export const ButtonVariants = {
  primary: 'primary',
  secondary: 'secondary',
  subtle: 'subtle',
} as const;

export type ButtonVariant = KeysOf<typeof ButtonVariants>;

export const ButtonSizes = {
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const;

export type ButtonSize = KeysOf<typeof ButtonSizes>;

export interface ButtonDefaultProps extends Omit<ButtonProps, 'variant'> {
  large?: boolean;
  alt?: boolean;
  isLoading?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
}

const ButtonDefault = React.forwardRef<HTMLButtonElement, ButtonDefaultProps>(
  (props, ref): JSX.Element => {
    const {
      variant = ButtonVariants.primary,
      size = ButtonSizes.medium,
      sx,
      disabled,
      isLoading,
      children,
      ...others
    } = props;

    let variantStyles = variantPrimary;
    switch (variant) {
      case ButtonVariants.primary:
        break;
      case ButtonVariants.secondary:
        variantStyles = variantSecondary;
        break;
      case ButtonVariants.subtle:
        variantStyles = variantSubtle;
        break;
      default:
        console.error(
          `Unknown button variant [${variant}], defaulting to primary`,
        );
    }

    let sizeStyles = sizeMedium;
    switch (size) {
      case ButtonSizes.small:
        sizeStyles = sizeSmall;
        break;
      case 'medium':
        break;
      case ButtonSizes.large:
        sizeStyles = sizeLarge;
        break;
      default:
        console.error(`Unknown button size [${size}], defaulting to medium`);
    }

    return (
      <Button
        {...others}
        ref={ref}
        variant="contained"
        sx={combineSxProp(baseStyles, variantStyles, sizeStyles, sx)}
        disabled={disabled || isLoading}
      >
        <Stack direction="row" justifyContent="center">
          {children}
        </Stack>
        <Fade in={isLoading}>
          <LinearProgress
            sx={{
              marginBottom: -0.5,
            }}
          />
        </Fade>
      </Button>
    );
  },
);
export default ButtonDefault;
