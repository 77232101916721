import { Edit } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import PageHeader from '../components/base/PageHeader';
import TableDefault, { ColumnData } from '../components/base/TableDefault';
import PATHS from '../constants/pathGenerators';
import AuthorizedPage from '../containers/AuthorizedPage';
import ContactFormService from '../services/contactFormService';
import { PermissionNames } from '../services/transportTypes/BaseTypes';
import { IContactForm } from '../utilities/backendTypes';
import usePermission from '../utilities/hooks/usePermission';
import useProject from '../utilities/hooks/useProject';

const ContactForm = (): JSX.Element => {
  const [currentProject] = useProject();
  const navigate = useNavigate();
  const aUser = usePermission(PermissionNames.AdminUser, 'read');
  const [editContactForm, setEditContactForm] = useState<
    IContactForm | undefined
  >();

  const { data: allForms = [], isLoading } = useQuery({
    queryFn: () => ContactFormService.getContactForms(currentProject!.slug),
    queryKey: [ContactFormService.getContactForms.name, currentProject?.slug],
    enabled: !!currentProject,
  });

  const columns: ColumnData<IContactForm>[] = [
    {
      id: 'id',
      type: 'string',
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'name',
      type: 'string',
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'email_address',
      type: 'string',
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'subject',
      type: 'string',
      disablePadding: false,
      label: 'Subject',
    },
  ];

  const handleEdit = (contactForm: IContactForm) => {
    navigate(PATHS.CONTACT_FORM_MANAGEMENT(currentProject?.slug), {
      state: {
        currentForm: contactForm,
      },
    });
  };

  const tableActions = [
    {
      icon: Edit,
      action: handleEdit,
      tooltip: 'Edit',
      key: 'edit',
    },
  ];

  if (editContactForm) {
    return (
      <Navigate
        to={PATHS.CONTACT_FORM_MANAGEMENT(currentProject?.slug)}
        state={{ contactFormId: editContactForm.id }}
      />
    );
  }

  const handleAddUser = () => {
    navigate(PATHS.CONTACT_FORM_MANAGEMENT(currentProject?.slug), {
      state: { isNewContactForm: true },
    });
  };

  return (
    <AuthorizedPage>
      <PageHeader
        title="Contact Form"
        hintMessage="View and manage contact forms"
      />
      <Grid container justifyContent="space-between" className="review">
        <Grid container item className="table" xs={12}>
          <Grid container item className="dashboard-body project-management">
            <TableDefault<IContactForm>
              isLoading={isLoading}
              columns={columns}
              rows={allForms}
              filters={[]}
              actions={aUser ? tableActions : []}
              onAddNew={handleAddUser}
              hideAddButton={!aUser}
              hideDeleteButton={!aUser}
              hideFilterButton
              selectOnlyOneRow
            />
          </Grid>
        </Grid>
      </Grid>
    </AuthorizedPage>
  );
};

export default ContactForm;
