import { Box, Grid, Skeleton, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { ReactNode } from 'react';
import { VictoryContainer, VictoryLegend, VictoryPie } from 'victory';
import DESIGN from '../../../constants/designConstants';
import Panel from '../../base/Panel';
import GraphHeader from '../GraphHeader';

const colorScale = [
  DESIGN.GRAPH_RED,
  DESIGN.GRAPH_GREEN,
  DESIGN.GRAPH_LIGHT_BLUE,
  DESIGN.GRAPH_DARK_BLUE,
  DESIGN.COLOR_YELLOW,
  DESIGN.COLOR_PINK,
  DESIGN.COLOR_PALE_BROWN,
  DESIGN.COLOR_GREY,
];

// Data structure to represent each group/sector of the pie chart
export interface PieChartSector {
  id: string;
  label: string;
  value: number;
}

/**
 * Takes input slices, sorts them and then either groups excess slices into 'Other' or adds a
 * placeholder slice if no data is present.
 * @param data
 */
function tidySectors(data: PieChartSector[]): PieChartSector[] {
  if (data.length === 0) {
    return [
      {
        id: 'NoData',
        label: 'No Data Available',
        value: 1,
      },
    ];
  }

  const sorted = data.sort((a, b) => b.value - a.value);
  if (sorted.length > 7) {
    const otherSlices = sorted.slice(7);

    return [
      ...sorted.slice(0, 7),
      {
        id: 'Other Data',
        label: 'Other',
        value: otherSlices.reduce((acc, slice) => acc + slice.value, 0),
      },
    ];
  }
  return sorted;
}

export interface PieChartProps {
  name: string;
  category: string;
  sectorData: PieChartSector[];
  isLoading?: boolean;
  children?: ReactNode | ReactNode[];
}

const PieChart = (props: PieChartProps): JSX.Element => {
  const { name, category, sectorData, isLoading, children } = props;

  const theme = useTheme();
  const data = tidySectors(sectorData);

  return (
    <Panel
      sx={{
        display: 'flex',
        flexFlow: 'column',
        padding: 0,
        height: '100%',
        width: '100%',
      }}
    >
      <GraphHeader name={name} category={category}>
        {children}
      </GraphHeader>
      <Box flex="1 1 0">
        {isLoading ? (
          <Box sx={{ p: 3 }}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={7}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Skeleton variant="circular" width={230} height={230} />
              </Grid>
              <Grid
                item
                xs={5}
                sx={{
                  display: 'flex',
                  alignItems: 'stretch',
                  justifyContent: 'stretch',
                }}
              >
                <Stack alignItems="stretch" spacing={1} sx={{ flex: '1 1 0' }}>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <VictoryContainer
            width={400}
            height={230}
            preserveAspectRatio="xMidYMid "
          >
            <VictoryPie
              data={data}
              colorScale={colorScale}
              style={{
                data: {
                  stroke: '#fff',
                  strokeWidth: 5,
                },
                labels: {
                  display: 'none',
                },
              }}
              width={230}
              height={230}
              padding={20}
              standalone={false}
              y="value"
            />
            <VictoryLegend
              data={data.map((slice) => ({
                name: slice.label,
              }))}
              style={{
                labels: {
                  fontFamily: theme.typography.fontFamily,
                },
              }}
              orientation="vertical"
              rowGutter={-10}
              itemsPerRow={8}
              colorScale={colorScale}
              standalone={false}
              width={170}
              height={230}
              x={230}
              y={20}
            />
          </VictoryContainer>
        )}
      </Box>
    </Panel>
  );
};

export default PieChart;
