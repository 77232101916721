import { Grid } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonDefault from '../components/base/ButtonDefault';
import PageHeader from '../components/base/PageHeader';
import { BodyPanel } from '../components/base/Panel';
import ProjectsAndRoles from '../components/user/ProjectsAndRoles';
import UserPropertiesEditor, {
  UserPropertiesDictionary,
} from '../components/user/UserPropertiesEditor';
import MESSAGES from '../constants/messagesConstants';
import PathGenerators from '../constants/pathGenerators';
import AuthorizedPage from '../containers/AuthorizedPage';
import { IProject } from '../services/transportTypes/BaseTypes';
import { IRole } from '../services/transportTypes/NRole';
import userService from '../services/userService';
import useAlert from '../utilities/hooks/useAlert';
import useProjectList from '../utilities/hooks/useProjectList';

const UserCreate = (): JSX.Element => {
  const navigate = useNavigate();
  const alert = useAlert();

  const [allProjects, projectsLoading] = useProjectList(true);

  const [userProperties, setUserProperties] =
    useState<UserPropertiesDictionary>({
      name: '',
      email: '',
      two_factor_state: null,
    });
  const [toggledProjects, setToggledProjects] = useState<IProject['id'][]>([]);
  const [toggledRoles, setToggledRoles] = useState<IRole['id'][]>([]);
  const [fieldErrors, setFieldErrors] = useState<string[]>([]);

  const addUserMutation = useMutation({
    mutationFn: userService.addUser,
    mutationKey: ['addUser'],
    onSuccess: (data) => {
      alert({
        message: MESSAGES.USER_ADDED,
        severity: 'success',
      });
      navigate(PathGenerators.USER_EDIT(data.id.toString()));
    },
    onError: (error: string) => {
      console.error('Add user error', { error });
      alert({
        message: error,
        severity: 'error',
      });
    },
  });

  const workingProjects = allProjects.map((project) => ({
    ...project,
    assigned: toggledProjects.includes(project.id),
  }));
  const workingRoles = allProjects
    .flatMap((p) => p.roles)
    .map((projectRole) => ({
      ...projectRole,
      assigned: toggledRoles.includes(projectRole.id),
    }));

  const handlePropertyChange = (props: UserPropertiesDictionary) => {
    setUserProperties(props);
  };

  const toggleProject = (projectId: IProject['id']) => {
    if (toggledProjects.includes(projectId)) {
      setToggledProjects(toggledProjects.filter((p) => p !== projectId));
    } else {
      setToggledProjects([...toggledProjects, projectId]);
    }
  };

  const toggleRole = (projectId: IProject['id'], roleId: IRole['id']) => {
    if (toggledRoles.includes(roleId)) {
      setToggledRoles(toggledRoles.filter((r) => r !== roleId));
    } else {
      setToggledRoles([...toggledRoles, roleId]);
    }
  };

  const onSave = () => {
    const errors: string[] = [];
    if (!userProperties.name) {
      errors.push('name');
    }
    if (!userProperties.email) {
      errors.push('client');
    }
    if (fieldErrors.length) {
      alert({
        message: 'Please fill all mandatory fields.',
        severity: 'error',
      });
    }

    if (fieldErrors.length === 0) {
      const projects = workingProjects
        .filter((p) => p.assigned)
        .map<{
          id: IProject['id'];
          roles: { id: IRole['id'] }[];
        }>((p) => ({
          id: p.id,
          // Pass along the id prop of selected roles
          roles: workingRoles
            .filter((r) => r.project_id === p.id && r.assigned)
            .map((r) => ({ id: r.id })),
        }));

      addUserMutation.mutate({
        ...userProperties,
        projects,
      });
    } else {
      setFieldErrors(errors);
    }
  };

  return (
    <AuthorizedPage>
      <PageHeader title="Create User." hintMessage="" displayGoBack />
      <BodyPanel>
        <Grid
          container
          spacing={3}
          alignItems="stretch"
          alignContent="space-between"
        >
          <Grid item xs={3}>
            <UserPropertiesEditor
              values={userProperties}
              errors={fieldErrors}
              onChange={handlePropertyChange}
              isNewUser
            />
          </Grid>
          <Grid item xs={5}>
            <ProjectsAndRoles
              projects={workingProjects}
              roles={workingRoles}
              onAddProject={toggleProject}
              onRemoveProject={toggleProject}
              onAddRole={toggleRole}
              onRemoveRole={toggleRole}
              isLoading={projectsLoading}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <ButtonDefault
              onClick={onSave}
              isLoading={addUserMutation.isLoading}
            >
              Save
            </ButtonDefault>
          </Grid>
        </Grid>
      </BodyPanel>
    </AuthorizedPage>
  );
};

export default UserCreate;
