import React from 'react';
import {
  Button,
  Card,
  CardActionArea,
  Paper,
  Skeleton,
  Stack,
} from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import Typography from '@mui/material/Typography';
import DesignConstants from '../../constants/designConstants';
import Panel from '../base/Panel';

const containerStyle: SystemStyleObject = {
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
};

const buttonStyle: SystemStyleObject = {
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'stretch',
  borderBottom: `1px solid ${DesignConstants.COLOR_LIGHT_GREY}`,
  py: 2,
  '&:last-child': {
    border: 'none',
  },
};

const headerStyle: SystemStyleObject = {
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 15,
  textAlign: 'center',
  color: DesignConstants.COLOR_GREY,
};

const valueStyle: SystemStyleObject = {
  fontFamily: ['Inter', 'sans-serif'],
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 40,
  textAlign: 'center',
  color: 'black',
};

export interface SummaryButtonData {
  title: string;
  value?: number;
  onClick: () => void;
  isLoading?: boolean;
}

export interface SummaryButtonsComponentProps {
  data: SummaryButtonData[];
}

const SummaryButtons = (props: SummaryButtonsComponentProps): JSX.Element => {
  const { data } = props;

  return (
    <Panel sx={containerStyle}>
      {data.map((item) => (
        <Button key={item.title} onClick={item.onClick} sx={buttonStyle}>
          <Typography variant="h1" sx={headerStyle}>
            {item.title}
          </Typography>
          <Typography variant="body1" sx={valueStyle}>
            {item.isLoading ? <Skeleton variant="text" sx={{ mx: 2 }} /> : item.value || 'N/A'}
          </Typography>
        </Button>
      ))}
    </Panel>
  );
};

export default SummaryButtons;
