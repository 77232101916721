import { Box, Grid, Skeleton } from '@mui/material';
import React from 'react';
import {
  VictoryChart,
  VictoryLegend,
  VictoryLine,
  VictoryTheme,
} from 'victory';
import { IOptionsFilter } from '../../../constants/analyticsConstants';
import { increaseMinMax } from '../../../utilities/GraphUtils';
import FilterSelect from '../FilterSelect';
import GraphHeader from '../GraphHeader';

export interface DataPoint {
  x: Date;
  y: number;
}

export interface DataLine {
  title: string;
  colour: string;
  points: DataPoint[];
}

export interface LineChartProps {
  name: string;
  category: string;
  data: DataLine[];
  currentFilter: IOptionsFilter | null;
  filterOptions: IOptionsFilter[];
  onFilterChange: (filter: IOptionsFilter | null) => void;
  isLoading?: boolean;
}

const LineChart = (props: LineChartProps): JSX.Element => {
  const {
    data,
    name,
    category,
    currentFilter,
    filterOptions,
    onFilterChange,
    isLoading,
  } = props;

  const [min, max] = data
    .flatMap((line) => line.points) // collect all the points
    .map((point) => point.y) // extract y value
    .reduce(increaseMinMax, [0, 10]) // sensible default range to stop showing tiny floating points
    .map((x) => 1.1 * x); // Add some negative space around the domain

  return (
    <Grid
      className="line-chart"
      container
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid container item xs={12}>
        <GraphHeader name={name} category={category}>
          <FilterSelect
            selected={currentFilter}
            options={filterOptions}
            onChange={onFilterChange}
          />
        </GraphHeader>
      </Grid>
      <Grid container item xs={12}>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'stretch',
              justifyContent: 'stretch',
              p: 3,
              width: '100%',
            }}
          >
            <Skeleton variant="rounded" height={230} width="100%" />
          </Box>
        ) : (
          <VictoryChart
            animate={{
              duration: 1000,
              onLoad: { duration: 1000 },
            }}
            theme={VictoryTheme.material}
            scale={{ x: 'time', y: 'linear' }}
            height={230}
            width={650}
            domain={{ y: [min, max] }}
          >
            <VictoryLegend
              x={500}
              y={0}
              data={data.map((e) => ({
                name: e.title,
                symbol: {
                  fill: e.colour,
                },
              }))}
              centerTitle
              orientation="vertical"
              standalone={false}
            />
            {data.map((line) => (
              <VictoryLine
                key={line.title}
                style={{
                  data: { stroke: line.colour },
                  parent: { border: '1px solid #ccc' },
                }}
                data={line.points}
              />
            ))}
          </VictoryChart>
        )}
      </Grid>
    </Grid>
  );
};

export default LineChart;
