import { Divider, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import React, { ReactNode } from 'react';
import { combineSxProp } from '../../utilities/functions';

interface HeaderProps {
  title: string;
  sx?: SxProps;
  children?: ReactNode | ReactNode[];
}

const HeaderComponent = (props: HeaderProps): JSX.Element => {
  const { title, sx, children } = props;

  return (
    <Stack
      sx={combineSxProp(
        {
          mb: 3,
        },
        sx,
      )}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
      >
        <Typography variant="h2">{title}</Typography>
        <Stack direction="row" alignItems="center" spacing={1} height={0}>
          {children}
        </Stack>
      </Stack>
      <Divider
        sx={{
          borderWidth: 'thin',
          borderColor: 'rgba(0, 0, 0, 0.25)',
        }}
      />
    </Stack>
  );
};

export default HeaderComponent;
