export interface IOptionsFilter {
  id: string;
  display: string;
  value: number;
}
export const defaultOptionsFilters: IOptionsFilter[] = [
  {
    id: '0',
    display: 'Last 7 Days',
    value: 7,
  },
  {
    id: '1',
    display: 'Last 14 Days',
    value: 14,
  },
  {
    id: '2',
    display: 'Last 30 Days',
    value: 30,
  },
];
