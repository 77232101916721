/* eslint-disable react/no-unknown-property */
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box, IconButton } from '@mui/material';
import React, { ReactElement, ReactNode, useContext } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

function LeftArrow() {
  const { scrollPrev } = useContext(VisibilityContext);

  return (
    <IconButton size="large" onClick={() => scrollPrev()}>
      <KeyboardArrowLeft />
    </IconButton>
  );
}

function RightArrow() {
  const { scrollNext } = useContext(VisibilityContext);

  return (
    <IconButton size="large" onClick={() => scrollNext()}>
      <KeyboardArrowRight />
    </IconButton>
  );
}

export interface SliderItemProps {
  itemId: string;
  children?: ReactNode | ReactNode[];
}
export function SliderItem(props: SliderItemProps) {
  const { itemId, children } = props;

  // @ts-ignore
  return <div itemId={itemId}>{children}</div>;
}

export interface SliderProps {
  children: ReactElement | ReactElement[];
}

const Slider = (props: SliderProps): JSX.Element => {
  const { children } = props;

  return (
    <Box
      sx={{
        '& .scroll-container': {
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'center',
        },
        '& .scroll-item': {
          mx: 1,
        },
      }}
    >
      <ScrollMenu
        LeftArrow={<LeftArrow />}
        RightArrow={<RightArrow />}
        scrollContainerClassName="scroll-container"
        itemClassName="scroll-item"
      >
        {children}
      </ScrollMenu>
    </Box>
  );
};

export default Slider;
