import { useQuery } from '@tanstack/react-query';
import cmsService from '../../services/cmsService';
import { IProject } from '../../services/transportTypes/BaseTypes';
import { IDocument } from '../../services/transportTypes/NCms';

export default function (
  project?: IProject | null,
  id?: IDocument['id'] | null,
): [IDocument | null, boolean] {
  const { data = null, isLoading } = useQuery({
    queryKey: [
      cmsService.getDocument.name,
      {
        projectId: project?.slug,
        documentId: id,
      },
    ],
    queryFn: () =>
      cmsService.getDocument({
        projectId: project!.slug,
        documentId: id!,
      }),
    enabled: !!project && !!id,
  });

  return [data, isLoading];
}
