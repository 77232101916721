import API from '../constants/apiConstants';
import addAuthHeader from '../utilities/auth';
import { ProjectSlugOrId } from '../utilities/frontendTypes';
import handleResponse from '../utilities/handleResponse';
import { PermissionName } from './transportTypes/BaseTypes';
import { IRole, NDefaultRole, NRole } from './transportTypes/NRole';

const apiUrl = process.env.REACT_APP_API;

function getDefaultRoles(): Promise<NDefaultRole.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}/roles`,
    requestOptions,
  ).then<NDefaultRole.Get.Response>(handleResponse);
}

function getRoles(projectId: ProjectSlugOrId): Promise<NRole.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${projectId}/role`,
    requestOptions,
  ).then<NRole.Get.Response>(handleResponse);
}

interface addRoleArgs {
  projectId: ProjectSlugOrId;
  body: NRole.Post.Request;
}

function addRole({
  projectId,
  body,
}: addRoleArgs): Promise<NRole.Post.Response> {
  const requestOptions = {
    method: 'POST',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${projectId}/role`,
    requestOptions,
  ).then<NRole.Post.Response>(handleResponse);
}

function updateRole(role: IRole): Promise<NRole.RoleId.Put.Response> {
  const requestOptions = {
    method: 'put',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(role),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${role.project_id}/role/${role.id}`,
    requestOptions,
  ).then<NRole.RoleId.Put.Response>(handleResponse);
}

function deleteRole(role: IRole): Promise<NRole.RoleId.Delete.Response> {
  const requestOptions = {
    method: 'DELETE',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${role.project_id}/role/${role.id}`,
    requestOptions,
  ).then<NRole.RoleId.Delete.Response>(handleResponse);
}

interface getRolePermissionArgs {
  projectSlug: ProjectSlugOrId;
  roleId: number;
}

function getRolePermissions({
  projectSlug,
  roleId,
}: getRolePermissionArgs): Promise<NRole.RoleId.Permission.Get.Response> {
  const requestOptions = {
    method: 'GET',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
  };
  return fetch(
    `${apiUrl}${API.PROJECT}/${projectSlug}/role/${roleId}/permission`,
    requestOptions,
  ).then<NRole.RoleId.Permission.Get.Response>(handleResponse);
}

function updateRolePermission({
  pathParams,
  body,
}: {
  pathParams: {
    projectId: ProjectSlugOrId;
    roleId: IRole['id'];
    permissionName: PermissionName;
  };
  body: NRole.RoleId.Permission.PermissionName.Put.Request;
}): Promise<NRole.RoleId.Permission.Post.Response> {
  const requestOptions = {
    method: 'PUT',
    headers: addAuthHeader({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body),
  };

  return fetch(
    `${apiUrl}${API.PROJECT}/${pathParams.projectId}/role/${pathParams.roleId}/permission/${pathParams.permissionName}`,
    requestOptions,
  ).then<NRole.RoleId.Permission.Post.Response>(handleResponse);
}

const rolesService = {
  getDefaultRoles,
  updateRolePermission,
  getRolePermissions,
  deleteRole,
  updateRole,
  addRole,
  getRoles,
};

export default rolesService;
