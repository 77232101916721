import { AlertColor, SnackbarCloseReason } from '@mui/material';
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import CustomSnackbar from '../../components/base/CustomSnackbar';
import useUniqueId from '../hooks/useUniqueId';

export interface AlertOptions {
  message: string;
  severity: AlertColor;
  callback?: () => void;
}

interface IAlertContext {
  showAlert: (alert: AlertOptions | string) => void;
}

export const AlertContext = createContext<IAlertContext>({
  showAlert: () => {},
});

export interface AlertContextProviderProps {
  children?: ReactNode | ReactNode[];
}

export const AlertContextProvider = (props: AlertContextProviderProps) => {
  const { children } = props;

  const [_, nextId] = useUniqueId();

  const [open, setOpen] = useState(true);
  const [item, setItem] = useState<AlertOptions & { id: number }>();
  const [queue, setQueue] = useState<(AlertOptions & { id: number })[]>([]);

  const handleAddAlert = (alert: AlertOptions | string) => {
    const obj =
      typeof alert === 'string'
        ? {
            id: nextId(),
            message: alert,
            severity: 'success' as const,
          }
        : { id: nextId(), ...alert };
    console.log('Handling add', { item, queue, open, obj });
    setQueue([...queue, obj]);
  };

  const handleClose = (reason: SnackbarCloseReason | 'alert') => {
    // console.log('Handling close', { item, queue, open, reason });
    if (reason !== 'clickaway') {
      if (item && item.callback) {
        item.callback();
      }
      setOpen(false);
    }
  };

  const handleExited = () => {
    // console.log('Handling exited', { item, queue, open });
    if (item) {
      setItem(undefined);
    }
  };

  useEffect(() => {
    if (!item && queue.length > 0) {
      // console.log('Triggering next item', { item, queue, open });
      setItem(queue[0]);
      setQueue(queue.slice(1));
      setOpen(true);
    }
  });

  return (
    <AlertContext.Provider
      value={{
        showAlert: handleAddAlert,
      }}
    >
      {item && (
        <CustomSnackbar
          key={item.id}
          severity={item.severity}
          message={item.message}
          open={open}
          onClose={handleClose}
          onExited={handleExited}
        />
      )}
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;
