import { Dialog, Grid } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import React from 'react';
import { formatDate } from '../../utilities/functions';
import { ActivityLogRecord } from '../../utilities/hooks/useProjectActivityHistory';
import ButtonDefault from './ButtonDefault';
import TableDefault, { ColumnData } from './TableDefault';

interface TableRow {
  id: string;
  prevValue: string | null;
  value: string | null;
}

function renderValue(value: string | null) {
  switch (value) {
    case null:
      return <em>[null]</em>;
    case 'false':
      return <em>[removed]</em>;
    case 'true':
      return <em>[added]</em>;
    default:
      return value;
  }
}

const columns: ColumnData<TableRow>[] = [
  {
    id: 'id',
    type: 'string',
    label: 'Resource ID',
  },
  {
    id: 'prevValue',
    type: 'string',
    label: 'Previous Value',
    formatter: renderValue,
  },
  {
    id: 'value',
    type: 'string',
    label: 'New Value',
    formatter: renderValue,
  },
];

export interface ViewActivityOverlayProps {
  record?: ActivityLogRecord;
  allRecords: ActivityLogRecord[];
  onClose: () => void;
}

const ViewActivityOverlay = (props: ViewActivityOverlayProps): JSX.Element => {
  const { record, allRecords, onClose } = props;

  const previousRecords = record
    ? allRecords.filter(
        (r) => r.created_at <= record.created_at && r.id !== record.id,
      )
    : [];
  const rows = record?.items.map<TableRow>((item) => {
    const prevValue = previousRecords
      .flatMap((r) => r.items)
      .find((i) => i.resource === item.resource)?.value;

    return {
      id: item.resource,
      prevValue: prevValue ?? null,
      value: item.value,
    };
  });

  return (
    <Dialog open={!!record} onClose={onClose} maxWidth="xl">
      <DialogTitle>Change Details</DialogTitle>
      <DialogContent>
        <Grid container sx={{ mb: 1 }} alignItems="center">
          <Grid item xs={2}>
            <Typography variant="h3">User:</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>{record?.userName}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h3">Change Date:</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>{record && formatDate(record.created_at)}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h3">Detail message:</Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography>{record?.detail}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">Changes:</Typography>
          </Grid>
        </Grid>
        {/* <Divider */}
        {/*  sx={{ */}
        {/*    borderWidth: 'thin', */}
        {/*    borderColor: 'rgba(0, 0, 0, 0.25)', */}
        {/*  }} */}
        {/* /> */}

        {/* <HeaderComponent title="Changes" /> */}
        <TableDefault
          columns={columns}
          rows={rows ?? []}
          filters={[]}
          isReadOnly
          hideToolbar
          hideEmptyRows
        />
      </DialogContent>
      <DialogActions>
        <ButtonDefault onClick={onClose}>Close</ButtonDefault>
      </DialogActions>
    </Dialog>
  );
};

export default ViewActivityOverlay;
