import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { CSVLink } from 'react-csv';
import ButtonDefault, { ButtonSizes, ButtonVariants } from '../ButtonDefault';

interface TableDefaultToolbarProps {
  numSelected: number;
  downloadData?: any[];
  disabled?: boolean;
  onClickFilter?: () => void;
  onClickDelete?: () => void;
  onClickAdd?: () => void;
  isReadOnly?: boolean;
  hideFilterButton?: boolean;
  hideAddButton?: boolean;
  hideDownloadButton?: boolean;
  hideDeleteButton?: boolean;
}

const TableDefaultToolbar = (props: TableDefaultToolbarProps) => {
  const {
    numSelected,
    disabled,
    onClickFilter,
    onClickDelete,
    onClickAdd,
    isReadOnly,
    hideDownloadButton,
    hideAddButton,
    hideDeleteButton,
    hideFilterButton,
    downloadData,
  } = props;

  return (
    <Toolbar sx={{ px: 2 }}>
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} variant="body1">
          {numSelected} selected
        </Typography>
      ) : (
        <Box sx={{ flex: '1 1 100%' }} />
      )}
      {numSelected > 0 && !hideDeleteButton ? (
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            onClick={onClickDelete}
            size="large"
            disabled={disabled}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Stack
          className="tool-tip-wrapper"
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {!hideDownloadButton && downloadData && (
            <CSVLink
              data={downloadData}
              filename="export.csv"
              onClick={() => !disabled}
            >
              <Tooltip title="Download list">
                <span>
                  <ButtonDefault
                    variant={ButtonVariants.subtle}
                    size={ButtonSizes.small}
                    aria-label="Download list"
                    disabled={disabled}
                  >
                    Download
                    <CloudDownloadOutlinedIcon style={{ marginLeft: '10px' }} />
                  </ButtonDefault>
                </span>
              </Tooltip>
            </CSVLink>
          )}
          {!hideFilterButton && (
            <Tooltip title="Filter list">
              <span>
                <ButtonDefault
                  variant={ButtonVariants.subtle}
                  size={ButtonSizes.small}
                  onClick={onClickFilter}
                  disabled={disabled}
                >
                  Filter
                  <FilterListIcon />
                </ButtonDefault>
              </span>
            </Tooltip>
          )}
          {!isReadOnly && !hideAddButton && (
            <Tooltip title="Add">
              <span>
                <IconButton
                  aria-label="add item"
                  onClick={onClickAdd}
                  size="large"
                  disabled={disabled}
                >
                  <AddCircleIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </Stack>
      )}
    </Toolbar>
  );
};

export default TableDefaultToolbar;
