// Menu constants
const DESIGN = {
  COLOR_GREEN_BUTTON: '#0DA768',
  COLOR_GREEN_ARROW: '#B4EBCC',
  COLOR_GREEN: '#3EE1AA',
  COLOR_LIGHT_GREEN: '#D8F9EE',
  COLOR_YELLOW: '#F9AA31',
  COLOR_RED: '#ea4969',
  COLOR_LIGHT_RED: '#ec302433',
  COLOR_WHITE: '#FFFFFF',
  COLOR_BLACK: '#000000FF',
  COLOR_LIGHT_GREY: '#E4E4E4',
  COLOR_GREY: '#9e9e9e',
  COLOR_PINK: '#EC008C',
  COLOR_ICON_LIGHT_BLUE: '#BEDBFF',
  COLOR_BUTTON_LIGHT_BLUE: '#2CACE3',
  COLOR_DARK_BLUE: '#214882',
  // COLOR_GREY: '#A7A7A7',
  COLOR_PALE_BROWN: '#C19A69',
  COLOR_BLUE: '#22B0FC',
  COLOR_LIGHT_BLUE: '#22b0fc38',
  GRAPH_RED: '#ea4969',
  GRAPH_GREEN: '#3CBA94',
  GRAPH_LIGHT_BLUE: '#00A6DB',
  GRAPH_DARK_BLUE: '#2480ED',
};

export const Colours = {
  Red: {
    ExtraFaded: '#f7adbc',
    Faded: '#eb7189',
    Primary: '#ea4969',
    Darkened: '#ad2b45',
  },
  Green: {
    ExtraFaded: '#D8F9EE',
    Faded: '#7ce8c0',
    Primary: '#3EE1AA',
    Darkened: '#2ea67e',
  },
  Blue: {
    Faded: '#64a1ed',
    Primary: '#2480ed',
    Darkened: '#195aa8',
  },
  White: {
    Pure: '#ffffff',
    Darkened: '#e3e3e3',
  },
  Grey: {
    Faded: '#E4E4E4',
    Primary: '#9e9e9e',
    Darkened: '#808080',
  },
  Black: {
    Pure: '#000000',
    Text: '#1d1d1d',
    Faded: '#333333',
  },
};

export default DESIGN;
