import React, { Fragment } from 'react';
import { Box, Divider, LinearProgress, Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { SxProps, SystemStyleObject } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { Colours } from '../../../constants/designConstants';
import GraphHeader, { GraphHeaderProps } from '../GraphHeader';
import { Key } from '../../../utilities/frontendTypes';
import Panel from '../../base/Panel';
import { combineSxProp } from '../../../utilities/functions';
import { ScrollBars } from '../../../DashboardTheme';

const barPrimary: SystemStyleObject<Theme> = {
  background: Colours.Red.ExtraFaded,
  '& .MuiLinearProgress-bar': {
    background: Colours.Red.Primary,
  },
};

const barSecondary: SystemStyleObject<Theme> = {
  background: Colours.Green.ExtraFaded,
  '& .MuiLinearProgress-bar': {
    background: Colours.Green.Primary,
  },
};

function PlaceholderRow(props: { percentage?: boolean; primary?: boolean }) {
  const { percentage, primary } = props;

  return (
    <>
      <Skeleton
        variant="text"
        component="p"
        sx={(theme) => ({ ml: 3, fontSize: theme.typography.body1.fontSize })}
      />
      {percentage && (
        <LinearProgress
          sx={combineSxProp(
            {
              borderRadius: 3,
              border: 0,
              color: 'white',
              height: 10,
            },
            primary ? barPrimary : barSecondary,
          )}
        />
      )}
      <Skeleton
        variant="text"
        component="p"
        sx={(theme) => ({ mr: 3, fontSize: theme.typography.body1.fontSize })}
      />
    </>
  );
}

export interface AnalyticsTableRow {
  id: Key;
  name: string;
  data: string | number;
  percentage?: number;
}

export interface AnalyticsTableProps {
  name: string;
  category: string;
  includePercentage?: boolean;
  data: AnalyticsTableRow[];
  isLoading?: boolean;
  headerChildren: GraphHeaderProps['children'];
  sx?: SxProps<Theme>;
}

function AnalyticsTable(props: AnalyticsTableProps): JSX.Element {
  const {
    name,
    category,
    includePercentage,
    data,
    isLoading,
    headerChildren,
    sx,
  } = props;

  return (
    <Panel
      sx={combineSxProp(
        {
          p: 0,
          display: 'flex',
          flexFlow: 'column',
          flex: '1 1 100%',
        },
        sx,
      )}
    >
      <GraphHeader name={name} category={category}>
        {headerChildren}
      </GraphHeader>
      {isLoading && (
        <Box
          sx={{
            overflowY: 'auto',
            display: 'grid',
            gridTemplateColumns: includePercentage ? '1fr 1fr 10%' : '1fr 10%',
            columnGap: 4,
            rowGap: 1,
            pb: 1,
            alignItems: 'center',
            flex: '0 1 auto',
            ...ScrollBars,
          }}
        >
          <PlaceholderRow percentage={includePercentage} primary />
          <Divider sx={{ gridColumn: 'span 3' }} />
          <PlaceholderRow percentage={includePercentage} />
          <Divider sx={{ gridColumn: 'span 3' }} />
          <PlaceholderRow percentage={includePercentage} />
          <Divider sx={{ gridColumn: 'span 3' }} />
          <PlaceholderRow percentage={includePercentage} />
          <Divider sx={{ gridColumn: 'span 3' }} />
          <PlaceholderRow percentage={includePercentage} />
        </Box>
      )}

      {!isLoading && (
        <Box
          sx={{
            overflowY: 'auto',
            display: 'grid',
            gridTemplateColumns: includePercentage
              ? 'fit-content(50%) 1fr auto'
              : '1fr auto',
            columnGap: 4,
            rowGap: 1,
            pb: 1,
            alignItems: 'center',
            flex: '0 1 auto',
            ...ScrollBars,
          }}
        >
          {data.map((row, index) => (
            <Fragment key={row.id}>
              {index !== 0 && <Divider sx={{ gridColumn: 'span 3' }} />}
              <Typography variant="body1" sx={{ ml: 3 }}>
                {row.name}
              </Typography>
              {includePercentage && (
                <LinearProgress
                  variant="determinate"
                  value={row.percentage}
                  sx={combineSxProp(
                    {
                      borderRadius: 3,
                      border: 0,
                      color: 'white',
                      height: 10,
                    },
                    index === 0 ? barPrimary : barSecondary,
                  )}
                />
              )}
              <Typography variant="body1" textAlign="right" sx={{ mr: 3 }}>
                {row.data}
              </Typography>
            </Fragment>
          ))}
        </Box>
      )}
    </Panel>
  );
}

export default AnalyticsTable;
