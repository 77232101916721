import { Grid, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import ButtonDefault, { ButtonSizes } from '../components/base/ButtonDefault';
import PATHS from '../constants/pathGenerators';
import UnauthorizedPage from '../containers/UnauthorizedPage';
import { NAuth } from '../services/transportTypes/NAuth';
import userService from '../services/userService';
import { useAppDispatch } from '../store/store';
import { loginSuccess } from '../store/userSlice';
import useAlert from '../utilities/hooks/useAlert';

const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const alert = useAlert();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const login = useMutation({
    mutationKey: [userService.login.name],
    mutationFn: userService.login,
    onError: (error) => {
      console.error('Login failed: ', error);
      alert({
        message: 'Login failed',
        severity: 'error',
      });
    },
    onSuccess: (data) => {
      dispatch(
        loginSuccess({
          token: data.token,
          twoFactorPending: !!data.twoFactorPending,
        }),
      );
      alert({
        message: 'Login success',
        severity: 'success',
      });
      if (data.twoFactorPending) {
        navigate(PATHS.TWO_FACTOR_LOGIN());
      } else {
        navigate(PATHS.HOME());
      }
    },
  });

  const onSubmit = (data: NAuth.Post.Request) => {
    login.mutate(data);
  };

  return (
    <UnauthorizedPage>
      <Grid
        className="login-container"
        container
        direction="column"
        item
        xs={12}
      >
        <Typography variant="title" sx={{ pb: 6 }}>
          Login
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                // eslint-disable-next-line no-useless-escape
                pattern:
                  /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
              }}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  label="Email"
                  placeholder="example@example.co"
                  className="login-container__input"
                  error={!!errors.email}
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  label="Password"
                  type="password"
                  className="login-container__input"
                  error={!!errors.password}
                  {...field}
                />
              )}
            />
          </div>
          <div className="login-container__button">
            <ButtonDefault
              onClick={handleSubmit(onSubmit)}
              size={ButtonSizes.large}
              isLoading={login.isLoading}
              sx={{ width: '100%' }}
              type="submit"
            >
              Login
            </ButtonDefault>
          </div>
          <div className="login-container__forgot-password">
            <Link to={PATHS.FORGOT_PASSWORD()}>
              <Typography variant="body1">Forgot your password?</Typography>
            </Link>
          </div>
        </form>
      </Grid>
    </UnauthorizedPage>
  );
};

export default Login;
