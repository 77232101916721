import { useQuery } from '@tanstack/react-query';
import databaseConstants from '../../constants/databaseConstants';
import useProject from './useProject';
import {
  PermissionAction,
  PermissionName,
} from '../../services/transportTypes/BaseTypes';
import { PermissionDetails } from '../backendTypes';
import userService from '../../services/userService';

export default (permission: PermissionName, action: PermissionAction) => {
  const [selectedProject] = useProject();

  const projectId = permission.startsWith('admin')
    ? databaseConstants.ADMIN_PROJECT_ID
    : selectedProject?.id;

  const { data: permissions } = useQuery<PermissionDetails[]>(
    [userService.listSelfPermissions.name],
    () => userService.listSelfPermissions(),
  );

  const permissionObject = permissions?.find(
    (p) => p.name === permission && (!projectId || p.project_id === projectId),
  );

  return permissionObject?.[action] ?? false;
};
