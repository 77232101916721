import { capitalize, Skeleton, Stack, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import React, {ChangeEvent, ReactNode} from 'react';
import {
  IAdminUser,
  TwoFactorState,
} from '../../services/transportTypes/NAdminUser';
import ButtonDefault, { ButtonVariants } from '../base/ButtonDefault';
import HeaderComponent from '../base/HeaderComponent';
import ResetPasswordButton from "./ResetPasswordButton";

type UserProperty = 'name' | 'email' | 'two_factor_state';
export type UserPropertiesDictionary = Pick<IAdminUser, UserProperty>;

export interface UserPropertiesEditorProps {
  values: UserPropertiesDictionary;
  errors: string[];
  isNewUser?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  sx?: SxProps;
  onChange?: (update: UserPropertiesDictionary) => void;
  children?: ReactNode | ReactNode[]
}

const UserPropertiesEditor = (
  props: UserPropertiesEditorProps,
): JSX.Element => {
  const {
    values,
    errors,
    isNewUser,
    isLoading,
    disabled,
    sx,
    onChange,
    children
  } = props;

  const handleFieldChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: UserProperty,
  ) => {
    const update = {
      ...values,
      [field]: event.target.value,
    };
    if (onChange) {
      onChange(update);
    }
  };

  return (
    <Stack alignItems="stretch" justifyContent="stretch" sx={sx}>
      <HeaderComponent title="Details" />
      {isLoading ? (
        <Skeleton variant="rounded" sx={{ height: '100%', minHeight: 200 }} />
      ) : (
        <Stack alignItems="stretch" spacing={2}>
          <TextField
            variant="outlined"
            label="Name"
            placeholder="Name"
            value={values.name}
            onChange={(e) => handleFieldChange(e, 'name')}
            error={errors.includes('name')}
            required
            disabled={disabled}
          />
          <TextField
            variant="outlined"
            label="Email"
            placeholder="example@example.co"
            value={values.email}
            onChange={(e) => handleFieldChange(e, 'email')}
            error={errors.includes('email')}
            required
            disabled={disabled}
          />
          {!isNewUser && (
            <>
              <TextField
                variant="outlined"
                label="Two Factor Authentication"
                value={capitalize(values.two_factor_state ?? 'disabled')}
                disabled
              />
            </>
          )}
          {children}
        </Stack>
      )}
    </Stack>
  );
};

export default UserPropertiesEditor;
