import { Grid } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import PageHeader from '../components/base/PageHeader';
import TableDefault, { ColumnData } from '../components/base/TableDefault';
import { applyFilter, Filter } from '../components/base/TableDefault/Filter';
import ReviewSummary from '../components/review/ReviewSummary';
import DB from '../constants/databaseConstants';
import AuthorizedPage from '../containers/AuthorizedPage';
import reviewService from '../services/reviewService';
import { IReview } from '../utilities/backendTypes';
import { Key } from '../utilities/frontendTypes';
import { formatDateFromString } from '../utilities/functions';
import useProject from '../utilities/hooks/useProject';

const Review = (): JSX.Element => {
  const [currentProject] = useProject();
  const [rejectState, setRejectState] = useState(false);
  const [approveState, setApproveState] = useState(false);

  const [selectedReview, setSelectedReview] = useState<IReview | undefined>();
  const [isAllReviewSelected, setIsAllReviewSelected] = useState(false);
  const [filters, setFilters] = useState<Filter<IReview>[]>([]);

  const { data = [], isLoading } = useQuery({
    queryFn: () => reviewService.getReviewChanges(currentProject!.id),
    queryKey: [reviewService.getReviewChanges.name, currentProject?.slug],
    enabled: !!currentProject,
  });

  const updateReviewChanges = useMutation({
    mutationKey: [reviewService.updateReviewChanges.name, currentProject?.slug],
    mutationFn: (r: IReview) => reviewService.updateReviewChanges(r),
  });

  const columns: ColumnData<IReview>[] = [
    {
      id: 'name',
      type: 'string',
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'field',
      type: 'string',
      disablePadding: false,
      label: 'Field',
    },
    {
      id: 'user',
      type: 'string',
      disablePadding: false,
      label: 'User',
    },
    {
      id: 'created_at',
      type: 'string',
      disablePadding: false,
      label: 'Time',
    },
  ];

  const filteredReviews = data.filter((review) =>
    filters.every((f) => applyFilter(review, f)),
  );

  const handleRowSelect = (indexes: Key[]) => {
    const selectedRowArray = data.filter((review) => review.id === indexes[0]);

    if (selectedRowArray.length > 0) {
      setSelectedReview(selectedRowArray[0]);
    }

    // to hide left review detail if more than 1 reviews are selected.
    if (indexes.length > 1 || indexes.length === 0) {
      setSelectedReview(undefined);
    }
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('handleSelectAll', event);
    if (event.target.checked) {
      setSelectedReview(undefined);
      setIsAllReviewSelected(true);
    } else {
      setIsAllReviewSelected(false);
    }
  };

  const handleNoState = () => {
    if (rejectState) {
      setRejectState(false);
    } else {
      setApproveState(false);
    }
  };

  const handleApproveOrRejectState = () => {
    if (rejectState) {
      setRejectState(false);
      if (selectedReview) {
        const updatedReview = JSON.parse(JSON.stringify(selectedReview));
        updatedReview.status = DB.REVIEW_STATUS_REJECTED;
        updateReviewChanges.mutate(updatedReview);
      }
    } else {
      setApproveState(false);
      if (selectedReview) {
        const updatedReview = JSON.parse(JSON.stringify(selectedReview));
        updatedReview.status = DB.REVIEW_STATUS_APPROVED;
        updateReviewChanges.mutate(updatedReview);
      }
    }

    setSelectedReview(undefined);
    // setReviewChangeSuccessSnackbar(message!);
  };

  const handleFilterChange = (filter: Filter<IReview>[]) => {
    setFilters(filter);
  };

  return (
    <AuthorizedPage>
      <PageHeader
        title="Review Changes"
        hintMessage="Review and approve changes."
      />
      <Grid container justifyContent="space-between" className="review">
        <Grid container item className="table" xs={12} md={7}>
          <Grid container item className="dashboard-body project-management">
            <TableDefault<IReview>
              columns={columns}
              rows={filteredReviews}
              isLoading={isLoading}
              filters={filters}
              onRowSelect={handleRowSelect}
              onSelectAllClick={handleSelectAll}
              onFiltersUpdate={handleFilterChange}
              selectOnlyOneRow
              hideAddButton
              hideDeleteButton
              onMassDelete={(deleted) => {
                console.log(`DELETE ${deleted.map((u) => u)}`);
              }}
            />
          </Grid>
        </Grid>
        <Grid container item className="review-changes-wrapper" xs={12} md={5}>
          <Grid
            container
            item
            className="dashboard-body project-management review-summary-container"
          >
            {selectedReview && (
              <ReviewSummary
                title={`${selectedReview.name} - ${selectedReview.field}`}
                changes={selectedReview.changes}
                dateStr={formatDateFromString(selectedReview.created_at)}
                user={selectedReview.user}
                changeType={selectedReview.field}
                approveState={approveState}
                rejectState={rejectState}
                handleApprove={() => setApproveState(true)}
                handleReject={() => setRejectState(true)}
                handleYes={() => handleApproveOrRejectState()}
                handleNo={() => handleNoState()}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </AuthorizedPage>
  );
};

export default Review;
