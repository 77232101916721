import { toSearchParam } from '../utilities/functions';
import path from './pathEntries';

function toPath(
  entries: string[],
  queryParams?: Record<string, string | undefined>,
) {
  const url = ['', ...entries].join('/');

  let query = '';
  if (queryParams) {
    // Filter out any undefined entries
    const definedParams = Object.entries(queryParams).reduce<
      [string, string][]
    >((acc, [key, val]) => {
      if (val) {
        acc.push([key, val]);
      }
      return acc;
    }, []);

    // If there's something left, create the querystring
    if (definedParams.length) {
      query = toSearchParam(Object.fromEntries(definedParams));
    }
  }

  // console.log(`Created Path ${url + query}`, { entries, queryParams, url, query });
  return url + query;
}

const pathGenerators = {
  LOGIN: () => toPath([path.LOGIN]),
  TWO_FACTOR_LOGIN: () => toPath([path.LOGIN, path.TWO_FACTOR]),
  SET_PASSWORD: () => toPath([path.AUTH, path.RESET_PASSWORD]),
  FORGOT_PASSWORD: () => toPath([path.FORGOT_PASSWORD]),

  HOME: (slug?: string) => toPath([path.HOME], { slug }),

  USERS: (slug?: string) => toPath([path.USERS], { slug }),
  USER_CREATE: () => toPath([path.USERS, path.CREATE]),
  USER_EDIT: (id: string = path.ID) => toPath([path.USERS, id]),

  PROJECTS: (slug?: string) => toPath([path.PROJECTS], { slug }),
  PROJECTS_HOME: (slug: string = path.SLUG) => toPath([path.PROJECTS, slug]),
  PROJECTS_CREATE: () => toPath([path.PROJECTS, path.CREATE]),
  PROJECTS_EDIT: (slug: string = path.SLUG) =>
    toPath([path.PROJECTS, slug, path.MANAGEMENT]),

  ROLES: (slug: string = path.SLUG) =>
    toPath([path.PROJECTS, slug, path.ROLES]),
  ROLES_DEFINE: (slug: string = path.SLUG, id?: string) =>
    toPath([path.PROJECTS, slug, path.ROLES, path.DEFINE], { id }),

  ANALYTICS: (slug: string = path.SLUG) =>
    toPath([path.PROJECTS, slug, path.ANALYTICS]),
  ANALYTICS_BROWSER_TYPES: (slug: string = path.SLUG) =>
    toPath([path.PROJECTS, slug, path.ANALYTICS, path.BROWSER_TYPES]),
  ANALYTICS_UNIQUE_LOGINS: (slug: string = path.SLUG) =>
    toPath([path.PROJECTS, slug, path.ANALYTICS, path.UNIQUE_LOGINS]),
  ANALYTICS_PDFS_DOWNLOADED: (slug: string = path.SLUG) =>
    toPath([path.PROJECTS, slug, path.ANALYTICS, path.PDFS_DOWNLOADED]),

  REVIEW: (slug: string = path.SLUG) =>
    toPath([path.PROJECTS, slug, path.REVIEW]),

  MY_ACCOUNT: () => toPath([path.MY_ACCOUNT]),
  ACTIVITY_LOG: (slug: string = path.SLUG) =>
    toPath([path.PROJECTS, slug, path.ACTIVITY_LOG]),
  LOGIN_HISTORY: (id: string = path.ID) =>
    toPath([path.USERS, id, path.LOGIN_HISTORY]),
  DATA_CAPTURE: () => toPath([path.PROJECTS, path.DATA_CAPTURE]),
  FORM_DATA: () => toPath([path.PROJECTS, path.FORM_DATA]),
  CONTACT_FORM: (slug: string = path.SLUG) =>
    toPath([path.PROJECTS, slug, path.CONTACT_FORM]),
  CONTACT_FORM_MANAGEMENT: (slug: string = path.SLUG) =>
    toPath([path.PROJECTS, slug, path.CONTACT_FORM, path.MANAGEMENT]),

  UNIQUE_LOGINS: () => toPath([path.UNIQUE_LOGINS]),
  BROWSER_TYPES: () => toPath([path.BROWSER_TYPES]),
  PDFS_DOWNLOADED: () => toPath([path.PDFS_DOWNLOADED]),

  DOC_SCHEMAS: (slug: string = path.SLUG) =>
    toPath([path.PROJECTS, slug, path.DOC_SCHEMAS]),
  DOC_SCHEMA_CREATE: (slug: string = path.SLUG) =>
    toPath([path.PROJECTS, slug, path.DOC_SCHEMAS, path.CREATE]),
  DOC_SCHEMA_EDIT: (slug: string = path.SLUG, id: string = path.ID) =>
    toPath([path.PROJECTS, slug, path.DOC_SCHEMAS, id]),

  DOCUMENTS: (slug: string = path.SLUG, id?: string) =>
    toPath([path.PROJECTS, slug, path.DOCUMENTS], {
      id,
    }),
  DOCUMENT_CREATE: (slug: string = path.SLUG, id?: string) =>
    toPath([path.PROJECTS, slug, path.DOCUMENTS, path.CREATE], {
      id,
    }),
  DOCUMENT_EDIT: (slug: string = path.SLUG, id: string = path.ID) =>
    toPath([path.PROJECTS, slug, path.DOCUMENTS, id]),
};

export default pathGenerators;
