import { useParams } from 'react-router-dom';

/**
 * Returns -1 if id param is not present;
 */
export default function (): number | null {
  const { id } = useParams<{ id: string }>();
  // console.log('useParams', useParams<{ id: string }>());
  if (id === undefined) {
    return null;
  }
  return Number.parseInt(id);
}
