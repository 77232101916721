const queryConstants = {
  FILTERS: 'filters',
  TYPE: 'type',
  FIELD: 'field',
  OPERATOR: 'op',
  VALUE: 'value',
  PAGE: 'page',
  ROWS: 'rows',
  SELECTED: 'selected',
  SEARCH: 'search',
} as const;

export default queryConstants;
